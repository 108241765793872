import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import axios from "axios";
import { useInsertForm } from "../../Components/Hooks/UseInsertForm";

const AddClinic = () => {
  document.title = "Add Clinic - VisitTurkey";
  // const [options, setOptions] = useState([]);

  // const getOptions = async () => {
  //   const response = await axios.get("/organization?page=1&take=250&keyword=");
  //   setOptions(
  //     response.map((x) => {
  //       return {
  //         value: x.id.toString(),
  //         label: x.name,
  //       };
  //     })
  //   );
  // };

  const { renderForm } = useInsertForm(
    [
      // {
      //   label: "Organization",
      //   name: "organization",
      //   required: true,
      //   type: "selectbox",
      //   options,
      // },
      {
        label: "Type",
        name: "type",
        required: true,
        type: "selectbox",
        value: "clinic",
        disabled: true,
        options: [
          { label: "Hospital", value: "hospital" },
          { label: "Clinic", value: "clinic" },
        ],
      },
      {
        label: "Full Name",
        name: "fullName",
        placeholder: "enter full name",
        required: true,
        type: "text",
      },
      {
        label: "image",
        name: "image",
        required: false,
        type: "file",
        multiple: true,
        multiuploadcolumn: "clinic",
        uploadtype: "health-category",
      },
      {
        label: "Tax Number",
        name: "taxNumber",
        placeholder: "enter tax number",
        required: true,
        type: "number",
      },
      {
        label: "Tax Office",
        name: "taxOffice",
        placeholder: "enter tax office",
        required: true,
        type: "text",
      },
      {
        label: "address",
        name: "address",
        placeholder: "address",
        required: true,
        type: "text",
      },
      {
        label: "License Number",
        name: "licenseNumber",
        placeholder: "enter license number",
        required: true,
        type: "number",
      },
    ],
    [],
    { postUrl: "/organization/clinics" }
  );

  // useEffect(() => {
  //   getOptions();
  // }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>{renderForm()}</Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddClinic;
