import { FormGroup, Input } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";

export const useSoftDelete = () => {
  const handleChange = (deleteUri, checked) => {
    try {
      axios.delete(deleteUri);
      toast(
        !checked
          ? "This record is not visible now"
          : "This record is activated again",
        {
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: true,
          className: "bg-success text-white",
        }
      );
    } catch (e) {
      toast(e.response.data.message, {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: true,
        className: "bg-warning text-white",
      });
    }
  };

  const renderToast = () => {
    return (
      <>
        <ToastContainer />
      </>
    );
  };

  const renderSwitch = (deleteUri, value) => {
    return (
      <FormGroup switch>
        <Input
          type="switch"
          defaultChecked={value}
          role="switch"
          onChange={(e) => {
            handleChange(deleteUri, e.target.checked);
          }}
        />
      </FormGroup>
    );
  };

  return {
    renderSwitch,
    renderToast,
  };
};
