import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import axios from "axios";

import BreadCrumb from "../../Components/Common/BreadCrumb";

import { useInsertForm } from "../../Components/Hooks/UseInsertForm";

const AddQuestion = () => {
  document.title = "Add Question | Visit Turkey";

  const [options, setOptions] = useState([]);

  const getServices = async () => {
    try {
      const response = await axios.get("/service/services/all");
      if (response.length > 0) {
        const options = response.map((option) => ({
          label: option.name,
          value: option.service?.id?.toString(),
        }));
        setOptions(options);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { renderForm } = useInsertForm(
    [
      {
        name: "service",
        label: "Service",
        required: true,
        type: "selectbox",
        multiple: true,
        options: options,
      },
      {
        label: "Question Type",
        name: "questionType",
        required: true,
        options: [
          { label: "Travel", value: "travel" },
          { label: "Accommodation", value: "accommodation" },
          { label: "Services", value: "services" },
          { label: "Health", value: "health" },
          { label: "Additional", value: "additional" },
        ],
        type: "selectbox",
      },
      {
        label: "Is Toggle Question",
        name: "isToggleQuestion",
        required: false,
        type: "checkbox",
      },
      {
        label: "image",
        name: "image",
        type: "file",
        propname: "iconSrc",
        accept: "image/*",
        required: false,
      },
    ],
    [
      {
        label: "Soru Adı",
        name: "name",
        placeholder: "enter soru adı",
        required: true,
        type: "text",
      },
      {
        label: "Soru açıklama",
        name: "description",
        placeholder: "enter soru açıklama",
        required: true,
        type: "text",
      },
    ],
    { postUrl: "/questions" }
  );

  useEffect(() => {
    getServices();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Add Question" pageTitle="Add Question" />
          <Row>
            <Col>
              <Card>
                <CardBody className="pt-2">
                  <h5 className="card-title mb-3  mb-md-0 flex-grow-1">
                    Add Question
                  </h5>
                  {renderForm()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddQuestion;
