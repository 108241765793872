import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Nav,
  NavItem,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  Label,
  Button,
  NavLink,
  FormFeedback,
} from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import * as Yup from "yup";
import { useFormik } from "formik";

// Import Images
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";

const CreateStaff = () => {
  document.title = "Create Staff | Visit Turkey";

  const [note, setNote] = useState("");

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      firstName: "",
      lastName: "",
      phone: "",
      password: "",
      nationalID: "",
      dateOfBirth: "",
      department: "",
      note: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter First Name"),
      lastName: Yup.string().required("Please Enter Last Name"),
      phone: Yup.string().required("Please Enter Phone"),
      password: Yup.string().required("Please Enter Password"),
      nationalID: Yup.string().required("Please Enter National Identity"),
      dateOfBirth: Yup.string().required("Please Enter Date of Birth"),
      department: Yup.string().required("Please Enter Department"),
    }),
    onSubmit: (values) => {
      const newStaff = {
        firstName: values["firstName"],
        lastName: values["lastName"],
        phone: values["phone"],
        password: values["password"],
        nationalID: values["nationalID"],
        dateOfBirth: values["dateOfBirth"],
        department: values["department"],
        note,
      };
      // save new Contact
      console.log(newStaff);
      setNote("");
      validation.resetForm();
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <DeleteModal
          show={deleteModal}
          onCloseClick={() => setDeleteModal(false)}
        />
        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        /> */}
        <Container fluid>
          <BreadCrumb title="Create Staff" pageTitle="Create Staff" />
          <Row>
            <Col>
              <Card>
                {/* <CardBody className="border border-dashed border-end-0 border-start-0">
                  <Form>
                    <Row className="g-3">
                      <Col xxl={5} sm={6}>
                        <div className="search-box">
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder="Search for application ID, company, designation status or something..."
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>
                      <Col xxl={2} sm={6}>
                        <div>
                          <Flatpickr
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select date"
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "multiple",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xxl={2} sm={4}>
                        <div>
                          <Select
                            options={option}
                            name="choices-single-default"
                            id="idStatus"
                          ></Select>
                        </div>
                      </Col>
                      <Col xxl={2} sm={4}>
                        <div>
                          <Select
                            options={option1}
                            name="choices-single-default"
                            id="idType"
                          ></Select>
                        </div>
                      </Col>
                      <Col xxl={1} sm={4}>
                        <div>
                          <Button
                            type="button"
                            color="primary"
                            className="btn w-100"
                            // onclick=""
                          >
                            {" "}
                            <i className="ri-equalizer-fill me-1 align-bottom"></i>
                            Filters
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody> */}
                <CardBody className="pt-2">
                  <h5 className="card-title mb-3  mb-md-0 flex-grow-1">
                    Create Staff
                  </h5>
                  <Form
                    className="tablelist-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Input type="hidden" id="id-field" />
                    <Row className="g-3">
                      <Col lg={12}>
                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute  bottom-0 end-0">
                              <Label
                                htmlFor="customer-image-input"
                                className="mb-0"
                              >
                                <div className="avatar-xs cursor-pointer">
                                  <div className="avatar-title bg-light border rounded-circle text-muted">
                                    <i className="ri-image-fill"></i>
                                  </div>
                                </div>
                              </Label>
                              <Input
                                className="form-control d-none"
                                id="customer-image-input"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.img || ""}
                                invalid={
                                  validation.touched.img &&
                                  validation.errors.img
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            <div className="avatar-lg p-1">
                              <div className="avatar-title bg-light rounded-circle">
                                <img
                                  src={dummyImg}
                                  alt="dummyImg"
                                  id="customer-img"
                                  className="avatar-md rounded-circle object-fit-cover"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <Label
                            htmlFor="firstName-field"
                            className="form-label"
                          >
                            First Name
                          </Label>
                          <Input
                            name="firstName"
                            id="firstName-field"
                            className="form-control"
                            placeholder="Enter First Name"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstName || ""}
                            invalid={
                              validation.touched.firstName &&
                              validation.errors.firstName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.firstName &&
                          validation.errors.firstName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.firstName}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div>
                          <Label
                            htmlFor="lastName-field"
                            className="form-label"
                          >
                            Last Name
                          </Label>
                          <Input
                            name="lastName"
                            id="lastName-field"
                            className="form-control"
                            placeholder="Enter Last Name"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastName || ""}
                            invalid={
                              validation.touched.lastName &&
                              validation.errors.lastName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.lastName &&
                          validation.errors.lastName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.lastName}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div>
                          <Label htmlFor="phone-field" className="form-label">
                            Phone
                          </Label>
                          <Input
                            name="phone"
                            id="phone-field"
                            className="form-control"
                            placeholder="Enter Phone"
                            type="tel"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone || ""}
                            invalid={
                              validation.touched.phone &&
                              validation.errors.phone
                                ? true
                                : false
                            }
                          />
                          {validation.touched.phone &&
                          validation.errors.phone ? (
                            <FormFeedback type="invalid">
                              {validation.errors.phone}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div>
                          <Label
                            htmlFor="password-field"
                            className="form-label"
                          >
                            Password
                          </Label>
                          <Input
                            name="password"
                            id="password-field"
                            className="form-control"
                            placeholder="Enter Password"
                            type="password"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div>
                          <Label
                            htmlFor="nationalID-field"
                            className="form-label"
                          >
                            National Identity
                          </Label>
                          <Input
                            name="nationalID"
                            id="nationalID-field"
                            className="form-control"
                            placeholder="Enter Name"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.nationalID || ""}
                            invalid={
                              validation.touched.nationalID &&
                              validation.errors.nationalID
                                ? true
                                : false
                            }
                          />
                          {validation.touched.nationalID &&
                          validation.errors.nationalID ? (
                            <FormFeedback type="invalid">
                              {validation.errors.nationalID}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div>
                          <Label
                            htmlFor="dateOfBirth-field"
                            className="form-label"
                          >
                            Date of Birth
                          </Label>
                          <Input
                            name="dateOfBirth"
                            id="dateOfBirth-field"
                            className="form-control"
                            placeholder="Enter Date of Birth"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.dateOfBirth || ""}
                            invalid={
                              validation.touched.dateOfBirth &&
                              validation.errors.dateOfBirth
                                ? true
                                : false
                            }
                          />
                          {validation.touched.dateOfBirth &&
                          validation.errors.dateOfBirth ? (
                            <FormFeedback type="invalid">
                              {validation.errors.dateOfBirth}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div>
                          <Label
                            htmlFor="department-field"
                            className="form-label"
                          >
                            Department
                          </Label>
                          <Input
                            name="department"
                            id="department-field"
                            className="form-control"
                            placeholder="Enter Name"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.department || ""}
                            invalid={
                              validation.touched.department &&
                              validation.errors.department
                                ? true
                                : false
                            }
                          />
                          {validation.touched.department &&
                          validation.errors.department ? (
                            <FormFeedback type="invalid">
                              {validation.errors.department}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <Label
                            htmlFor="company_name-field"
                            className="form-label"
                          >
                            Note
                          </Label>
                          <textarea
                            onChange={(e) => setNote(e.target.value)}
                            className="form-control"
                            id="addaddress-textarea"
                            placeholder="Enter Address"
                            rows="2"
                          ></textarea>
                        </div>
                      </Col>
                    </Row>
                    <div className="hstack gap-2 mt-4 justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-success"
                        id="add-btn"
                      >
                        Create Staff
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateStaff;
