import React from "react";
import { Col, Container, Row } from "reactstrap";
import { useInsertForm } from "../../Components/Hooks/UseInsertForm";
import { useParams } from "react-router-dom";

const AddSubservice = () => {
  document.title = "Add SubService - VisitTurkey";
  const { id } = useParams();

  const { renderForm } = useInsertForm(
    [
      {
        label: "image",
        name: "image",
        required: true,
        type: "file",
        multiple: true,
        multiuploadcolumn: "subService",
        uploadtype: "subService",
        accept: "image/*",
      },
      {
        label: "",
        name: "service",
        hidden: "hidden",
        required: true,
        type: "text",
        value: id,
      },
      {
        label: "Is Image Required",
        name: "isImageRequired",
        type: "checkbox",
      },
    ],
    [
      {
        label: "Name",
        name: "name",
        placeholder: "enter name",
        required: true,
        type: "text",
      },
      {
        label: "Slug",
        name: "slug",
        placeholder: "enter slug",
        required: true,
        type: "text",
      },
      {
        label: "Description",
        name: "description",
        placeholder: "enter description",
        required: true,
        type: "textarea",
      },
    ],
    { postUrl: "/service/create/subservice" }
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>{renderForm()}</Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddSubservice;
