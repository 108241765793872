import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  Input,
  Button,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import Select from "react-select";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import axios from "axios";

const CreateOffer = () => {
  const [users, setUsers] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndexes, setCurrentQuestionIndexes] = useState([]);
  const [images, setImages] = useState([]);
  const [flightClasses, setFlightClasses] = useState([]);
  const [files, setFiles] = useState([]);
  const [flightClassValue, setFlightClassValue] = useState(-1);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    getUsers();
    getServiceCategories();
    getFlightClass();
  }, []);

  const getUsers = async () => {
    try {
      const response = await axios.get("/users/list");
      const userList = response.map((user) => ({
        id: user.id,
        fullName: `${user.firstName} ${user.lastName}`,
      }));
      setUsers(userList);
    } catch (error) {
      console.log(error);
    }
  };

  const getServiceCategories = async () => {
    try {
      const response = await axios.get("/service/category");
      const list = response.map((list) => ({
        id: list.id,
        title: list.title,
        slug: list.slug,
      }));
      setServiceCategories(list);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = async (id) => {
    try {
      const response = await axios.get(
        `/service/services/${id}?page=1&take=100&language=en&keyword=`
      );
      const list = response.map((list) => ({
        id: list.id,
        name: list.name,
        serviceId: list.service.id,
      }));
      setCategories(list);
    } catch (error) {
      console.log(error);
    }
  };

  const getSubService = async (id) => {
    try {
      const response = await axios.get(
        `/service/services/sub/${id}?page=1&take=100&language=en&keyword=`
      );
      console.log(response);
      const list = response.map((list) => ({
        id: list.id,
        name: list.name,
        serviceId: list.subService.id,
      }));
      setSubCategories(list);
    } catch (error) {
      console.log(error);
    }
  };

  const getQuestions = async (id) => {
    try {
      const response = await axios.get(`/questions/service/${id}`);

      const list = response.map((list) => ({
        id: list.id,
        name: list.questionI18n.name,
        isToggleQuestion: list.isToggleQuestion,
        questionType: list.questionType,
      }));

      setQuestions(list);
    } catch (error) {
      console.log(error);
    }
  };

  const getFlightClass = async () => {
    try {
      const response = await axios.get("/flight-class/en");
      const list = response.map((list) => ({
        id: list.id,
        name: list.name,
      }));

      setFlightClasses(list);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFile = (e) => {
    const imgArr = [];
    const files = e.target.files;

    for (let file of files) {
      imgArr.push(URL.createObjectURL(file));
    }

    setImages((prevImages) => [...prevImages, ...imgArr]);
    setFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData(document.getElementById("offer-form"));

      let data = {};

      data["departure"] = dates[0];
      data["return"] = dates[1];

      for (let [key, val] of Object.entries(
        Object.fromEntries(formData.entries())
      )) {
        if (key.includes("-")) {
          if (data["answers"]?.length > 0) {
            data["answers"].push({
              questionId: key.split("-")[0],
              content: val === "on" ? "yes" : val,
            });
          } else {
            data["answers"] = [
              {
                questionId: key.split("-")[0],
                content: val === "on" ? "yes" : val,
              },
            ];
          }
        } else {
          data[key] = val;
        }
      }

      if (!data?.category || !data?.subCategory) {
        alert("Please make sure that you choose a category/sub category");
        return;
      }
      const result = await axios.post("/offer", data);
      toast("Offer has been created", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
      sendAnswers(
        data.userId,
        data.category,
        data.subCategory,
        result.id,
        data["answers"]
      );
      if (files.length > 0) {
        sendFiles(data.userId, data.category, data.subCategory, result.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sendAnswers = async (
    userId,
    serviceId,
    subServiceId,
    offerId,
    answers
  ) => {
    try {
      await axios.post(
        `/answer/${userId}/${serviceId}/${subServiceId}/${offerId}`,
        answers
      );
    } catch (error) {
      console.log(error);
    }
  };

  const sendFiles = async (userId, serviceId, subServiceId, offerId) => {
    try {
      const formData = new FormData();

      files.forEach((file) => {
        formData.append("photos", file);
      });

      await axios.post(
        `/offer/photos/${offerId}/${serviceId}/${subServiceId}/${userId}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Create Offer" pageTitle="Create Offer" />
          <Row>
            <Col>
              <form onSubmit={onSubmit} id="offer-form">
                <Card>
                  <CardBody className="pt-2">
                    <h5 className="card-title mb-3  mb-md-0 flex-grow-1">
                      Create Offer
                    </h5>
                    <div className="mt-4">
                      <Label
                        htmlFor="userId"
                        className="form-label text-capitalize"
                      >
                        Choose a User
                      </Label>
                      <select required name="userId" className="form-control">
                        <option value="">Choose</option>
                        {users.map((user) => (
                          <option key={user.id} value={user.id}>
                            {user.fullName}
                          </option>
                        ))}
                      </select>
                    </div>
                    {serviceCategories.length > 0 && (
                      <div className="mt-4">
                        <Label
                          htmlFor="serviceCategory"
                          className="form-label text-capitalize"
                        >
                          Choose a Service Category
                        </Label>
                        <select
                          required
                          onChange={(e) => {
                            getCategories(e.target.value);
                          }}
                          name="serviceCategory"
                          className="form-control"
                        >
                          <option value="">Choose</option>
                          {serviceCategories.map((serviceCategory) => (
                            <option
                              key={serviceCategory.id}
                              value={serviceCategory.id}
                            >
                              {serviceCategory.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {categories.length > 0 && (
                      <div className="mt-4">
                        <Label
                          htmlFor="category"
                          className="form-label text-capitalize"
                        >
                          Choose a Category
                        </Label>
                        <select
                          required
                          onChange={(e) => {
                            getSubService(e.target.value);
                            getQuestions(e.target.value);
                            // console.log(e.target.dataset("data-id"));
                          }}
                          name="category"
                          className="form-control"
                        >
                          <option value="">Choose</option>
                          {categories.map((category) => (
                            <option
                              key={category.serviceId}
                              value={category.serviceId}
                              //   data-id={category.id}
                            >
                              {category.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {subCategories.length > 0 && (
                      <div className="mt-4">
                        <Label
                          htmlFor="subCategory"
                          className="form-label text-capitalize"
                        >
                          Choose a Sub Category
                        </Label>
                        <select
                          required
                          name="subCategory"
                          className="form-control"
                        >
                          <option value="">Choose</option>
                          {subCategories.map((category) => (
                            <option value={category.serviceId}>
                              {category.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {questions.length > 0 && categories.length > 0 && (
                      <>
                        <div className="mt-4">
                          {questions.map((item, i) => (
                            <>
                              <div className="form-check form-switch form-switch-custom form-switch-primary my-3">
                                <Label
                                  className="form-check-label form-label"
                                  htmlFor={`${item.id}-${item.questionType}`}
                                >
                                  {item.name}
                                </Label>
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id={`${item.id}-${item.questionType}`}
                                  name={`${item.id}-${item.questionType}`}
                                  onChange={(e) => {
                                    if (
                                      e.target.checked &&
                                      !currentQuestionIndexes.includes(i)
                                    ) {
                                      setCurrentQuestionIndexes(
                                        (prevIndexes) => [...prevIndexes, i]
                                      );
                                      return;
                                    }
                                    setCurrentQuestionIndexes((prevIndex) =>
                                      [...prevIndex].filter(
                                        (item) => item !== i
                                      )
                                    );
                                  }}
                                />
                              </div>
                              {item.isToggleQuestion &&
                                currentQuestionIndexes.includes(i) && (
                                  <Input
                                    name={`${item.id}-${item.questionType}`}
                                    placeholder="Enter more detail"
                                  />
                                )}
                            </>
                          ))}
                        </div>
                      </>
                    )}
                    <div className="mt-4">
                      <Label
                        htmlFor="file"
                        className="form-label text-capitalize"
                      >
                        Choose Media
                      </Label>
                      <Input
                        type="file"
                        id="file"
                        file="file"
                        accept="image/png, image/jpeg, image/jpg"
                        multiple
                        onChange={handleFile}
                      />
                    </div>

                    {images.length > 0 && (
                      <div className="d-flex gap-2 flex-wrap mt-3">
                        <i className="ri-file-copy-2-line align-middle me-2"></i>
                        <b>Media :</b>&nbsp;
                        {images.map((image, i) => (
                          <div key={i}>
                            <div
                              style={{
                                width: 100,
                                height: 100,
                                position: "relative",
                              }}
                            >
                              <img
                                src={image}
                                key={image}
                                style={{
                                  width: 100,
                                  height: 100,
                                  borderRadius: 6,
                                }}
                              />
                              <Button
                                onClick={() =>
                                  setImages((prevImages) => [
                                    ...prevImages.filter(
                                      (item, index) => index !== i
                                    ),
                                  ])
                                }
                                style={{
                                  position: "absolute",
                                  top: -10,
                                  right: -10,
                                  zIndex: 10,
                                  borderRadius: 100,
                                  backgroundColor: "gray",
                                  border: "1px solid gray",
                                  width: 45,
                                  height: 45,
                                }}
                              >
                                <i className="bx bx-trash"></i>
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="mt-4">
                      <Label
                        htmlFor="note"
                        className="form-label text-capitalize"
                      >
                        Add more description
                      </Label>
                      <Input
                        type="textarea"
                        id="note"
                        name="note"
                        placeholder="Write what you want to tell us about you"
                      />
                    </div>
                    <div className="mt-4 d-flex gap-3">
                      <div>
                        <Label
                          htmlFor="from"
                          className="form-label text-capitalize"
                        >
                          Cities you want to get service
                        </Label>
                        <Input
                          required
                          type="text"
                          id="from"
                          name="from"
                          placeholder="Departure city"
                        />
                      </div>
                      <div>
                        <Label
                          htmlFor="to"
                          className="form-label text-capitalize"
                        ></Label>
                        <Input
                          required
                          className="mt-2"
                          type="text"
                          id="to"
                          name="to"
                          placeholder="Arrival city"
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <Label
                        htmlFor="datepicker"
                        className="form-label text-capitalize"
                      >
                        Travel Date
                      </Label>
                      <Flatpickr
                        required
                        className="form-control"
                        id="datepicker"
                        name="datepicker"
                        placeholder="Select date"
                        onChange={(dates) => setDates(dates)}
                        options={{
                          minDate: "today",
                          altInput: true,
                          altFormat: "F j, Y",
                          mode: "range",
                          dateFormat: "d-m-Y",
                        }}
                      />
                    </div>
                    <div className="mt-4">
                      <div className="d-flex gap-3 ">
                        <div className="w-100">
                          <Label
                            htmlFor="adultCount"
                            className="form-label text-capitalize"
                          >
                            Adults <small>({">"} 12 Years)</small>
                          </Label>
                          <div>
                            <Select
                              required
                              id="adultCount"
                              name="adultCount"
                              options={[
                                {
                                  options: [
                                    { label: "1", value: "1" },
                                    { label: "2", value: "2" },
                                    { label: "3", value: "3" },
                                    { label: "4", value: "4" },
                                    { label: "5", value: "5" },
                                    { label: "6", value: "6" },
                                  ],
                                },
                              ]}
                            />
                          </div>
                        </div>
                        <div className="w-100">
                          <Label
                            htmlFor="childrenCount"
                            className="form-label text-capitalize"
                          >
                            2-12 Years <small>(Children)</small>
                          </Label>
                          <Select
                            id="childrenCount"
                            name="childrenCount"
                            options={[
                              {
                                options: [
                                  { label: "1", value: "1" },
                                  { label: "2", value: "2" },
                                  { label: "3", value: "3" },
                                  { label: "4", value: "4" },
                                  { label: "5", value: "5" },
                                  { label: "6", value: "6" },
                                ],
                              },
                            ]}
                          />
                        </div>
                        <div className="w-100">
                          <Label
                            htmlFor="babyCount"
                            className="form-label text-capitalize"
                          >
                            {"<"} 2 Years <small>(Infant)</small>
                          </Label>
                          <Select
                            id="babyCount"
                            name="babyCount"
                            options={[
                              {
                                options: [
                                  { label: "1", value: "1" },
                                  { label: "2", value: "2" },
                                  { label: "3", value: "3" },
                                  { label: "4", value: "4" },
                                  { label: "5", value: "5" },
                                  { label: "6", value: "6" },
                                ],
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <Label
                        htmlFor="form-item-flight"
                        className="form-label text-capitalize"
                      >
                        Flight Class
                      </Label>
                      {flightClasses.length > 0 &&
                        flightClasses.map((item) => (
                          <div
                            key={item.id}
                            className="form-check form-switch form-switch-custom form-switch-primary my-3"
                          >
                            <Label
                              className="form-check-label form-label"
                              htmlFor="flightClass"
                            >
                              {item.name}
                            </Label>
                            <Input
                              required={!flightClassValue}
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              checked={flightClassValue === item.id}
                              id={`${item.name}-${item.id}`}
                              name="flightClass"
                              value={flightClassValue}
                              onChange={() => setFlightClassValue(item.id)}
                            />
                          </div>
                        ))}
                    </div>
                    <Button type="submit">Submit</Button>
                  </CardBody>
                </Card>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateOffer;
