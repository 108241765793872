import React, { useRef, useState } from "react";
import {
  Card,
  CardHeader,
  Col,
  Row,
  Container,
  CardBody,
  Button,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { toast } from "react-toastify";
import { usePagination } from "../../Components/Hooks/UsePagination";
import { useNavigate } from "react-router-dom";
import { useSoftDelete } from "../../Components/Hooks/UseSoftDelete";
import UserModal from "../../Components/Common/Modals/UserModal";

const AllUsers = () => {
  const {
    data,
    emptyResult,
    renderFields,
    renderData,
    renderButtons,
    renderSearch,
  } = usePagination("/users", {
    fields: [
      { label: "id", column: "id", id: "id" },
      { label: "email", column: "email", id: "createdat" },
      { label: "firstName", column: "firstName", id: "firstName" },
      { label: "lastName", column: "lastName", id: "lastName" },
      { label: "gender", id: "gender" },
      { label: "dateOfBirth", column: "dateOfBirth", id: "dateOfBirth" },
      { label: "action", id: "action" },
    ],
  });
  const { renderSwitch, renderToast } = useSoftDelete();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    gender: "",
    dateOfBirth: "",
    city: "",
  });

  const modalRef = useRef(null);

  const deleteUser = async (id) => {
    try {
      await axios.delete(`/users/${id}`);
      toast("User has been deleted successfully!", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
    } catch (error) {
      toast("An error occurred while deleting user", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
      if (process.env.NODE_ENV === "development") console.log(error);
    }
  };
  return (
    <>
      <UserModal user={user} ref={modalRef} />
      <div className="page-content">
        <Container fluid>
          {renderToast()}
          <Row>
            <BreadCrumb title="All Users" pageTitle="Users" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title mb-0">View All Users</h4>
                  </CardHeader>

                  <CardBody>
                    <div className="listjs-table" id="customerList">
                      <div
                        className="d-flex justify-content-sm-end"
                        style={{ width: "100%" }}
                      >
                        {renderSearch()}
                      </div>

                      <div className="table-responsive table-card mt-3 mb-1">
                        <table
                          className="table align-middle table-nowrap"
                          id="organizationTable"
                        >
                          <thead className="table-light">
                            <tr>{renderFields()}</tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {renderData((d) => (
                              <>
                                {d.id === "isActive" &&
                                  renderSwitch(
                                    `/service/section/${d.data["section.id"]}`,
                                    !d.data["section.deletedAt"] ? true : false
                                  )}
                                {d.id === "gender" && (
                                  <p>{d.data["gender"] ?? "Not Specified"}</p>
                                )}
                                {d.id === "action" && (
                                  <div
                                    className="d-flex"
                                    style={{ gap: "1rem" }}
                                  >
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        navigate(
                                          `/user-applications?userId=${d.data["id"]}`
                                        );
                                      }}
                                    >
                                      View Offers
                                    </Button>
                                    <Button
                                      color="warning"
                                      onClick={() => {
                                        setUser(d.data);
                                        modalRef.current?.toggle();
                                      }}
                                    >
                                      Profile
                                    </Button>
                                    <Button
                                      color="danger"
                                      onClick={() => deleteUser(d.data["id"])}
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                )}
                              </>
                            ))}
                          </tbody>
                        </table>
                        {(!data || !data?.length) && emptyResult()}
                      </div>

                      {renderButtons()}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AllUsers;
