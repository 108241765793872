import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";

import { useInsertForm } from "../../Components/Hooks/UseInsertForm";
import axios from "axios";

const AddPolicy = () => {
  document.title = "Add Policy | Visit Turkey";

  const { renderForm } = useInsertForm(
    [],
    [
      {
        label: "Name",
        name: "name",
        placeholder: "enter name",
        required: true,
        type: "text",
      },
      {
        label: "Slug",
        name: "slug",
        placeholder: "enter slug",
        required: true,
        type: "text",
      },
      {
        label: "Description",
        name: "description",
        placeholder: "enter description",
        required: true,
        type: "text",
        richText: true,
      },
    ],
    { postUrl: "/policy" }
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Add Policy" pageTitle="Add Policy" />
          <Row>
            <Col>
              <Card>
                <CardBody className="pt-2">
                  <h5 className="card-title mb-3  mb-md-0 flex-grow-1">
                    Add Policy
                  </h5>
                  {renderForm()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddPolicy;
