import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { toast } from "react-toastify";

const AddClinicModal = ({ isOpen, onClose, onUpdate, id }) => {
  const [clinics, setClinics] = useState([]);
  const [practioners, setPractioners] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [currentClinic, setClinic] = useState();
  const [currentPractioner, setCurrentPractioner] = useState();

  const allClinics = async () => {
    const response = await axios.get(
      "/organization/all/clinic?withDeleted=false"
    );
    setClinics(response);
  };

  const getPractioners = async (id) => {
    const response = await axios.get(
      `/practioner/clinic/${id}?withDeleted=false`
    );
    setPractioners(response);
  };

  const update = async () => {
    try {
      await axios.patch(`/offer/clinic/${id}`, {
        clinic: { id: currentClinic.id },
        practitioner: { id: currentPractioner.practioner.id },
      });
      toast("Your record updated", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
      setLoading(true);
      onUpdate();
    } catch (e) {
      toast(e?.response?.data?.message || e, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-warning text-white",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    update();
  };

  useEffect(() => {
    allClinics();
  }, []);

  useEffect(() => {
    if (!currentClinic) {
      return;
    }
    getPractioners(currentClinic.id);
  }, [currentClinic]);

  return (
    <Modal isOpen={isOpen} toggle={onClose} id="exampleModal">
      <ModalHeader>
        Add Clinic
        <Button
          type="button"
          className="btn-close"
          onClick={onClose}
          aria-label="Close"
        ></Button>
      </ModalHeader>
      <ModalBody>
        {currentClinic && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              marginBlock: "1rem",
            }}
          >
            <h4>Clinic:</h4>
            <ListGroup>
              <ListGroupItem>
                <i className="ri-file-copy-2-line align-middle me-2"></i>
                <b>Organization:</b>&nbsp; {currentClinic.fullName}
              </ListGroupItem>
              <ListGroupItem>
                <i className="ri-file-copy-2-line align-middle me-2"></i>
                <b>Address:</b>&nbsp; {currentClinic.address}
              </ListGroupItem>
            </ListGroup>
            <div>
              <Button>View More Details</Button>
            </div>
          </div>
        )}
        {currentPractioner && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              marginBlock: "1rem",
            }}
          >
            <h4>Doctor:</h4>
            <ListGroup>
              <ListGroupItem>
                <i className="ri-file-copy-2-line align-middle me-2"></i>
                <b>Name:</b>&nbsp; {currentPractioner.practioner.name}
              </ListGroupItem>
              <ListGroupItem>
                <i className="ri-file-copy-2-line align-middle me-2"></i>
                <b>Field:</b>&nbsp; {currentPractioner.practioner.field}
              </ListGroupItem>
            </ListGroup>
            <div>
              <Button>View More Details</Button>
            </div>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="name" className="col-form-label">
              Choose Clinic:
            </label>
            <select
              required
              className="form-control"
              onChange={(e) => {
                if (!e.target.value) return;
                setClinic(clinics.find((x) => x.id === Number(e.target.value)));
              }}
            >
              <option value={""}>Choose</option>
              {clinics.map((clinic, id) => {
                return (
                  <option key={id} value={clinic.id}>
                    {clinic.fullName}
                  </option>
                );
              })}
            </select>
          </div>
          {currentClinic && (
            <div className="mb-3">
              <label htmlFor="name" className="col-form-label">
                Choose Practioner:
              </label>
              <select
                required
                className="form-control"
                onChange={(e) => {
                  if (!e.target.value) return;
                  setCurrentPractioner(
                    practioners.find((x) => x.id === Number(e.target.value))
                  );
                }}
              >
                <option value={""}>Choose</option>
                {practioners.map((practioner, id) => {
                  return (
                    <option key={id} value={practioner.id}>
                      {practioner.practioner.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          <ModalFooter>
            <Button
              color="light"
              type="button"
              className="btn btn-light"
              onClick={onClose}
            >
              Back
            </Button>
            <Button
              disabled={!currentPractioner || !currentClinic || isLoading}
              color="primary"
              type="submit"
            >
              {isLoading ? "Please wait" : "Update"}
            </Button>
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default AddClinicModal;
