import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Col,
  Row,
  Container,
  CardBody,
  Button,
  ListGroup,
  ListGroupItem,
  Spinner,
  Input,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import AddAccommodationModal from "./AddAccommodationModal";
import AddClinicModal from "./AddClinicModal";
import AddFlightModal from "./AddFlightModal";

import "react-toastify/dist/ReactToastify.css";

const offerStatus = {
  draft: "Kullanıcı teklifinizi bekliyor.",
  sent: "Teklif gönderildi.",
  complete: "Teklif tamamlandı.",
  reject: "Teklif reddedildi.",
  cancel: "Teklif iptal edildi.",
  waiting_user_response: "Kullanıcı yanıtı bekleniyor.",
  expired: "Bu teklifin süresi doldu.",
  accept_offer: "Kullanıcı teklifi kabul etti.",
};

const OfferDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [details, setDetails] = useState();
  const [answers, setAnswers] = useState([]);
  const [currentEditItem, setCurrentEditItem] = useState({
    index: -1,
    value: "",
    id: -1,
  });

  const [isAddClinicOpen, setAddClinicOpen] = useState(false);
  const [isAddAccommodationOpen, setAddAccommodationOpen] = useState(false);
  const [isFlightClass, setIsAddFlightClass] = useState(false);
  const [additionalServices, setAdditionalServices] = useState([]);

  const isDisabled =
    details?.cost &&
    details?.accommodation?.id &&
    details?.airline?.id &&
    details?.clinic?.id &&
    details?.flightClass?.id &&
    details?.flightTicket?.id &&
    details?.practitioner?.id;

  const getDetails = async () => {
    const response = await axios.get(`/offer/${id}`);

    if (response.additionalServices.length !== 0)
      setAdditionalServices(response.additionalServices);
    setDetails(response);

    if (response?.answers?.length > 0) {
      const answerGroup = response.answers.reduce((acc, item) => {
        let group = acc.find((g) => g.title === item.question.questionType);

        if (!group) {
          group = { title: item.question.questionType, list: [] };
          acc.push(group);
        }
        group.list.push(item);

        return acc;
      }, []);
      console.log(answerGroup);
      setAnswers(answerGroup);
    }
    toast("Detaylar yükleniyor", {
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      className: "bg-success text-white",
    });
    setAddAccommodationOpen(false);
    setAddClinicOpen(false);
  };

  useEffect(() => {
    getDetails();
  }, []);

  const sendOffer = async () => {
    if (details?.status === "accept_offer") {
      toast("Bu teklif zaten kullanıcı tarafından kabul edildi", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
      return;
    }
    try {
      await axios.patch(`/offer/offer-status/${id}`);
      toast("Teklif kullanıcıya gönderildi", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
      setDetails((prevDetails) => ({
        ...prevDetails,
        status: "waiting_user_response",
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const updatePrice = async () => {
    try {
      await axios.patch(`/offer/${id}`, {
        cost: details.cost,
      });
      toast("Fiyat güncellendi", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const addNewField = () => {
    setAdditionalServices((prevAdditionalServices) => [
      ...prevAdditionalServices,
      { title: "", description: "" },
    ]);
  };

  const deleteField = async (i) => {
    const service = additionalServices[i];
    if (service.id) {
      await axios.delete(`/offer-additional-service/${service.id}`);
    }

    setAdditionalServices((prevAdditionalServices) =>
      [...prevAdditionalServices].filter((_, index) => index !== i)
    );
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const services = [...additionalServices];
    services[index][name] = value;
    setAdditionalServices(services);
  };

  const createAdditionalService = async () => {
    try {
      await axios.post(`/offer-additional-service/${id}`, {
        additionalServices,
      });

      toast("Yeni ek hizmetler oluşturuldu", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateOffer = async () => {
    try {
      await axios.patch(`/offer/${id}`, {
        airline: details?.airline?.id,
        cost: details?.cost,
        accommodation: details?.accommodation?.id,
        clinic: details?.clinic?.id,
        flightClass: details?.flightClass?.id,
        flightTicket: details?.flightTicket?.id,
        practitioner: details?.practitioner?.id,
      });
      toast("Teklif güncellendi", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const rejectOffer = async () => {
    try {
      await axios.patch(`/offer/reject/${id}`, {
        status: "reject",
      });
      toast("Teklif reddedildi", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateAnswers = async () => {
    try {
      await axios.patch(`/answer/${currentEditItem.id}`, {
        content: currentEditItem.value,
      });

      toast("Cevap güncellendi", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });

      // Soruların güncel hali için tekrar yükleme
      await getDetails();

      setCurrentEditItem({ id: -1, index: -1, value: "" });
    } catch (error) {
      console.error("Hata:", error);
      toast("Cevap güncellenemedi", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-danger text-white",
      });
    }
  };

  const deleteAnswer = async (id) => {
    try {
      await axios.delete(`/answer/${id}`);
      toast("Cevap silindi", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
      setDetails((prevDetails) => ({
        ...prevDetails,
        answers: prevDetails.answers.filter((item) => item.id !== id),
      }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <ToastContainer />
          <AddClinicModal
            id={id}
            isOpen={isAddClinicOpen}
            onClose={() => setAddClinicOpen(false)}
            onUpdate={getDetails}
          />
          <AddAccommodationModal
            id={id}
            isOpen={isAddAccommodationOpen}
            onClose={() => setAddAccommodationOpen(false)}
            onUpdate={getDetails}
          />
          <AddFlightModal
            id={id}
            isOpen={isFlightClass}
            onClose={() => setIsAddFlightClass(false)}
            onUpdate={getDetails}
          />
          {details ? (
            <Row>
              <BreadCrumb title="Teklif Detayı" pageTitle="Teklif Detayı" />
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardHeader>
                      <h4 className="card-title mb-0">
                        Teklif Numarası: <b>{details.offerNumber}</b>
                      </h4>
                    </CardHeader>

                    <CardBody>
                      <ListGroup>
                        <Card className="mb-4">
                          <CardHeader>
                            <h5 className="mb-0">
                              <i className="ri-information-line align-middle me-2"></i>
                              Teklif Detayları
                            </h5>
                          </CardHeader>
                          <CardBody>
                            {/* Teklif Bilgileri, Hizmetler, Yolcu Bilgileri Grupları Burada */}
                            <Card className="mb-3">
                              <CardHeader className="bg-light">
                                <h6 className="mb-0">Genel Bilgiler</h6>
                              </CardHeader>
                              <CardBody>
                                <p>
                                  <b>Teklif Numarası:</b> {details.offerNumber}
                                </p>
                                <p>
                                  <b>Id:</b> {details.id}
                                </p>
                                <p>
                                  <b>Durum:</b> {offerStatus[details.status]}
                                </p>
                                <p>
                                  <b>Oluşturulma Tarihi:</b>{" "}
                                  {new Intl.DateTimeFormat("tr", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }).format(new Date(details.createdAt))}
                                </p>
                                <p>
                                  <b>Güncellenme Tarihi:</b>{" "}
                                  {new Intl.DateTimeFormat("tr", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }).format(new Date(details.updatedAt))}
                                </p>
                              </CardBody>
                            </Card>

                            {/* Hizmet Bilgileri Grubu */}
                            <Card className="mb-3">
                              <CardHeader className="bg-light">
                                <h6 className="mb-0">Hizmet Bilgileri</h6>
                              </CardHeader>
                              <CardBody>
                                <p>
                                  <b>Hizmet:</b> {details.service.locales.name}
                                </p>
                                <p>
                                  <b>Alt Hizmet:</b>{" "}
                                  {details.service.subServices
                                    ? details.service.subServices.translations
                                        .name
                                    : "Boş"}
                                </p>
                                <p>
                                  <b>Açıklama:</b>{" "}
                                  {details.additionalHealthNote}
                                </p>
                                <p>
                                  <b>Hizmet Ek Not:</b>{" "}
                                  {details.additionalServiceNote}
                                </p>
                              </CardBody>
                            </Card>

                            {/* Seyahat ve Konaklama Bilgileri Grubu */}
                            <Card className="mb-3">
                              <CardHeader className="bg-light">
                                <h6 className="mb-0">
                                  Seyahat ve Konaklama Bilgileri
                                </h6>
                              </CardHeader>
                              <CardBody>
                                <p>
                                  <b>Gidiş Şehri:</b> {details?.cityFrom ?? ""}
                                </p>
                                <p>
                                  <b>Varış Şehri:</b> {details?.cityTo ?? ""}
                                </p>
                                <p>
                                  <b>Dönüş Tarihi:</b>{" "}
                                  {new Intl.DateTimeFormat("tr", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }).format(new Date(details.returnDate))}
                                </p>
                                <p>
                                  <b>Gidiş Tarihi:</b>{" "}
                                  {new Intl.DateTimeFormat("tr", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }).format(new Date(details.departureDate))}
                                </p>
                                <p>
                                  <b>Konaklama Ek Not:</b>{" "}
                                  {details.additionalAccommodationNote}
                                </p>
                                <p>
                                  <b>Seyahat Ek Not:</b>{" "}
                                  {details.additionalTravelNote}
                                </p>
                              </CardBody>
                            </Card>

                            {/* Yolcu Bilgileri Grubu */}
                            <Card className="mb-3">
                              <CardHeader className="bg-light">
                                <h6 className="mb-0">Yolcu Bilgileri</h6>
                              </CardHeader>
                              <CardBody>
                                <p>
                                  <b>Yolcular:</b> {details.adultCount}{" "}
                                  Yetişkin, {details.childrenCount} Çocuk,{" "}
                                  {details.babyCount} Bebek
                                </p>
                              </CardBody>
                            </Card>

                            {/* Klinik ve Diğer Hizmet Bilgileri */}
                            <Card className="mb-3">
                              <CardHeader className="bg-light">
                                <h6 className="mb-0">Klinik ve Ek Hizmetler</h6>
                              </CardHeader>
                              <CardBody>
                                <p>
                                  <b>Klinik ve Uygulayıcı:</b>{" "}
                                  {details?.clinic?.fullName || "Belirlenmedi"}{" "}
                                  -{" "}
                                  {details?.practitioner?.name ||
                                    "Belirlenmedi"}{" "}
                                  <Button
                                    size="sm"
                                    color="info"
                                    onClick={() => setAddClinicOpen(true)}
                                  >
                                    Güncelle
                                  </Button>
                                </p>
                                <p>
                                  <b>Konaklama:</b>{" "}
                                  {details?.accommodation?.locales?.name ||
                                    "Belirlenmedi"}{" "}
                                  <Button
                                    size="sm"
                                    color="info"
                                    onClick={() =>
                                      setAddAccommodationOpen(true)
                                    }
                                  >
                                    Güncelle
                                  </Button>
                                </p>
                                <p>
                                  <b>Uçuş Sınıfı:</b>{" "}
                                  {details?.flightClass?.name || "Belirlenmedi"}{" "}
                                  <br />
                                  <b>Havayolu:</b>{" "}
                                  {details?.airline?.name || "Belirlenmedi"}{" "}
                                  <Button
                                    size="sm"
                                    color="info"
                                    onClick={() => setIsAddFlightClass(true)}
                                  >
                                    Güncelle
                                  </Button>
                                </p>
                              </CardBody>
                            </Card>

                            {/* Fiyat ve Ek Hizmetler */}
                            <Card className="mb-3">
                              <CardHeader className="bg-light">
                                <h6 className="mb-0">Fiyat ve Ek Hizmetler</h6>
                              </CardHeader>
                              <CardBody>
                                <p>
                                  <b>Fiyat ({details?.currency}):</b>
                                  <Input
                                    type="number"
                                    className="form-control mt-2"
                                    placeholder="Fiyat giriniz"
                                    value={details?.cost ?? ""}
                                    onChange={(e) =>
                                      setDetails((prevDetails) => ({
                                        ...prevDetails,
                                        cost: e.target.valueAsNumber || "",
                                      }))
                                    }
                                  />
                                  <Button
                                    className="mt-2"
                                    size="sm"
                                    color="success"
                                    onClick={updatePrice}
                                  >
                                    Güncelle
                                  </Button>
                                </p>

                                <div>
                                  <b>Ek Hizmetler:</b>
                                  {additionalServices.length > 0 ? (
                                    additionalServices.map((item, i) => (
                                      <div
                                        key={i}
                                        className="d-flex gap-2 mt-2"
                                      >
                                        <Input
                                          type="text"
                                          placeholder="Başlık"
                                          value={item.title}
                                          onChange={(e) => {
                                            const updatedServices = [
                                              ...additionalServices,
                                            ];
                                            updatedServices[i].title =
                                              e.target.value;
                                            setAdditionalServices(
                                              updatedServices
                                            );
                                          }}
                                        />
                                        <Input
                                          type="text"
                                          placeholder="Açıklama"
                                          value={item.description}
                                          onChange={(e) => {
                                            const updatedServices = [
                                              ...additionalServices,
                                            ];
                                            updatedServices[i].description =
                                              e.target.value;
                                            setAdditionalServices(
                                              updatedServices
                                            );
                                          }}
                                        />
                                        <Button
                                          size="sm"
                                          color="danger"
                                          onClick={() => {
                                            const updatedServices =
                                              additionalServices.filter(
                                                (_, index) => index !== i
                                              );
                                            setAdditionalServices(
                                              updatedServices
                                            );
                                          }}
                                        >
                                          Sil
                                        </Button>
                                      </div>
                                    ))
                                  ) : (
                                    <p className="text-muted">
                                      Henüz ek hizmet bulunmuyor.
                                    </p>
                                  )}
                                  <Button
                                    className="mt-3 me-2" // Sağ tarafa boşluk eklemek için `me-2` kullanıldı
                                    size="sm"
                                    color="info" // Rengi `primary` yerine `info` yapıldı
                                    onClick={() => {
                                      setAdditionalServices([
                                        ...additionalServices,
                                        { title: "", description: "" },
                                      ]);
                                    }}
                                  >
                                    Ek Hizmet Alanı Ekle
                                  </Button>

                                  {/* Update Butonu */}
                                  {additionalServices.length > 0 && (
                                    <Button
                                      className="mt-3"
                                      size="sm"
                                      color="success"
                                      onClick={createAdditionalService}
                                    >
                                      Güncelle
                                    </Button>
                                  )}
                                </div>
                              </CardBody>
                            </Card>

                            {/* Medya */}
                            <Card className="mb-3">
                              <CardHeader className="bg-light">
                                <h6 className="mb-0">Medya</h6>
                              </CardHeader>
                              <CardBody>
                                {details?.fileCollection?.files?.length > 0 ? (
                                  <div className="d-flex gap-2 flex-wrap">
                                    {details.fileCollection.files.map(
                                      (file, i) => (
                                        <img
                                          key={file.id}
                                          src={file.path}
                                          alt="Media"
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                            borderRadius: "6px",
                                          }}
                                        />
                                      )
                                    )}
                                  </div>
                                ) : (
                                  <p className="text-muted">
                                    Henüz medya bulunmuyor.
                                  </p>
                                )}
                              </CardBody>
                            </Card>
                          </CardBody>
                        </Card>
                        <Card className="mb-4">
                          <CardHeader>
                            <h5 className="mb-0">
                              <i className="ri-question-line align-middle me-2"></i>
                              Sorular ve Cevaplar
                            </h5>
                          </CardHeader>
                          <CardBody>
                            {answers?.length > 0 ? (
                              answers.map((listItem, i) => (
                                <Card key={i} className="mb-3">
                                  <CardHeader className="bg-light">
                                    <h6
                                      className="mb-0"
                                      style={{ fontWeight: 600 }}
                                    >
                                      {listItem.title.toUpperCase()}
                                    </h6>
                                  </CardHeader>
                                  <CardBody>
                                    {listItem.list.map((item) => (
                                      <div
                                        key={item.id}
                                        className="mb-3 p-3 border rounded"
                                      >
                                        <b>
                                          {item.question.questionI18n.name}:
                                        </b>
                                        {currentEditItem.id === item.id ? ( // Sadece seçili soruyu düzenle
                                          <div className="mt-2">
                                            <input
                                              className="form-control mb-2"
                                              placeholder="Cevap giriniz"
                                              value={currentEditItem.value}
                                              onChange={(e) =>
                                                setCurrentEditItem(
                                                  (prevEditItem) => ({
                                                    ...prevEditItem,
                                                    value: e.target.value,
                                                  })
                                                )
                                              }
                                            />
                                            <div className="d-flex gap-2">
                                              <Button
                                                size="sm"
                                                color="secondary"
                                                onClick={() =>
                                                  setCurrentEditItem({
                                                    id: null,
                                                    value: "",
                                                  })
                                                }
                                              >
                                                Vazgeç
                                              </Button>
                                              <Button
                                                size="sm"
                                                color="success"
                                                onClick={updateAnswers}
                                              >
                                                Kaydet
                                              </Button>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="d-flex justify-content-between mt-2">
                                            <span>{item.content}</span>
                                            <div>
                                              <Button
                                                size="sm"
                                                color="info"
                                                className="me-2"
                                                onClick={() =>
                                                  setCurrentEditItem({
                                                    id: item.id,
                                                    value: item.content,
                                                  })
                                                }
                                              >
                                                Düzenle
                                              </Button>
                                              <Button
                                                size="sm"
                                                color="danger"
                                                onClick={() =>
                                                  deleteAnswer(item.id)
                                                }
                                              >
                                                Sil
                                              </Button>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </CardBody>
                                </Card>
                              ))
                            ) : (
                              <p className="text-muted">
                                Henüz herhangi bir soru veya cevap
                                bulunmamaktadır.
                              </p>
                            )}
                          </CardBody>
                        </Card>
                        <Card className="mt-2">
                          <CardHeader>
                            <h5 className="mb-0">
                              <i className="ri-tools-line align-middle me-2"></i>
                              Teklif İşlemleri
                            </h5>
                          </CardHeader>
                          <CardBody>
                            <div className="d-flex gap-2">
                              <Button
                                className="btn btn-success"
                                disabled={!isDisabled}
                                onClick={sendOffer}
                              >
                                Teklifi Gönder
                              </Button>
                              <Button
                                className="btn btn-info"
                                disabled={!isDisabled}
                                onClick={updateOffer}
                              >
                                Güncelle
                              </Button>
                              <Button
                                className="btn btn-danger"
                                onClick={rejectOffer}
                              >
                                Reddet
                              </Button>
                            </div>
                          </CardBody>
                        </Card>
                      </ListGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Row>
          ) : (
            <Spinner />
          )}
        </Container>
      </div>
    </>
  );
};

export default OfferDetail;
