import React, { useState } from "react";
import { Alert, Button, Col, Container } from "reactstrap";
import { UseUpdateForm } from "../../Components/Hooks/UseUpdateForm";
import { useParams } from "react-router-dom";

const EditPolicy = () => {
  document.title = "Edit Policy - VisitTurkey";
  const { id } = useParams();

  const [language, setLanguage] = useState("tr");

  const { renderForm: renderLanguageForm } = UseUpdateForm(
    `/policy/${id}/${language}`,
    `/policy/${id}/${language}`,
    [language],
    {
      fields: [
        {
          field: "id",
          readOnly: "readonly",
          type: "input",
          label: "Id",
          required: "required",
        },
        {
          field: "title",
          type: "text",
          label: "Title",
          required: "required",
        },
        {
          field: "slug",
          type: "text",
          label: "Slug",
          required: "required",
        },
        {
          field: "content",
          type: "text",
          label: "Content",
          required: "required",
          richText: true,
        },
      ],
    }
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Col style={{ marginBlock: "2rem" }}>
            <Alert color="info">Please choose a locale for update</Alert>
            <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
              <Button
                onClick={() => setLanguage("tr")}
                className={language === "tr" ? "fw-bold" : ""}
              >
                Turkish
              </Button>
              <Button
                onClick={() => setLanguage("ar")}
                dir="rtl"
                className={language === "ar" ? "fw-bold" : ""}
              >
                Arabic
              </Button>
              <Button
                onClick={() => setLanguage("en")}
                className={language === "en" ? "fw-bold" : ""}
              >
                English
              </Button>
            </div>
          </Col>
          <Col>{renderLanguageForm()}</Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditPolicy;
