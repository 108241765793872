import React, { useState, useEffect } from "react";
import { Alert, Button, Col, Container, Row } from "reactstrap";
import { UseUpdateForm } from "../../Components/Hooks/UseUpdateForm";
import { useParams } from "react-router-dom";
import axios from "axios";
import { UseUpdateSingleFile } from "../../Components/Hooks/UseUpdateSingleFile";

const EditSection = () => {
  document.title = "Edit Section - VisitTurkey";
  const { id } = useParams();

  const [language, setLanguage] = useState("tr");

  const [categories, setCategories] = useState([]);

  const { renderRedirectButton } = UseUpdateSingleFile(undefined, {
    pageColumn: "section",
    relationId: id,
  });

  const getCategories = async () => {
    try {
      const response = await axios.get("/service/category");
      if (response.length > 0) {
        const categoryList = response.map((category) => ({
          label: category.title,
          value: category.id,
        }));
        setCategories(categoryList);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const { renderForm: renderCommonForm } = UseUpdateForm(
    `/service/section/detail/${id}`,
    `/service/section/detail/${id}`,
    [],
    {
      fields: [
        {
          label: "Category",
          field: "categories",
          required: true,
          type: "select",
          multiple: true,
          options: categories,
        },
      ],
    }
  );

  const { renderForm: renderLanguageForm } = UseUpdateForm(
    `/service/section/detail/${id}/${language}`,
    `/service/section/detail/${id}/${language}`,
    [language],
    {
      fields: [
        {
          field: "id",
          readOnly: "readonly",
          type: "input",
          label: "Id",
          required: "required",
        },
        {
          field: "title",
          type: "text",
          label: "Title",
          required: "required",
        },
      ],
    }
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>{renderRedirectButton()}</Row>
          <Row>
            <Col>{renderCommonForm()}</Col>
          </Row>
          <Col style={{ marginBlock: "2rem" }}>
            <Alert color="info">Please choose a locale for update</Alert>
            <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
              <Button
                onClick={() => setLanguage("tr")}
                className={language === "tr" ? "fw-bold" : ""}
              >
                Turkish
              </Button>
              <Button
                onClick={() => setLanguage("ar")}
                dir="rtl"
                className={language === "ar" ? "fw-bold" : ""}
              >
                Arabic
              </Button>
              <Button
                onClick={() => setLanguage("en")}
                className={language === "en" ? "fw-bold" : ""}
              >
                English
              </Button>
            </div>
          </Col>
          <Col>{renderLanguageForm()}</Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditSection;
