// import React from "react";
// import { Card, CardBody, Col, Container, Row } from "reactstrap";

// import BreadCrumb from "../../Components/Common/BreadCrumb";

// import { useInsertForm } from "../../Components/Hooks/UseInsertForm";

// const AddOrganization = () => {
//   document.title = "Add New Organization | Visit Turkey";

//   const { renderForm } = useInsertForm(
//     [
//       {
//         label: "Organization Name",
//         name: "name",
//         placeholder: "enter org. adı",
//         required: true,
//         type: "text",
//       },
//     ],
//     [],
//     { postUrl: "/organization" }
//   );

//   return (
//     <React.Fragment>
//       <div className="page-content">
//         <Container fluid>
//           <BreadCrumb title="Add Organization" pageTitle="Add Question" />
//           <Row>
//             <Col>
//               <Card>
//                 <CardBody className="pt-2">
//                   <h5 className="card-title mb-3  mb-md-0 flex-grow-1">
//                     Add Organization
//                   </h5>
//                   {renderForm()}
//                 </CardBody>
//               </Card>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </React.Fragment>
//   );
// };

// export default AddOrganization;
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import axios from "axios";
import { useInsertForm } from "../../Components/Hooks/UseInsertForm";

const AddOrganization = () => {
  document.title = "Add New Organization | Visit Turkey";

  const { renderForm } = useInsertForm(
    [
      {
        label: "Type",
        name: "type",
        required: true,
        type: "selectbox",
        value: "hospital",
        disabled: true,
        options: [
          { label: "Hospital", value: "hospital" },
          { label: "Clinic", value: "clinic" },
        ],
      },
      {
        label: "Full Name",
        name: "fullName",
        placeholder: "enter full name",
        required: true,
        type: "text",
      },
      {
        label: "image",
        name: "image",
        required: true,
        type: "file",
        multiple: true,
        multiuploadcolumn: "clinic",
        uploadtype: "health-category",
      },
      {
        label: "Tax Number",
        name: "taxNumber",
        placeholder: "enter tax number",
        required: true,
        type: "number",
      },
      {
        label: "Tax Office",
        name: "taxOffice",
        placeholder: "enter tax office",
        required: true,
        type: "text",
      },
      {
        label: "address",
        name: "address",
        placeholder: "address",
        required: true,
        type: "text",
      },
      {
        label: "License Number",
        name: "licenseNumber",
        placeholder: "enter license number",
        required: true,
        type: "number",
      },
    ],
    [],
    { postUrl: "/organization/clinics" }
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>{renderForm()}</Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddOrganization;
