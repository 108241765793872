import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { Input, Label, Button } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const UseUpdateForm = (uri, updateUri, watch, options) => {
  const [data, setData] = useState();
  const form = useRef();
  const [attachData, setAttachData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const option = {};

  const { fields } = options;
  const richTextWarn = useRef({});
  console.log(option);
  const setRichTextValidation = () => {
    if (data?.body?.length > 0) {
      richTextWarn.current["body"] = "";
      return;
    }
    fields.forEach((field) => {
      if (field.richText && field.required) {
        richTextWarn.current = {
          ...richTextWarn.current,
          [field.field]: `${field.label} field is required`,
        };
      }
    });
  };

  const getData = async () => {
    setData(undefined);
    try {
      const response = await axios.get(uri);
      setData(response);

      if (response?.fileCollection) {
        const files = response.fileCollection.files?.map((item) => ({
          path: item.path,
          id: item.id,
        }));
        setFiles(files);
      }
      const checkboxes = fields.filter((x) => x.type === "checkbox");
      checkboxes.forEach((x) => {
        setAttachData((prev) => {
          return {
            ...prev,
            [x.field]: response["field"],
          };
        });
      });
    } catch (e) {
      toast(e?.response?.data?.message || e, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-warning text-white",
      });
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const validationMessages = [];
      Object.values(richTextWarn.current).forEach((x) => {
        if (x.length) {
          validationMessages.push(x);
        }
      });

      if (validationMessages.length) {
        return alert(validationMessages.join("\n"));
      }

      const formData = new FormData(form.current);
      const body = Object.fromEntries(formData);
      setLoading(true);
      await axios.patch(updateUri, { ...body, ...attachData, ...option });
      toast("This record is updated", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
      setRichTextValidation();
    } catch (e) {
      toast(e?.response?.data?.message || e, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-warning text-white",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    setRichTextValidation();
  }, [...watch]);

  useEffect(() => {
    setRichTextValidation();
  }, [data]);

  const deleteFile = async (id) => {
    try {
      const response = await axios.delete(`/files/${id}`);
      if (response?.data?.affected === 1 || response.affected === 1) {
        setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
        toast("File has been deleted successfully!", {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          className: "bg-success text-white",
        });
      }
    } catch (error) {
      toast(error?.response?.data?.message || error, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-warning text-white",
      });
    }
  };

  const renderForm = () => {
    return data ? (
      <form onSubmit={handleSubmit} ref={form}>
        <ToastContainer />
        {files?.length > 0 && (
          <div className="d-flex gap-2 flex-wrap mt-3">
            {files.map((file, i) => (
              <div key={file.id}>
                <div style={{ width: 100, height: 100, position: "relative" }}>
                  <img
                    src={file.path}
                    key={file.path}
                    style={{ width: 100, height: 100, borderRadius: 6 }}
                  />
                  <Button
                    onClick={() => deleteFile(file.id)}
                    style={{
                      position: "absolute",
                      top: -10,
                      right: -10,
                      zIndex: 10,
                      borderRadius: 100,
                      backgroundColor: "gray",
                      border: "1px solid gray",
                      width: 45,
                      height: 45,
                    }}
                  >
                    <i className="bx bx-trash"></i>
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}

        {fields.map((field, index) => {
          return (
            <div key={index}>
              <div style={{ marginBlock: "1rem" }}>
                <Label
                  htmlFor={`form-item-${field.field}`}
                  className="form-label text-capitalize"
                >
                  {field.label}
                </Label>
                {field?.type !== "select" && !field.richText && (
                  <Input
                    onChange={(e) => {
                      if (field.type === "checkbox") {
                        setAttachData((prev) => {
                          return {
                            ...prev,
                            [field.field]: e.target.checked ? true : false,
                          };
                        });
                      }
                    }}
                    name={field.field}
                    defaultChecked={
                      data[field.field] === true && field.type === "checkbox"
                        ? true
                        : false
                    }
                    defaultValue={data[field.field]}
                    id={`form-item-${field.field}`}
                    className="form-control"
                    {...field}
                  />
                )}
                {field?.type === "select" && (
                  <select
                    className="form-select"
                    name={field.field}
                    {...field}
                    defaultValue={attachData[field.field] ?? data[field.field]}
                    onChange={(e) => {
                      if (!field.multiple) {
                        setAttachData((prev) => {
                          return {
                            ...prev,
                            [field.field]: e.target.value,
                          };
                        });
                      } else {
                        const options = e.target.selectedOptions;
                        const values = Array.from(options).map(
                          ({ value }) => value
                        );
                        option[field.field] = values.map((y) => Number(y));
                      }
                    }}
                  >
                    {field.options.map((option) => {
                      return (
                        <option
                          key={Math.random() * 999}
                          value={option.value}
                          selected={
                            option.value ===
                            (attachData[field.field] ?? data[field.field])
                          }
                        >
                          {option.label}
                        </option>
                      );
                    })}
                  </select>
                )}
                {field?.richText === true && (
                  <>
                    <input
                      name={field.field}
                      hidden
                      defaultValue={data[field.field]}
                    />
                    <CKEditor
                      editor={ClassicEditor}
                      data={data[field.field]}
                      onChange={(_, editor) => {
                        document.querySelector(
                          `input[name='${field.field}']`
                        ).value = editor.getData();
                        setTimeout(() => {
                          if (richTextWarn.current[field.field] !== undefined) {
                            if (editor.getData().length > 0) {
                              richTextWarn.current[field.field] = "";
                            } else {
                              richTextWarn.current[
                                field.field
                              ] = `${field.label} field is required`;
                            }
                          }
                          console.log(richTextWarn.current);
                        }, 100);
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          );
        })}

        {fields.length > 0 && (
          <Button
            disabled={isLoading}
            type="submit"
            color="primary"
            className="mt-4 btn w-100 d-block"
          >
            {!isLoading ? "Submit" : "Please wait"}
          </Button>
        )}
      </form>
    ) : (
      <p>Please wait...</p>
    );
  };

  return {
    renderForm,
    data,
  };
};
