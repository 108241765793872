import { loginSuccess, setLocked, setLoading } from "./reducer";

export const loginUser = (user, cb) => async (dispatch) => {
  dispatch(loginSuccess({ ...user }));
  if (cb) {
    cb();
  }
};

export const loading = (isLoading) => (dispatch) => {
  dispatch(setLoading(isLoading));
};

export const lockUser = (locked) => async (dispatch) => {
  dispatch(setLocked(locked));
};

export const logoutUser = () => async (dispatch) => {};

export const socialLogin = (type, history) => async (dispatch) => {};

export const resetLoginFlag = () => async (dispatch) => {};
