import React, { useState, useEffect } from "react";
import { Alert, Button, Col, Container, Row } from "reactstrap";
import { UseUpdateForm } from "../../Components/Hooks/UseUpdateForm";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useInsertForm } from "../../Components/Hooks/UseInsertForm";

const EditDoctor = () => {
  document.title = "Edit Doctor - VisitTurkey";
  const [options, setOptions] = useState([]);

  const { renderForm } = useInsertForm(
    [
      {
        label: "Clinic or Hospital",
        name: "clinic",
        required: true,
        type: "selectbox",
        options,
      },
      {
        label: "Name",
        name: "name",
        placeholder: "enter name",
        required: true,
        type: "text",
      },
      {
        label: "image",
        name: "image",
        required: false,
        type: "file",
        multiple: true,
        multiuploadcolumn: "practitioner",
        uploadtype: "health-category",
      },
      {
        label: "License Number",
        name: "licenseNumber",
        placeholder: "enter license number",
        required: true,
        type: "number",
      },
      {
        label: "Field",
        name: "field",
        placeholder: "enter field",
        required: true,
        type: "text",
      },
      {
        label: "Slug",
        name: "slug",
        placeholder: "enter slug",
        required: true,
        type: "text",
      },
    ],
    [
      {
        label: "About",
        name: "about",
        placeholder: "about",
        required: true,
        type: "text",
        richText: true,
      },
    ],
    { postUrl: "/practioner" }
  );

  const getOptions = async () => {
    const response = await axios.get("/organization/all/clinic");
    setOptions(
      response.map((x) => {
        return {
          value: x.id.toString(),
          label: x.fullName,
        };
      })
    );
  };

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>{renderForm()}</Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditDoctor;
