import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Nav,
  NavItem,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  Label,
  Button,
  NavLink,
  FormFeedback,
} from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DeleteModal from "../../Components/Common/DeleteModal";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

const AddPage = () => {
  document.title = "Add Page | Visit Turkey";

  const [description, setDescription] = useState({ value: "", error: "" });

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: "",
      description: "",
      category: "",
      image: "",
      seo: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
      category: Yup.string().required("Please Enter Category"),
      //  image: Yup.string().required("Please Upload an Image"),
      seo: Yup.string().required("Please Enter SEO"),
    }),
    onSubmit: (values) => {
      if (!description.value) {
        setDescription({ value: "", error: "Please Enter Description" });
        return;
      }

      const newPage = {
        title: values["title"],
        description,
        category: values["category"],
        image: values["image"],
        seo: values["seo"],
      };

      setDescription({ value: "", error: "" });
      validation.resetForm();
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <DeleteModal
          show={deleteModal}
          onCloseClick={() => setDeleteModal(false)}
        />
        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        /> */}
        <Container fluid>
          <BreadCrumb title="Add Page" pageTitle="Add Page" />
          <Row>
            <Col>
              <Card>
                {/* <CardBody className="border border-dashed border-end-0 border-start-0">
                  <Form>
                    <Row className="g-3">
                      <Col xxl={5} sm={6}>
                        <div className="search-box">
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder="Search for application ID, company, designation status or something..."
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>
                      <Col xxl={2} sm={6}>
                        <div>
                          <Flatpickr
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select date"
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "multiple",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xxl={2} sm={4}>
                        <div>
                          <Select
                            options={option}
                            name="choices-single-default"
                            id="idStatus"
                          ></Select>
                        </div>
                      </Col>
                      <Col xxl={2} sm={4}>
                        <div>
                          <Select
                            options={option1}
                            name="choices-single-default"
                            id="idType"
                          ></Select>
                        </div>
                      </Col>
                      <Col xxl={1} sm={4}>
                        <div>
                          <Button
                            type="button"
                            color="primary"
                            className="btn w-100"
                            // onclick=""
                          >
                            {" "}
                            <i className="ri-equalizer-fill me-1 align-bottom"></i>
                            Filters
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody> */}
                <CardBody className="pt-2">
                  <h5 className="card-title mb-3  mb-md-0 flex-grow-1 ">
                    Add Page
                  </h5>

                  <Form
                    className="tablelist-form mt-3"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row className="g-3">
                      <Col lg={12}>
                        <div>
                          <Label htmlFor="title-field" className="form-label">
                            Title
                          </Label>
                          <Input
                            name="title"
                            id="title-field"
                            className="form-control"
                            placeholder="Enter Title"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.title || ""}
                            invalid={
                              validation.touched.title &&
                              validation.errors.title
                                ? true
                                : false
                            }
                          />
                          {validation.touched.title &&
                          validation.errors.title ? (
                            <FormFeedback type="invalid">
                              {validation.errors.title}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={12}>
                        <Label className="form-label">Description</Label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={description.value}
                          //onReady={(editor) => {                     }}
                          onChange={(_, editor) =>
                            setDescription({
                              value: editor.getData(),
                              error: "",
                            })
                          }
                        />
                        {description.error && (
                          <p className="text-danger">{description.error}</p>
                        )}
                      </Col>
                      <Col lg={12}>
                        <div>
                          <Label
                            className="form-label"
                            htmlFor="project-thumbnail-img"
                          >
                            Thumbnail Image
                          </Label>
                          <Input
                            className="form-control"
                            id="project-thumbnail-img"
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                          />

                          {/* <Input
                            name="name"
                            id="customername-field"
                            className="form-control"
                            placeholder="Enter Name"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null} */}
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div>
                          <Label
                            htmlFor="category-field"
                            className="form-label"
                          >
                            Category
                          </Label>
                          <Input
                            name="category"
                            id="category-field"
                            className="form-control"
                            placeholder="Enter Category"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.category || ""}
                            invalid={
                              validation.touched.category &&
                              validation.errors.category
                                ? true
                                : false
                            }
                          />
                          {validation.touched.category &&
                          validation.errors.category ? (
                            <FormFeedback type="invalid">
                              {validation.errors.category}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div>
                          <Label htmlFor="seo-field" className="form-label">
                            SEO
                          </Label>
                          <Input
                            name="seo"
                            id="seo-field"
                            className="form-control"
                            placeholder="Enter SEO"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.seo || ""}
                            invalid={
                              validation.touched.seo && validation.errors.seo
                                ? true
                                : false
                            }
                          />
                          {validation.touched.seo && validation.errors.seo ? (
                            <FormFeedback type="invalid">
                              {validation.errors.seo}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <div className="hstack gap-2 mt-4 justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-success"
                        id="add-btn"
                      >
                        {" "}
                        Add Page
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddPage;
