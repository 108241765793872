import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import React, { useEffect, useRef, useState } from "react";
import { Input, Label, Button } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import tr from "../../locales/tr.json";
import en from "../../locales/en.json";
import ar from "../../locales/ar.json";

export const useInsertForm = (form = [], locales = [], submit) => {
  const { postUrl } = submit;
  const formRef = useRef();
  const localesRef = useRef();
  const files = useRef([]);

  const AVAILABLE_LANGUAGES = ["ar", "tr", "en"];
  const LANGUAGE_MAP = [ar, tr, en];
  const localeBody = {};
  let attachData = {};
  const [isLoading, setLoading] = useState(false);
  const richTextWarn = useRef({});

  let isSingleUpload = null;
  let isMultipleUpload = null;
  let uploadType = null;
  Array.from(
    new Set(
      form
        .map((c) => {
          if (c.propname) {
            isSingleUpload = c.propname;
          }
          if (c.multiuploadcolumn) {
            isMultipleUpload = c.multiuploadcolumn;
          }
          if (c.uploadtype) {
            uploadType = c.uploadtype;
          }
        })
        .flat()
    )
  );

  const handleFiles = (e) => {
    if (!e.target.files) {
      return;
    }
    files.current = e.target.files;
  };

  const handleCheckbox = (e) => {
    if (e.target.type !== "checkbox") {
      return;
    }
    attachData = {
      ...attachData,
      [e.target.name]: e.target.checked,
    };
  };

  const hasSelectboxValue = () => {
    attachData = {
      ...attachData,
      type: form.filter((item) => item.type === "selectbox" && !!item?.value)[0]
        ?.value,
    };
  };

  useEffect(() => {
    hasSelectboxValue();
  }, []);

  const setRichTextValidation = () => {
    AVAILABLE_LANGUAGES.forEach((lang) => {
      locales.forEach((locale) => {
        if (locale.richText && locale.required === true) {
          richTextWarn.current[lang] = {
            ...richTextWarn.current[lang],
            [locale.name]: `${locale.label} (${lang}) field is required`,
          };
        }
      });
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const validationMessages = [];
      const entires = Object.entries(richTextWarn.current);
      for (const entry in entires) {
        const x = entires[entry];
        const y = Object.values(x[1]);
        for (const z in y) {
          if (y[z].length >= 1) {
            validationMessages.push(y[z]);
          }
        }
      }

      LANGUAGE_MAP.forEach((lang) => {
        try {
          let messages = LANGUAGE_MAP[lang]; // Dil dosyasını dinamik olarak seç

          if (!messages) {
            // Eğer dil dosyası yoksa tr.json'a fallback yap
            messages = LANGUAGE_MAP["tr"]; // Varsayılan olarak Türkçe'yi kullan
          }

          locales.forEach((locale) => {
            if (locale.richText && locale.required === true) {
              if (
                !localeBody[lang] ||
                localeBody[lang][locale.name].trim().length === 0
              ) {
                richTextWarn.current[lang] = {
                  ...richTextWarn.current[lang],
                  [locale.name]: messages.required_field.replace(
                    "{field}",
                    locale.label
                  ),
                };
              } else {
                // Eğer field doluysa, uyarıyı temizleyelim
                if (richTextWarn.current[lang]) {
                  delete richTextWarn.current[lang][locale.name];
                }
              }
            }
          });
        } catch (error) {
          const messages = LANGUAGE_MAP["tr"];
        }
      });

      console.log(validationMessages);
      if (validationMessages.length > 0) {
        return alert(validationMessages.join("\n").replace(/,/g, ""));
      }

      if (isSingleUpload && files?.current?.length > 0) {
        const formData = new FormData();
        formData.append("files", files.current[0]);
        const upload = await axios.post("/files/upload-and-get-url", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        attachData = {
          ...attachData,
          [isSingleUpload]: upload[0].Location,
        };
      }
      const formData = new FormData(formRef.current);

      const body = JSON.stringify({
        ...Object.fromEntries(formData),
        ...attachData,
        locales: Object.entries(localeBody).map((y) => y[1]),
      });

      setLoading(true);
      const response = await axios.post(postUrl, body);

      if (isMultipleUpload && files?.current?.length > 0) {
        const formData = new FormData();
        Array.from(files.current).map((file) => formData.append("files", file));

        const responseId =
          uploadType === "section"
            ? response[0].section.id
            : uploadType === "category"
            ? response[0].category.id
            : response[uploadType]?.id ||
              response[isMultipleUpload.replace("practitioner", "practioner")]
                ?.id ||
              response.id;

        formData.append("type", uploadType);
        formData.append("column", isMultipleUpload);
        formData.append("relationId", responseId);

        const fileResponse = await axios.post(
          "/files/upload/multiple",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (postUrl === "/blog") {
          await axios.patch(`/blog/detail/${responseId}`, {
            fileCollection: { id: fileResponse.collectionId },
          });
        }
        if (postUrl === "/service/category") {
          await axios.patch(`/service/category/detail/${responseId}`, {
            fileCollection: { id: fileResponse.collectionId },
          });
        }
        if (postUrl === "/service/sections") {
          await axios.patch(`/service/section/detail/file/${responseId}`, {
            fileCollection: { id: fileResponse.collectionId },
          });
        }
      }

      toast("New Record added", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
      setRichTextValidation();
    } catch (e) {
      console.log(e);
      toast(e?.response?.data?.message || e, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-warning text-white",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    form.map((x) => {
      if (x.type === "checkbox") {
        attachData = {
          ...attachData,
          [x.name]: x?.defaultChecked ? true : false,
        };
      }
    });
    setRichTextValidation();
  }, []);

  const renderForm = () => {
    return (
      <form ref={formRef} onSubmit={handleSubmit}>
        <ToastContainer />
        {form.map((field, index) => {
          return (
            <div key={Math.random() * 9999} className="mt-4">
              <Label
                htmlFor={`form-item-${index}`}
                className="form-label text-capitalize"
              >
                {field.label}
              </Label>

              {field.type === "selectbox" && (
                <select
                  className="form-control"
                  id={`form-item-${index}`}
                  {...field}
                  onChange={(e) => {
                    if (field.multiple) {
                      const options = e.target.selectedOptions;
                      const values = Array.from(options).map(
                        ({ value }) => value
                      );
                      if (field.name === "service") {
                        attachData = {
                          ...attachData,
                          service: values.map((y) => Number(y)),
                        };
                      } else {
                        attachData = {
                          ...attachData,
                          categoryIds: values.map((y) => Number(y)),
                        };
                      }
                    }
                  }}
                >
                  <option value={""}>Choose</option>
                  {(field.options ?? []).map((option) => {
                    return (
                      <option
                        key={Math.random() * 999}
                        selected={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    );
                  })}
                </select>
              )}
              {field.type !== "selectbox" && !field?.richText && (
                <Input
                  onChange={(e) => {
                    handleFiles(e);
                    handleCheckbox(e);
                  }}
                  id={`form-item-${index}`}
                  className="form-control"
                  {...field}
                />
              )}
            </div>
          );
        })}
        <div ref={localesRef}>
          {AVAILABLE_LANGUAGES.map((lang) => {
            return (
              <div key={Math.random() * 999}>
                <h3 className="mt-4">
                  Locales for <span className="text-uppercase">{lang}</span>{" "}
                </h3>
                {locales.map((field, index) => {
                  return (
                    <div
                      key={Math.random() * 999}
                      className="mt-4"
                      dir={lang === "ar" ? "rtl" : "ltr"}
                    >
                      <Label
                        htmlFor={`form-item-${index}`}
                        className="form-label text-capitalize"
                      >
                        {field.label}{" "}
                        <span className="text-uppercase">{lang}</span>
                      </Label>
                      {field.richText ? (
                        <CKEditor
                          editor={ClassicEditor}
                          onChange={(_, editor) => {
                            localeBody[lang] = {
                              ...localeBody[lang],
                              [field.name]: editor.getData(),
                              language: lang,
                            };

                            if (richTextWarn.current[lang]) {
                              if (richTextWarn.current[lang][field.name]) {
                                if (editor.getData().length === 0) {
                                  richTextWarn.current[lang][
                                    field.name
                                  ] = `${field.label} ${lang} field is required`;
                                } else {
                                  richTextWarn.current[lang][field.name] = "";
                                }
                              }
                            }
                          }}
                        />
                      ) : (
                        <Input
                          onChange={(e) => {
                            localeBody[lang] = {
                              ...localeBody[lang],
                              [field.name]: e.target.value,
                              language: lang,
                            };
                          }}
                          id={`form-item-${index}`}
                          className="form-control"
                          {...field}
                          data-prop={`${field.name}`}
                        />
                      )}
                    </div>
                  );
                })}
                <hr />
              </div>
            );
          })}
        </div>

        <Button
          disabled={isLoading}
          type="submit"
          color="primary"
          className="mt-4 btn w-100 d-block"
        >
          {!isLoading ? "Submit" : "Please wait"}
        </Button>
      </form>
    );
  };

  return {
    renderForm,
  };
};
