import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";

import { useInsertForm } from "../../Components/Hooks/UseInsertForm";

const AddArticle = () => {
  document.title = "Add Article | Visit Turkey";

  const { renderForm } = useInsertForm(
    [
      {
        label: "blog type",
        name: "type",
        required: true,
        options: [
          { label: "Historical", value: "historical" },
          { label: "Touristical", value: "touristical" },
          { label: "City", value: "city" },
          { label: "Blog", value: "blog" },
        ],
        type: "selectbox",
      },
      {
        label: "Is Story",
        name: "isStory",
        required: false,
        type: "checkbox",
      },
      {
        label: "image",
        name: "image",
        placeholder: "enter",
        required: false,
        type: "file",
        multiple: true,
        multiuploadcolumn: "blog",
        uploadtype: "blog",
      },
    ],
    [
      {
        label: "Title",
        name: "title",
        placeholder: "enter title",
        required: true,
        type: "text",
      },
      {
        label: "Subtitle",
        name: "subtitle",
        placeholder: "enter subtitle",
        required: true,
        type: "text",
      },
      {
        label: "First Paragraph",
        name: "firstParagraph",
        placeholder: "enter paragraph",
        required: true,
        type: "text",
      },
      {
        label: "Slug",
        name: "slug",
        placeholder: "enter slug",
        required: true,
        type: "text",
      },
      {
        label: "Content",
        name: "body",
        placeholder: "enter content",
        required: true,
        type: "text",
        richText: true,
      },
    ],
    { postUrl: "/blog" }
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Add Article" pageTitle="Add Article" />
          <Row>
            <Col>
              <Card>
                <CardBody className="pt-2">
                  <h5 className="card-title mb-3  mb-md-0 flex-grow-1">
                    Add Article
                  </h5>
                  {renderForm()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddArticle;
