import React from "react";
import { Navigate, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useProfile } from "../Components/Hooks/UserHooks";
import BasicLockScreen from "../pages/AuthenticationInner/LockScreen/BasicLockScr";

const AuthProtected = (props) => {
  const { isLoggedin, isLoading, locked } = useProfile();
  if (!isLoggedin && !isLoading) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
  console.log(locked);
  return (
    <>
      {isLoading && <p>Please wait</p>}
      {!isLoading && locked && <BasicLockScreen />}
      {!isLoading && !locked && props.children}
    </>
  );
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <>
      <ToastContainer />
      <Route
        {...rest}
        render={(props) => {
          return (
            <>
              {" "}
              <Component {...props} />{" "}
            </>
          );
        }}
      />
    </>
  );
};

export { AuthProtected, AccessRoute };
