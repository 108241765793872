import React, { useState, useEffect } from "react";
import {
  Alert,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { UseUpdateForm } from "../../Components/Hooks/UseUpdateForm";
import { UseUpdateSingleFile } from "../../Components/Hooks/UseUpdateSingleFile";
import useHotelLanguageForm from "../../Components/Hooks/useHotelLanguageForm";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditHotel = () => {
  document.title = "Otel Düzenle - VisitTurkey";
  const { id } = useParams();

  const { renderRedirectButton } = UseUpdateSingleFile(undefined, {
    pageColumn: "accommodation",
    relationId: id,
  });

  const [options, setOptions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [language, setLanguage] = useState("tr");

  const { renderForm: renderCommonForm, data } = UseUpdateForm(
    `/accommodation/detail/${id}`,
    `/accommodation/detail/${id}`,
    [],
    {
      fields: [
        {
          field: "cityId",
          label: "Şehir",
          required: "required",
          type: "select",
          options,
        },
      ],
    }
  );

  const { renderForm: renderLanguageForm, switchLanguageTab } =
    useHotelLanguageForm(
      `/accommodation/detail/${id}/${language}`,
      language,
      (lang) => setLanguage(lang)
    );

  const getCities = async () => {
    const response = await axios.get("/accommodation/cities");
    setOptions(
      response.map((city) => {
        return {
          label: city.name,
          value: city.id.toString(),
        };
      })
    );
  };

  const getCategories = async () => {
    const response = await axios.get(
      "/accommodation/categories?page=1&take=100&language=tr&keyword="
    );
    setCategories(
      response.map((x) => {
        return {
          value: x.accommodationCategory.id.toString(),
          label: x.name,
        };
      })
    );
  };

  const handleUpdateCategories = async (e) => {
    e.preventDefault();
    await axios.patch(`/accommodation/update-category/${id}`, {
      ids: selectedCategories.map((x) => x),
    });
    toast("Kategoriler güncellendi", {
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      className: "bg-success text-white",
    });
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    setSelectedCategories(data?.categories.map((x) => x.id));
  }, [data]);

  useEffect(() => {
    Promise.all([getCities(), getCategories()]);
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <ToastContainer />
        <Container fluid>
          <Row>
            <Col>{renderCommonForm()}</Col>
          </Row>
          <Col style={{ marginBlock: "2rem" }}>
            <Alert color="info">Lütfen güncelleme için bir dil seçin</Alert>
            <Nav tabs>
              {["tr", "ar", "en"].map((lang) => (
                <NavItem key={lang}>
                  <NavLink
                    className={language === lang ? "active fw-bold" : ""}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setLanguage(lang);
                      switchLanguageTab(lang);
                    }}
                  >
                    {lang === "tr"
                      ? "Türkçe"
                      : lang === "ar"
                      ? "Arapça"
                      : "İngilizce"}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
          <Col>
            {renderLanguageForm([
              {
                field: "id",
                readOnly: "readonly",
                type: "input",
                label: "Id",
                required: "required",
              },
              {
                field: "name",
                type: "input",
                label: "İsim",
                required: "required",
              },
              {
                field: "description",
                type: "input",
                label: "Açıklama",
                required: "required",
                richText: true,
              },
              {
                field: "slug",
                type: "input",
                label: "Slug",
                required: "required",
              },
            ])}
          </Col>
          <Col>{renderRedirectButton()}</Col>
          <hr />
          <form onSubmit={handleUpdateCategories}>
            <Col
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <p>Kategoriler</p>
              <div
                className="checkbox-group"
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
                  gap: "1rem",
                  maxHeight: "200px",
                  overflowY: "auto",
                  padding: "0.5rem",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                }}
              >
                {categories.map((category) => (
                  <label
                    key={category.value}
                    htmlFor={`category-${category.value}`}
                    className="custom-checkbox"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0.5rem",
                      cursor: "pointer",
                      borderRadius: "4px",
                      transition: "background-color 0.3s",
                    }}
                  >
                    <input
                      type="checkbox"
                      id={`category-${category.value}`}
                      name="categories"
                      value={category.value}
                      checked={selectedCategories.includes(
                        Number(category.value)
                      )}
                      onChange={(e) => {
                        const selectedValues = e.target.checked
                          ? [...selectedCategories, Number(category.value)]
                          : selectedCategories.filter(
                              (v) => v !== Number(category.value)
                            );
                        setSelectedCategories(selectedValues);
                      }}
                      style={{ display: "none" }}
                    />
                    <span
                      className="checkbox-box"
                      style={{
                        display: "inline-block",
                        width: "16px",
                        height: "16px",
                        border: "2px solid #007bff",
                        borderRadius: "4px",
                        marginRight: "0.5rem",
                        transition: "all 0.3s",
                        backgroundColor: selectedCategories.includes(
                          Number(category.value)
                        )
                          ? "#007bff"
                          : "transparent",
                      }}
                    />
                    <span>{category.label}</span>
                  </label>
                ))}
              </div>
              <Button type="submit" className="btn btn-primary">
                Kategorileri Güncelle
              </Button>
            </Col>
          </form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditHotel;
