import { useState, useRef } from "react";
import { Input, Label, Button } from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const UseUpdateSingleFile = (updateUri, options) => {
  const [isLoading, setLoading] = useState(false);
  const file = useRef();
  const navigate = useNavigate();
  const handleChange = (e) => {
    file.current = e.target.files[0];
  };

  const handleRemoveFile = async () => {
    const isConfirmed = confirm("Do you confirm remove this file?");
    if (!isConfirmed) return;
    try {
      setLoading(true);
      await axios.patch(updateUri, {
        [options.column]: null,
      });
      toast(`Removed this ${options.label}`, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
    } catch (e) {
      toast(e?.response?.data?.message || e, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-warning text-white",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("files", file.current);
      const upload = await axios.post("/files/upload-and-get-url", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      const path = upload[0].Location;
      await axios.patch(updateUri, {
        [options.column]: path,
      });
      toast(`${options.label} file updated`, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
    } catch (e) {
      toast(e?.response?.data?.message || e, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-warning text-white",
      });
    } finally {
      setLoading(false);
    }
  };

  const renderFileInput = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div style={{ marginBlock: "1rem" }}>
          <Label
            htmlFor={`form-item-${options.label}`}
            className="form-label text-capitalize"
          >
            {options.label}
          </Label>
        </div>
        <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
          <Input
            accept="image/*"
            onChange={handleChange}
            {...options}
            type="file"
          />
          <Button onClick={handleRemoveFile} type="button" color="danger">
            Remove file
          </Button>
        </div>
        <Button
          disabled={isLoading}
          type="submit"
          color="primary"
          className="mt-4 btn btn-primary w-100 d-block"
        >
          {!isLoading ? "Submit" : "Please wait"}
        </Button>
      </form>
    );
  };

  const renderRedirectButton = () => {
    return (
      <div>
        <Button
          onClick={() => {
            navigate(`/files/add/${options.pageColumn}/${options.relationId}`);
          }}
          type="submit"
          color="info"
          className="mt-4 btn btn-primary w-100 d-block"
        >
          Add new images
        </Button>
        {/* <Button
          type="submit"
          color="primary"
          className="mt-4 btn w-100 d-block"
        >
          Manage Files
        </Button> */}
      </div>
    );
  };

  return {
    renderFileInput,
    renderRedirectButton,
  };
};
