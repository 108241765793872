import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import ParticlesAuth from "../ParticlesAuth";

import logoLight from "../../../assets/images/visit-turkey-logo.png";
import { useProfile } from "../../../Components/Hooks/UserHooks";
import axios from "axios";

const BasicLockScreen = () => {
  document.title = "Lock Screen | Visit Turkey";

  const { user, unlock } = useProfile();
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const cb = () => {
    window.location.href = "/dashboard";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/auth/login", {
        email: user.email,
        password,
      });
      localStorage.setItem("authUser", response.token);
      unlock();
      dispatch(loginUser(response.admin, cb));
    } catch (e) {
      setError(e?.response?.data?.message || e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content mt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/dashboard" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="120" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">Visit Turkey Dashboard</p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Lock Screen</h5>
                      <p className="text-muted">
                        Enter your password to unlock the screen!
                      </p>
                    </div>
                    {error && <Alert color="danger">{error}</Alert>}
                    <div
                      className="user-thumb text-center"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">
                        <div className="avatar-title text-uppercase border rounded-circle bg-light text-primary">
                          {user.name.substring(0, 2)}
                        </div>
                      </div>
                      <h5 className="font-size-15 mt-3 text-capitalize">
                        {user.name}
                      </h5>
                    </div>
                    <div className="p-2 mt-4">
                      <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="userpassword">
                            Password
                          </label>
                          <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="form-control"
                            id="userpassword"
                            placeholder="Enter password"
                            required
                          />
                        </div>
                        <div className="mb-2 mt-4">
                          <Button
                            disabled={isLoading}
                            color="success"
                            className="w-100"
                            type="submit"
                          >
                            {!isLoading ? "Unlock" : "Please wait"}
                          </Button>
                        </div>
                      </form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Not you ? return{" "}
                    <Link
                      to="/login"
                      className="fw-semibold text-primary text-decoration-underline"
                    >
                      {" "}
                      Login{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default BasicLockScreen;
