import React from "react";
import {
  Card,
  CardHeader,
  Col,
  Row,
  Container,
  CardBody,
  Button,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { usePagination } from "../../Components/Hooks/UsePagination";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSoftDelete } from "../../Components/Hooks/UseSoftDelete";
import { useNavigate } from "react-router-dom";

const AllDoctors = () => {
  const {
    data,
    emptyResult,
    renderFields,
    renderData,
    renderButtons,
    renderSearch,
  } = usePagination("/practioner", {
    fields: [
      { label: "id", column: "id", id: "id" },
      { label: "practioner", column: "practioner.name", id: "practioner.name" },
      { label: "field", column: "practioner.field", id: "practioner.field" },
      {
        label: "license number",
        id: "practioner.licenseNumber",
      },
      { label: "created at", column: "createdAt", id: "createdAt" },
      { label: "is Active", id: "isActive" },
      { label: "action", id: "action" },
    ],
  });
  const { renderSwitch } = useSoftDelete();
  const navigate = useNavigate();

  const deleteDoctor = async (id) => {
    try {
      await axios.delete(`/practioner/${id}`);
      toast("Doctor has been deleted successfully!", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
    } catch (error) {
      toast("An error occurred while deleting doctor", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
      if (process.env.NODE_ENV === "development") console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <ToastContainer />
          <Row>
            <BreadCrumb title="All Doctors" pageTitle="Tables" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title mb-0">View All Doctors</h4>
                  </CardHeader>

                  <CardBody>
                    <div className="listjs-table" id="customerList">
                      <Row className="g-4 mb-3">
                        <Col className="col-sm-auto">
                          <div>
                            <Button
                              color="success"
                              className="add-btn me-1"
                              onClick={() => navigate("/add-doctor")}
                              id="create-btn"
                            >
                              <i className="ri-add-line align-bottom me-1"></i>{" "}
                              Add
                            </Button>
                          </div>
                        </Col>
                        <Col className="col-sm">
                          <div className="d-flex justify-content-sm-end">
                            {renderSearch()}
                          </div>
                        </Col>
                      </Row>

                      <div className="table-responsive table-card mt-3 mb-1">
                        <table
                          className="table align-middle table-nowrap"
                          id="organizationTable"
                        >
                          <thead className="table-light">
                            <tr>{renderFields()}</tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {renderData((d) => (
                              <>
                                {d.id === "practioner.licenseNumber" && (
                                  <p className="text-primary">
                                    <b>{d.data["practioner.licenseNumber"]}</b>
                                  </p>
                                )}
                                {d.id === "isActive" &&
                                  renderSwitch(
                                    `/practioner/${d.data["practioner.id"]}`,
                                    !d.data["practioner.deletedAt"]
                                      ? true
                                      : false
                                  )}
                                {d.id === "action" && (
                                  <div
                                    className="d-flex"
                                    style={{ gap: "1rem" }}
                                  >
                                    <Button
                                      color="info"
                                      onClick={() => {
                                        navigate(
                                          `/edit-doctor/${d.data["practioner.id"]}`
                                        );
                                      }}
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      color="danger"
                                      onClick={() =>
                                        deleteDoctor(d.data["practioner.id"])
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                )}
                              </>
                            ))}
                          </tbody>
                        </table>
                        {(!data || !data?.length) && emptyResult()}
                      </div>

                      {renderButtons()}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AllDoctors;
