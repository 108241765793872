import React, { useState, useEffect } from "react";
import { Alert, Button, Col, Container, Row } from "reactstrap";
import { UseUpdateForm } from "../../Components/Hooks/UseUpdateForm";
import { useParams } from "react-router-dom";
import { UseUpdateSingleFile } from "../../Components/Hooks/UseUpdateSingleFile";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditHotel = () => {
  document.title = "Edit Article - VisitTurkey";
  const { id } = useParams();

  const [language, setLanguage] = useState("tr");
  const { renderRedirectButton } = UseUpdateSingleFile(undefined, {
    pageColumn: "accommodation",
    relationId: id,
  });

  const [options, setOptions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const { renderForm: renderCommonForm, data } = UseUpdateForm(
    `/accommodation/detail/${id}`,
    `/accommodation/detail/${id}`,
    [],
    {
      fields: [
        {
          field: "cityId",
          label: "City",
          required: "required",
          type: "select",
          options,
        },
      ],
    }
  );

  const { renderForm: renderLanguageForm } = UseUpdateForm(
    `/accommodation/detail/${id}/${language}`,
    `/accommodation/detail/${id}/${language}`,
    [language],
    {
      fields: [
        {
          field: "id",
          readOnly: "readonly",
          type: "input",
          label: "Id",
          required: "required",
        },
        {
          field: "name",
          type: "input",
          label: "Name",
          required: "required",
        },
        {
          field: "description",
          type: "input",
          label: "Description",
          required: "required",
          richText: true,
        },
        {
          field: "slug",
          type: "input",
          label: "Slug",
          required: "required",
        },
      ],
    }
  );

  const getCities = async () => {
    const response = await axios.get("/accommodation/cities");
    setOptions(
      response.map((city) => {
        return {
          label: city.name,
          value: city.id.toString(),
        };
      })
    );
  };

  const getCategories = async () => {
    const response = await axios.get(
      "/accommodation/categories?page=1&take=100&language=tr&keyword="
    );
    setCategories(
      response.map((x) => {
        return {
          value: x.accommodationCategory.id.toString(),
          label: x.name,
        };
      })
    );
  };

  const handleUpdateCategories = async (e) => {
    e.preventDefault();
    await axios.patch(`/accommodation/update-category/${id}`, {
      ids: selectedCategories.map((x) => {
        return {
          id: x,
        };
      }),
    });
    toast("Updated categories", {
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      className: "bg-success text-white",
    });
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    setSelectedCategories(data?.categories.map((x) => x.id));
  }, [data]);

  useEffect(() => {
    Promise.all([getCities(), getCategories()]);
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <ToastContainer />
        <Container fluid>
          <Row>
            <Col>{renderCommonForm()}</Col>
          </Row>
          <Col style={{ marginBlock: "2rem" }}>
            <Alert color="info">Please choose a locale for update</Alert>
            <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
              <Button
                onClick={() => setLanguage("tr")}
                className={language === "tr" ? "fw-bold" : ""}
              >
                Turkish
              </Button>
              <Button
                onClick={() => setLanguage("ar")}
                dir="rtl"
                className={language === "ar" ? "fw-bold" : ""}
              >
                Arabic
              </Button>
              <Button
                onClick={() => setLanguage("en")}
                className={language === "en" ? "fw-bold" : ""}
              >
                English
              </Button>
            </div>
          </Col>
          <Col>{renderLanguageForm()}</Col>
          <Col>{renderRedirectButton()}</Col>
          <hr />
          <form onSubmit={handleUpdateCategories}>
            <Col
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <p>Categories</p>
              <select
                id="categories"
                className="form-control"
                multiple
                required
                onChange={(e) => {
                  const selected = document.querySelectorAll(
                    "#categories option:checked"
                  );
                  const values = Array.from(selected).map((el) => el.value);
                  setSelectedCategories(values);
                }}
              >
                {categories.map((category) => {
                  return (
                    <option
                      selected={
                        selectedCategories.includes(Number(category.value))
                          ? true
                          : false
                      }
                      key={category.value}
                      value={category.value}
                    >
                      {category.label}
                    </option>
                  );
                })}
              </select>
              <Button type="submit">Update categories</Button>
            </Col>
          </form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditHotel;
