import React, { useState } from "react";
import { Alert, Button, Col, Container, Row } from "reactstrap";
import { UseUpdateForm } from "../../Components/Hooks/UseUpdateForm";
import { useParams } from "react-router-dom";
import { UseUpdateSingleFile } from "../../Components/Hooks/UseUpdateSingleFile";

const EditCategory = () => {
  document.title = "Edit Category - VisitTurkey";
  const { id } = useParams();

  const [language, setLanguage] = useState("tr");

  const { renderRedirectButton } = UseUpdateSingleFile(undefined, {
    pageColumn: "category",
    relationId: id,
  });

  const { renderForm: renderCommonForm } = UseUpdateForm(
    `/service/category/detail/${id}`,
    `/service/category/detail/${id}`,
    [],
    {
      fields: [
        {
          field: "isHomePageItem",
          type: "checkbox",
          label: "Is Home Page Item",
        },
        {
          field: "favorite",
          type: "checkbox",
          label: "Is Favorite",
        },
      ],
    }
  );

  const { renderForm: renderLanguageForm } = UseUpdateForm(
    `/service/category/detail/${id}/${language}`,
    `/service/category/detail/${id}/${language}`,
    [language],
    {
      fields: [
        {
          field: "id",
          readOnly: "readonly",
          type: "input",
          label: "Id",
          required: "required",
        },
        {
          field: "title",
          type: "text",
          label: "Title",
          required: "required",
        },
        {
          field: "slug",
          type: "text",
          label: "Slug",
          required: "required",
        },
      ],
    }
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>{renderRedirectButton()}</Row>
          <Row>
            <Col>{renderCommonForm()}</Col>
          </Row>
          <Col style={{ marginBlock: "2rem" }}>
            <Alert color="info">Please choose a locale for update</Alert>
            <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
              <Button
                onClick={() => setLanguage("tr")}
                className={language === "tr" ? "fw-bold" : ""}
              >
                Turkish
              </Button>
              <Button
                onClick={() => setLanguage("ar")}
                dir="rtl"
                className={language === "ar" ? "fw-bold" : ""}
              >
                Arabic
              </Button>
              <Button
                onClick={() => setLanguage("en")}
                className={language === "en" ? "fw-bold" : ""}
              >
                English
              </Button>
            </div>
          </Col>
          <Col>{renderLanguageForm()}</Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditCategory;
