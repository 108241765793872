import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { toast } from "react-toastify";

const AddAccommodationModal = ({ isOpen, onClose, onUpdate, id }) => {
  const [accommodation, setAccommodation] = useState([]);
  const [currentAccommodation, setCurrentAccommodation] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const getAccommodations = async () => {
    const response = await axios.get(
      `/accommodation?page=1&take=500&language=en&keyword=&withDeleted=false`
    );
    setAccommodation(response);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await axios.patch(`/offer/${id}`, {
        accommodation: { id: currentAccommodation.accommodation.id },
      });
      onUpdate();
    } catch (e) {
      console.log(e);
      toast(e?.response?.data?.message || e, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-warning text-white",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAccommodations();
  }, []);

  return (
    <Modal isOpen={isOpen} toggle={onClose} id="exampleModal">
      <ModalHeader>
        Add Accommodation
        <Button
          type="button"
          className="btn-close"
          onClick={onClose}
          aria-label="Close"
        ></Button>
      </ModalHeader>
      <ModalBody>
        {currentAccommodation && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              marginBlock: "1rem",
            }}
          >
            <h4>Accommodation:</h4>
            <ListGroup>
              <ListGroupItem>
                <i className="ri-file-copy-2-line align-middle me-2"></i>
                <b>Accommodation:</b>&nbsp; {currentAccommodation.name}
              </ListGroupItem>
            </ListGroup>
            <div>
              <Button>View More Details</Button>
            </div>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="name" className="col-form-label">
              Choose Accommodation:
            </label>
            <select
              required
              className="form-control"
              onChange={(e) => {
                if (!e.target.value) return;
                setCurrentAccommodation(
                  accommodation.find((x) => x.id === Number(e.target.value))
                );
              }}
            >
              <option value={""}>Choose</option>
              {accommodation.map((accommodation, id) => {
                return (
                  <option key={id} value={accommodation.id}>
                    {accommodation.name}
                  </option>
                );
              })}
            </select>
          </div>
          <ModalFooter>
            <Button
              color="light"
              type="button"
              className="btn btn-light"
              onClick={onClose}
            >
              Back
            </Button>
            <Button
              disabled={!currentAccommodation || isLoading}
              color="primary"
              type="submit"
            >
              Update
            </Button>
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default AddAccommodationModal;
