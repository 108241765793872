import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Col,
  Row,
  Container,
  CardBody,
  Button,
  ListGroup,
  ListGroupItem,
  Spinner,
  Input,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import AddAccommodationModal from "./AddAccommodationModal";
import AddClinicModal from "./AddClinicModal";
import AddFlightModal from "./AddFlightModal";

import "react-toastify/dist/ReactToastify.css";

const offerStatus = {
  draft: "User is waiting for your offer.",
  sent: "Offer has been sent.",
  complete: "Offer has been completed.",
  reject: "Offer has been rejected",
  cancel: "Offer has been cancelled",
  waiting_user_response: "Waiting for user response",
  expired: "This offer is expired",
  accept_offer: "Offer has been accepted by user",
};

const OfferDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [details, setDetails] = useState();
  const [answers, setAnswers] = useState([]);
  const [currentEditItem, setCurrentEditItem] = useState({
    index: -1,
    value: "",
    id: -1,
  });

  const [isAddClinicOpen, setAddClinicOpen] = useState(false);
  const [isAddAccommodationOpen, setAddAccommodationOpen] = useState(false);
  const [isFlightClass, setIsAddFlightClass] = useState(false);
  const [additionalServices, setAdditionalServices] = useState([]);

  const isDisabled =
    details?.cost &&
    details?.accommodation?.id &&
    details?.airline?.id &&
    details?.clinic?.id &&
    details?.flightClass?.id &&
    details?.flightTicket?.id &&
    details?.practitioner?.id;

  const getDetails = async () => {
    const response = await axios.get(`/offer/${id}`);

    if (response.additionalServices.length !== 0)
      setAdditionalServices(response.additionalServices);
    setDetails(response);

    if (response?.answers?.length > 0) {
      const answerGroup = response.answers.reduce((acc, item) => {
        let group = acc.find((g) => g.title === item.question.questionType);

        if (!group) {
          group = { title: item.question.questionType, list: [] };
          acc.push(group);
        }
        group.list.push(item);

        return acc;
      }, []);
      console.log(answerGroup);
      setAnswers(answerGroup);
    }
    toast("Loading details", {
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      className: "bg-success text-white",
    });
    setAddAccommodationOpen(false);
    setAddClinicOpen(false);
  };

  useEffect(() => {
    getDetails();
  }, []);

  const sendOffer = async () => {
    if (details?.status === "accept_offer") {
      toast("This offer was already accepted by user", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
      return;
    }
    try {
      await axios.patch(`/offer/offer-status/${id}`);
      toast("Offer is sent to user", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
      setDetails((prevDetails) => ({
        ...prevDetails,
        status: "waiting_user_response",
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const updatePrice = async () => {
    try {
      await axios.patch(`/offer/${id}`, {
        cost: details.cost,
      });
      toast("Price is updated", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const addNewField = () => {
    setAdditionalServices((prevAdditionalServices) => [
      ...prevAdditionalServices,
      { title: "", description: "" },
    ]);
  };

  const deleteField = async (i) => {
    const service = additionalServices[i];
    if (service.id) {
      await axios.delete(`/offer-additional-service/${service.id}`);
    }

    setAdditionalServices((prevAdditionalServices) =>
      [...prevAdditionalServices].filter((_, index) => index !== i)
    );
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const services = [...additionalServices];
    services[index][name] = value;
    setAdditionalServices(services);
  };

  const createAdditionalService = async () => {
    try {
      await axios.post(`/offer-additional-service/${id}`, {
        additionalServices,
      });

      toast("New additional services are created", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateOffer = async () => {
    try {
      await axios.patch(`/offer/${id}`, {
        airline: details?.airline?.id,
        cost: details?.cost,
        accommodation: details?.accommodation?.id,
        clinic: details?.clinic?.id,
        flightClass: details?.flightClass?.id,
        flightTicket: details?.flightTicket?.id,
        practitioner: details?.practitioner?.id,
      });
      toast("Offer is updated", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const rejectOffer = async () => {
    try {
      await axios.patch(`/offer/reject/${id}`, {
        status: "reject",
      });
      toast("Offer is rejected", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateAnswers = async () => {
    try {
      await axios.patch(`/answer/${currentEditItem.id}`, {
        content: currentEditItem.value,
      });
      toast("Answer is updated", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
      setDetails((prevDetails) => ({
        ...prevDetails,
        answers: prevDetails.answers.map((item) =>
          item.id !== currentEditItem.id
            ? item
            : { ...item, content: currentEditItem.value }
        ),
      }));
      setCurrentEditItem({ id: -1, index: -1, value: "" });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAnswer = async (id) => {
    try {
      await axios.delete(`/answer/${id}`);
      toast("Answer is deleted", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
      setDetails((prevDetails) => ({
        ...prevDetails,
        answers: prevDetails.answers.filter((item) => item.id !== id),
      }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <ToastContainer />
          <AddClinicModal
            id={id}
            isOpen={isAddClinicOpen}
            onClose={() => setAddClinicOpen(false)}
            onUpdate={getDetails}
          />
          <AddAccommodationModal
            id={id}
            isOpen={isAddAccommodationOpen}
            onClose={() => setAddAccommodationOpen(false)}
            onUpdate={getDetails}
          />
          <AddFlightModal
            id={id}
            isOpen={isFlightClass}
            onClose={() => setIsAddFlightClass(false)}
            onUpdate={getDetails}
          />
          {details ? (
            <Row>
              <BreadCrumb title="Offer Detail" pageTitle="Offer Detail" />
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardHeader>
                      <h4 className="card-title mb-0">
                        Offer Number: <b>{details.offerNumber}</b>
                      </h4>
                    </CardHeader>

                    <CardBody>
                      <ListGroup>
                        <ListGroupItem>
                          <i className="ri-file-copy-2-line align-middle me-2"></i>
                          <b>Id:</b>&nbsp;{details.id}
                        </ListGroupItem>
                        <ListGroupItem>
                          <i className="ri-file-copy-2-line align-middle me-2"></i>
                          <b>Service:</b>&nbsp;
                          <span>{details.service.locales.name}</span>
                        </ListGroupItem>
                        <ListGroupItem>
                          <i className="ri-file-copy-2-line align-middle me-2"></i>
                          <b>Subservice:</b>&nbsp;
                          <span>
                            {details.service.subServices
                              ? details.service.subServices.translations.name
                              : "Empty"}
                          </span>
                        </ListGroupItem>
                        <ListGroupItem>
                          <i className="ri-file-copy-2-line align-middle me-2"></i>
                          <b>Description:</b>&nbsp;
                          {details.additionalHealthNote}
                        </ListGroupItem>
                        <ListGroupItem>
                          <i className="ri-file-copy-2-line align-middle me-2"></i>
                          <b>Accommodation Additional Note:</b>&nbsp;
                          {details.additionalAccommodationNote}
                        </ListGroupItem>
                        <ListGroupItem>
                          <i className="ri-file-copy-2-line align-middle me-2"></i>
                          <b>Service Additional Note:</b>&nbsp;
                          {details.additionalServiceNote}
                        </ListGroupItem>
                        <ListGroupItem>
                          <i className="ri-file-copy-2-line align-middle me-2"></i>
                          <b>Travel Additional Note:</b>&nbsp;
                          {details.additionalTravelNote}
                        </ListGroupItem>
                        <ListGroupItem>
                          <i className="ri-file-copy-2-line align-middle me-2"></i>
                          <b>Passengers:</b>&nbsp;
                          <span>{details.adultCount} Adult</span>{" "}
                          <span>{details.childrenCount} Child</span>{" "}
                          <span>{details.babyCount} Baby</span>
                        </ListGroupItem>
                        <ListGroupItem>
                          <i className="ri-file-copy-2-line align-middle me-2"></i>
                          <b>Status:</b>&nbsp;{" "}
                          <Button>{offerStatus[details.status]}</Button>
                        </ListGroupItem>
                        <ListGroupItem>
                          <i className="ri-file-copy-2-line align-middle me-2"></i>
                          <b>Departure City:</b>&nbsp;{" "}
                          <span>{details?.cityFrom ?? ""}</span>
                        </ListGroupItem>
                        <ListGroupItem>
                          <i className="ri-file-copy-2-line align-middle me-2"></i>
                          <b>Arrival City:</b>&nbsp;{" "}
                          <span>{details?.cityTo ?? ""}</span>
                        </ListGroupItem>
                        <ListGroupItem>
                          <i className="ri-file-copy-2-line align-middle me-2"></i>
                          <b>Return Date:</b>&nbsp;
                          <span>
                            {new Intl.DateTimeFormat("tr", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                            }).format(new Date(details.returnDate))}
                          </span>
                        </ListGroupItem>
                        <ListGroupItem>
                          <i className="ri-file-copy-2-line align-middle me-2"></i>
                          <b>Departure Date:</b>&nbsp;
                          <span>
                            {new Intl.DateTimeFormat("tr", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                            }).format(new Date(details.departureDate))}
                          </span>
                        </ListGroupItem>
                        {/* <ListGroupItem>
                          <i className="ri-file-copy-2-line align-middle me-2"></i>
                          <b>Organization:</b>&nbsp;
                          <span>
                            {details?.organization?.name || "Not setted yet"}
                          </span>
                        </ListGroupItem> */}
                        <ListGroupItem>
                          <i className="ri-file-copy-2-line align-middle me-2"></i>
                          <b>Clinic and Practioner:</b>&nbsp;
                          <span>
                            {details?.clinic?.fullName || "Not setted yet"}
                            {" - "}
                            {details?.practitioner?.name || "Not setted yet"}
                          </span>
                          <p>
                            <Button onClick={() => setAddClinicOpen(true)}>
                              Update
                            </Button>
                          </p>
                        </ListGroupItem>
                        <ListGroupItem>
                          <i className="ri-file-copy-2-line align-middle me-2"></i>
                          <b>Accommodation:</b>&nbsp;
                          <span>
                            {details?.accommodation?.locales?.name ||
                              "Not setted yet"}
                          </span>
                          <p>
                            <Button
                              onClick={() => setAddAccommodationOpen(true)}
                            >
                              Update
                            </Button>
                          </p>
                        </ListGroupItem>
                        <ListGroupItem>
                          <i className="ri-file-copy-2-line align-middle me-2"></i>
                          <b>Flight Class:</b>&nbsp;
                          <span>
                            {details?.flightClass?.name || "Not setted yet"}
                          </span>
                          <p>
                            <i className="ri-file-copy-2-line align-middle me-2"></i>
                            <b>Airline:</b>&nbsp;
                            <span>
                              {details?.airline?.name || "Not setted yet"}
                            </span>
                          </p>
                          <p>
                            <Button onClick={() => setIsAddFlightClass(true)}>
                              Update
                            </Button>
                          </p>
                        </ListGroupItem>
                        <ListGroupItem>
                          <i className="ri-file-copy-2-line align-middle me-2"></i>
                          <b>Price ({details?.currency}):</b>&nbsp;
                          <span>
                            <Input
                              type="number"
                              className="form-control"
                              id="cost"
                              placeholder="Enter price"
                              value={details?.cost ?? ""}
                              onChange={(e) =>
                                setDetails((prevDetails) => ({
                                  ...prevDetails,
                                  cost: e.target.valueAsNumber,
                                }))
                              }
                            />
                          </span>
                          <Button className="mt-2" onClick={updatePrice}>
                            Update
                          </Button>
                        </ListGroupItem>
                        <ListGroupItem>
                          <i className="ri-file-copy-2-line align-middle me-2"></i>
                          <b>Created At:</b>&nbsp;
                          <span>
                            {new Intl.DateTimeFormat("tr", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                            }).format(new Date(details.createdAt))}
                          </span>
                        </ListGroupItem>
                        <ListGroupItem>
                          <i className="ri-file-copy-2-line align-middle me-2"></i>
                          <b>Updated At:</b>&nbsp;
                          <span>
                            {new Intl.DateTimeFormat("tr", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                            }).format(new Date(details.updatedAt))}
                          </span>
                        </ListGroupItem>
                        <ListGroupItem>
                          <i className="ri-file-copy-2-line align-middle me-2"></i>
                          <b>Addional Services :</b>&nbsp;
                          {additionalServices.length >= 1 &&
                            additionalServices.map((item, i) => (
                              <div key={i} className="d-flex gap-2 mt-2">
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="title"
                                  name="title"
                                  placeholder="Enter Title"
                                  value={additionalServices[i].title}
                                  onChange={(e) => handleChange(e, i)}
                                />
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="description"
                                  name="description"
                                  placeholder="Enter Description"
                                  value={additionalServices[i].description}
                                  onChange={(e) => handleChange(e, i)}
                                />

                                <Button
                                  className="btn btn-danger"
                                  onClick={() => deleteField(i)}
                                >
                                  Delete
                                </Button>
                              </div>
                            ))}
                          {additionalServices.length > 0 && (
                            <Button
                              style={{ marginRight: 10 }}
                              className="mt-2 "
                              onClick={createAdditionalService}
                            >
                              Update
                            </Button>
                          )}
                          <Button className="mt-2" onClick={addNewField}>
                            Add Additional Service Field
                          </Button>
                        </ListGroupItem>
                        <ListGroupItem>
                          {details?.fileCollection &&
                            details?.fileCollection?.files?.length > 0 && (
                              <div className="d-flex gap-2 flex-wrap mt-3">
                                <i className="ri-file-copy-2-line align-middle me-2"></i>
                                <b>Media :</b>&nbsp;
                                {details?.fileCollection?.files.map(
                                  (file, i) => (
                                    <div key={file.id}>
                                      <div
                                        style={{
                                          width: 100,
                                          height: 100,
                                          position: "relative",
                                        }}
                                      >
                                        <img
                                          src={file.path}
                                          key={file.path}
                                          style={{
                                            width: 100,
                                            height: 100,
                                            borderRadius: 6,
                                          }}
                                        />
                                        {/* <Button
                    onClick={() => deleteFile(file.id)}
                    style={{
                      position: "absolute",
                      top: -10,
                      right: -10,
                      zIndex: 10,
                      borderRadius: 100,
                      backgroundColor: "gray",
                      border: "1px solid gray",
                      width: 45,
                      height: 45,
                    }}
                  >
                    <i className="bx bx-trash"></i>
                  </Button> */}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                        </ListGroupItem>
                        <ListGroupItem>
                          <i className="ri-file-copy-2-line align-middle me-2"></i>
                          <b>Questions and Answers:</b>&nbsp;
                          {answers?.length > 0 &&
                            answers.map((listItem, i) => (
                              <div key={i}>
                                <p className="mt-2" key={i}>
                                  <h3
                                    style={{
                                      fontWeight: 600,
                                      fontSize: 22,
                                      color: "#000",
                                      textTransform: "capitalize",
                                      borderBottom: "3px solid #333",
                                    }}
                                  >
                                    {listItem.title}
                                  </h3>
                                  {listItem.list.map((item) => (
                                    <div className="mt-3">
                                      <b>{item.question.questionI18n.name}</b> -{" "}
                                      {currentEditItem.index === i ? (
                                        <span>
                                          <input
                                            placeholder="Content"
                                            value={currentEditItem.value}
                                            onChange={(e) =>
                                              setCurrentEditItem(
                                                (prevEditItem) => ({
                                                  ...prevEditItem,
                                                  value: e.target.value,
                                                })
                                              )
                                            }
                                          />

                                          <Button
                                            color="white"
                                            style={{
                                              marginTop: "-15px",
                                              marginLeft: "-10px",
                                            }}
                                            onClick={() =>
                                              setCurrentEditItem({
                                                index: -1,
                                                id: -1,
                                                value: "",
                                              })
                                            }
                                          >
                                            <i className="ri-close-fill fs-20 align-bottom"></i>
                                          </Button>
                                          <Button onClick={updateAnswers}>
                                            Update
                                          </Button>
                                        </span>
                                      ) : (
                                        <>
                                          {item.content}
                                          <Button
                                            color="white"
                                            style={{ marginTop: "-10px" }}
                                            onClick={() =>
                                              setCurrentEditItem({
                                                index: i,
                                                id: item.id,
                                                value: item.content,
                                              })
                                            }
                                          >
                                            <i className="ri-pencil-fill align-bottom"></i>
                                          </Button>
                                          <Button
                                            className="btn btn-danger"
                                            color="white"
                                            onClick={() =>
                                              deleteAnswer(item.id)
                                            }
                                          >
                                            Delete
                                          </Button>
                                        </>
                                      )}
                                      <br />
                                    </div>
                                  ))}
                                </p>
                              </div>
                            ))}
                        </ListGroupItem>
                        <ListGroupItem>
                          <div className="d-flex gap-2">
                            <Button
                              className="btn btn-success"
                              disabled={!isDisabled}
                              onClick={sendOffer}
                            >
                              Send Offer
                            </Button>
                            <Button
                              className="btn btn-info"
                              disabled={!isDisabled}
                              onClick={updateOffer}
                            >
                              Update
                            </Button>
                            <Button
                              className="btn btn-danger"
                              onClick={rejectOffer}
                            >
                              Reject Offer
                            </Button>
                          </div>
                        </ListGroupItem>
                      </ListGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Row>
          ) : (
            <Spinner />
          )}
        </Container>
      </div>
    </>
  );
};

export default OfferDetail;
