import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";

import { useInsertForm } from "../../Components/Hooks/UseInsertForm";
import axios from "axios";

const AddSection = () => {
  document.title = "Add Section | Visit Turkey";

  const [categories, setCategories] = useState([]);

  const getCategories = async () => {
    try {
      const response = await axios.get("/service/category");
      if (response.length > 0) {
        const categoryList = response.map((category) => ({
          label: category.title,
          value: category.id,
        }));
        setCategories(categoryList);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const { renderForm } = useInsertForm(
    [
      {
        label: "Category",
        name: "category",
        required: true,
        type: "selectbox",
        multiple: true,
        options: categories,
      },
      {
        label: "image",
        name: "image",
        placeholder: "enter date",
        required: false,
        type: "file",
        multiple: true,
        multiuploadcolumn: "section",
        uploadtype: "section",
      },
    ],
    [
      {
        label: "Title",
        name: "title",
        placeholder: "enter title",
        required: true,
        type: "text",
      },
    ],
    { postUrl: "/service/sections" }
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Add Section" pageTitle="Add Section" />
          <Row>
            <Col>
              <Card>
                <CardBody className="pt-2">
                  <h5 className="card-title mb-3  mb-md-0 flex-grow-1">
                    Add Section
                  </h5>
                  {renderForm()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddSection;
