import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row, Nav, NavItem, NavLink } from "reactstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import { UseUpdateSingleFile } from "../../Components/Hooks/UseUpdateSingleFile";
import useSubserviceLanguageForm from "../../Components/Hooks/useSubserviceLanguageForm";
import { UseUpdateForm } from "../../Components/Hooks/UseUpdateForm";

const EditSubservice = () => {
  document.title = "Alt Hizmet Düzenle - VisitTurkey";
  const { id, service } = useParams();

  const [language, setLanguage] = useState("tr");
  const [options, setOptions] = useState([]);

  const { renderRedirectButton } = UseUpdateSingleFile(undefined, {
    pageColumn: "subService",
    relationId: id,
  });

  const getServices = async () => {
    const response = await axios.get(`/service/services/all`);
    setOptions(
      response.map((a) => {
        return {
          value: a.service.id.toString(),
          label: a.name,
        };
      })
    );
  };

  useEffect(() => {
    getServices();
  }, []);

  const { renderForm: renderCommonForm } = UseUpdateForm(
    `/service/service/sub/detail/${id}`,
    `/service/service/sub/detail/${id}`,
    [options],
    {
      fields: [],
    }
  );

  const {
    renderForm: renderLanguageForm,
    switchLanguageTab,
    setFormData,
  } = useSubserviceLanguageForm(
    `/service/service/sub/detail/${id}/${language}`,
    language
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>{renderRedirectButton()}</Row>
          <Row>
            <Col>{renderCommonForm()}</Col>
          </Row>
          <Col style={{ marginBlock: "2rem" }}>
            <Alert color="info">Lütfen güncelleme için bir dil seçin2</Alert>
            <Nav tabs>
              {["tr", "ar", "en"].map((lang) => (
                <NavItem key={lang}>
                  <NavLink
                    className={language === lang ? "active fw-bold" : ""}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setFormData((prevData) => ({ ...prevData }));
                      setLanguage(lang);
                      switchLanguageTab(lang);
                    }}
                  >
                    {lang === "tr"
                      ? "Türkçe"
                      : lang === "ar"
                      ? "Arapça"
                      : "İngilizce"}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
          <Col>
            {renderLanguageForm([
              {
                field: "id",
                readOnly: "readonly",
                type: "input",
                label: "Id",
                required: "required",
              },
              {
                field: "name",
                type: "text",
                label: "Adı",
                required: "required",
              },
              {
                field: "slug",
                type: "text",
                label: "Slug",
                required: "required",
              },
            ])}
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditSubservice;
