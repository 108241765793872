import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Input,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import classnames from "classnames";
import axios from "axios";
import { toast } from "react-toastify";

const UploadOrSelectModal = ({ isOpen, toggle, onSelectFile, offerId }) => {
  const [activeTab, setActiveTab] = useState("upload");
  const [fileList, setFileList] = useState([]);
  const [selectedFileId, setSelectedFileId] = useState(null);

  // Dosya yükleme için state
  const [uploadFile, setUploadFile] = useState(null);

  // Modal açıldığında ve "select" sekmesine geçildiğinde PDF dosyalarını çek
  useEffect(() => {
    if (isOpen && activeTab === "select") {
      fetchExistingFiles();
    }
    // eslint-disable-next-line
  }, [isOpen, activeTab]);

  // Mevcut PDF dosyalarını çekmek için örnek istek
  const fetchExistingFiles = async () => {
    try {
      // Örneğin /files?mimeType=application/pdf endpoint'i ile filtrelenmiş dosyalar
      const { data } = await axios.get("/files?mimeType=application/pdf");
      setFileList(data);
    } catch (error) {
      toast.error("Dosyalar yüklenirken hata oluştu");
      console.log(error);
    }
  };

  // Dosyayı yükleme + mail gönderme
  const handleUpload = async () => {
    if (!uploadFile) {
      toast.warn("Lütfen bir dosya seçin");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", uploadFile);
      formData.append("offerId", offerId);

      // Tek adımda yükleme ve mail /offer/upload-and-mail endpoint’i
      const { data } = await axios.post("/offer/upload-and-mail", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      toast.success("Dosya ve mail işlemi başarılı!");
      console.log("Yükleme yanıtı:", data);

      // Dosyayı parent bileşene bildirmek isterseniz (opsiyonel):
      // if (onSelectFile) {
      //   onSelectFile({ ... });
      // }

      // Modal’ı kapatmak isterseniz:
      toggle();
    } catch (err) {
      toast.error("Dosya/mail yüklemesi başarısız!");
      console.error(err);
    }
  };

  // Mevcut yüklenmiş bir dosya seçme
  const handleSelect = () => {
    if (!selectedFileId) {
      toast.warn("Lütfen bir dosya seçin");
      return;
    }
    const chosenFile = fileList.find((f) => f.id === selectedFileId);
    if (onSelectFile) {
      onSelectFile(chosenFile);
    }
    toggle();
  };

  // Sekme değiştirme
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>PDF Ekle veya Seç</ModalHeader>
      <ModalBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "upload" })}
              onClick={() => toggleTab("upload")}
            >
              Dosya Yükle
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "select" })}
              onClick={() => toggleTab("select")}
            >
              Mevcut Dosyayı Seç
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab} className="mt-3">
          {/* DOSYA YÜKLE SEKME */}
          <TabPane tabId="upload">
            <div>
              <Label for="file">PDF Dosyası Seç</Label>
              <Input
                type="file"
                name="file"
                id="file"
                accept="application/pdf"
                onChange={(e) => {
                  console.log("SEÇİLEN DOSYA:", e.target.files[0]);
                  setUploadFile(e.target.files[0]);
                }}
              />
              <Button color="primary" className="mt-3" onClick={handleUpload}>
                Yükle ve Mail Gönder
              </Button>
            </div>
          </TabPane>

          {/* MEVCUT DOSYA SEÇME SEKME */}
          <TabPane tabId="select">
            <p>Mevcut yüklenmiş PDF’ler:</p>
            <ListGroup>
              {fileList.map((file) => (
                <ListGroupItem
                  key={file.id}
                  active={file.id === selectedFileId}
                  onClick={() => setSelectedFileId(file.id)}
                  style={{ cursor: "pointer" }}
                >
                  {file.name ?? file.path}
                </ListGroupItem>
              ))}
            </ListGroup>
            <Button color="primary" className="mt-3" onClick={handleSelect}>
              Seç
            </Button>
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
};

export default UploadOrSelectModal;
