import { useState, useEffect, useRef } from "react";
import axios from "axios";
import SearchIcon from "./icons/search";
import { Button } from "reactstrap";

export const usePagination = (uri, opt) => {
  const { fields, preventInitialRequest } = opt;
  const LIMIT = 10; // Her sayfada gösterilecek kayıt sayısı

  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState();
  const [keyword, setKeyword] = useState("");
  const timeoutRef = useRef();
  const isInitilized = useRef(false);

  const flatObj = (obj) => {
    let flatten = {};
    function flat(obj, val = "") {
      Object.keys(obj).forEach((key) => {
        if (
          !obj[key] ||
          typeof obj[key] !== "object" ||
          Array.isArray(obj[key])
        ) {
          flatten[val + "" + key] = obj[key];
        } else {
          flat(obj[key], val + key + ".");
        }
      });
    }
    flat(obj);
    return flatten;
  };

  const next = () => {
    setPage((cur) => cur + 1);
  };

  const prev = () => {
    setPage((cur) => cur - 1);
  };

  const sendRequest = async () => {
    try {
      setLoading(true);
      // Burada backend sayfalama yerine tüm verileri getiriyorsanız page ve take parametreleri opsiyonel olabilir.
      // Eğer backend tüm veriyi tek seferde döndürüyorsa, page & take göndermeye gerek yok.
      // Yine de şimdilik mevcut hali kalsın.
      let request = `${
        uri.includes("?")
          ? `${uri}&page=${page}&take=${LIMIT}&keyword=${keyword}&language=tr`
          : `${uri}?page=${page}&take=${LIMIT}&keyword=${keyword}&language=tr`
      }`;
      if (opt.params) {
        request += "&" + opt.params;
      }
      const response = await axios.get(request);
      setData(response); // Sunucunuz doğrudan array döndürüyorsa böyle kullanmaya devam edin
    } catch (e) {
      alert(e.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  // data mevcutsa currentData ile sadece o sayfanın kayıtlarını alıyoruz.
  const currentData = data ? data.slice((page - 1) * LIMIT, page * LIMIT) : [];

  const emptyResult = () => {
    if (!currentData || currentData.length === 0) {
      return (
        <div className="noresult">
          <div className="text-center">
            <SearchIcon />
            <h5 className="mt-2">Üzgünüz! Sonuç Bulunamadı</h5>
            <p className="text-muted mb-0">
              Aradığınız kriterlere uygun sonuç bulamadık.
            </p>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderFields = () => {
    return fields.map((field, index) => (
      <th key={index} data-sort={field} className="text-capitalize">
        {field.label}
      </th>
    ));
  };

  const renderData = (cb) => {
    if (!currentData || currentData.length === 0) {
      return null;
    }
    return currentData
      .map((x) => flatObj(x))
      .map((item) => {
        return (
          <tr key={item.id}>
            {fields.map((field, fieldIndex) => {
              let value = field.column ? item[field.column] : null;

              // Tarih formatlama
              if (
                (field.column === "createdAt" ||
                  field.column === "updatedAt") &&
                value
              ) {
                const date = new Date(value);
                value = date.toLocaleString("tr-TR", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                });
              }

              return (
                <td key={fieldIndex}>
                  {field.column
                    ? value
                    : cb({ id: field.id, field, data: item })}
                </td>
              );
            })}
          </tr>
        );
      });
  };

  const renderButtons = () => {
    return (
      <div className="d-flex justify-content-end">
        <div className="pagination-wrap hstack gap-2">
          <Button
            outline
            onClick={prev}
            disabled={page === 1}
            className={`page-item pagination-prev ${
              page === 1 ? "disabled" : ""
            }`}
          >
            Önceki
          </Button>
          {data && page * LIMIT < data.length && (
            <Button
              outline
              onClick={next}
              disabled={!data || data.length <= 0}
              className={`page-item pagination-next ${
                data.length <= 0 ? "disabled" : ""
              }`}
            >
              Sonraki
            </Button>
          )}
        </div>
      </div>
    );
  };

  const renderSearch = () => {
    return (
      <div className="search-box ms-2" style={{ width: "100%" }}>
        <input
          type="text"
          onChange={(e) => {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
              setPage(1);
              setKeyword(e.target.value);
            }, 500);
          }}
          className="form-control search"
          placeholder="Ara..."
        />
        <i className="ri-search-line search-icon"></i>
      </div>
    );
  };

  useEffect(() => {
    if (!isInitilized.current && preventInitialRequest) {
      isInitilized.current = true;
      return;
    }
    sendRequest();
  }, [page, keyword]);

  return {
    data,
    page,
    isLoading,
    sendRequest,
    renderSearch,
    renderButtons,
    renderFields,
    renderData,
    next,
    prev,
    emptyResult,
    // currentData dilimlenen veriyi de istenirse dışarı aktarabiliriz.
    // currentData,
  };
};
