import React, { useState } from "react";
import { Alert, Button, Col, Container, Row } from "reactstrap";
import { UseUpdateForm } from "../../Components/Hooks/UseUpdateForm";
import { useParams } from "react-router-dom";
import { UseUpdateSingleFile } from "../../Components/Hooks/UseUpdateSingleFile";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const EditArticle = () => {
  document.title = "Edit Article - VisitTurkey";
  const { id } = useParams();
  const navigate = useNavigate();

  const [language, setLanguage] = useState("tr");
  const { renderRedirectButton } = UseUpdateSingleFile(undefined, {
    pageColumn: "blog",
    relationId: id,
  });

  const { renderForm: renderCommonForm } = UseUpdateForm(
    `/blog/detail/${id}`,
    `/blog/detail/${id}`,
    [],
    {
      fields: [
        {
          field: "isStory",
          label: "Is this a story?",
          type: "checkbox",
        },
        {
          field: "type",
          label: "Type",
          required: "required",
          type: "select",
          options: [
            { value: "historical", label: "Historical" },
            { value: "touristical", label: "Touristical" },
            { value: "city", label: "City" },
            { value: "blog", label: "Blog" },
          ],
        },
      ],
    }
  );

  const { renderForm: renderLanguageForm } = UseUpdateForm(
    `/blog/detail/locale/${id}/${language}`,
    `/blog/detail/locale/${id}/${language}`,
    [language],
    {
      fields: [
        {
          field: "id",
          readOnly: "readonly",
          type: "input",
          label: "Id",
          required: "required",
        },
        {
          field: "title",
          type: "input",
          label: "Title",
          required: "required",
        },
        {
          field: "subtitle",
          type: "input",
          label: "Subtitle",
          required: "required",
        },
        {
          field: "firstParagraph",
          type: "input",
          label: "First Paragraph",
          required: "required",
        },
        {
          field: "body",
          richText: true,
          label: "Body",
          required: "required",
        },
        {
          field: "slug",
          type: "input",
          label: "Slug",
          required: "required",
        },
      ],
    }
  );

  const deleteBlog = async () => {
    try {
      if (confirm("You are about to delete this blog")) {
        await axios.delete(`/blog/${id}`);
        toast(`Blog has been deleted successfully`, {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          className: "bg-success text-white",
        });
        navigate("/discover/blog");
      }
    } catch (error) {
      toast(`We've encountered an error`, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-warning text-white",
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Col>{renderRedirectButton()}</Col>
          <Col>
            {" "}
            <Button
              onClick={deleteBlog}
              type="submit"
              color="danger"
              className="mt-4 btn w-100 d-block"
            >
              Remove blog
            </Button>
          </Col>
          <Row>
            <Col>{renderCommonForm()}</Col>
          </Row>
          <Col style={{ marginBlock: "2rem" }}>
            <Alert color="info">Please choose a locale for update</Alert>
            <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
              <Button
                onClick={() => setLanguage("tr")}
                className={language === "tr" ? "fw-bold" : ""}
              >
                Turkish
              </Button>
              <Button
                onClick={() => setLanguage("ar")}
                dir="rtl"
                className={language === "ar" ? "fw-bold" : ""}
              >
                Arabic
              </Button>
              <Button
                onClick={() => setLanguage("en")}
                className={language === "en" ? "fw-bold" : ""}
              >
                English
              </Button>
            </div>
          </Col>
          <Col>{renderLanguageForm()}</Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditArticle;
