import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { toast } from "react-toastify";

const AddFlightModal = ({ isOpen, onClose, onUpdate, id }) => {
  const [flightClasses, setFlightClasses] = useState([]);
  const [airlines, setAirlines] = useState([]);
  const [currentFlightClass, setCurrentFlightClass] = useState({});
  const [currentAirline, setCurrentAirline] = useState({});
  const [isLoading, setLoading] = useState(false);

  const getFlightClass = async () => {
    const [flightClass, airline] = await Promise.all([
      axios.get(`/flight-class/en`),
      axios.get(`/airline`),
    ]);
    setFlightClasses(flightClass);
    setAirlines(airline);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await axios.patch(`/offer/${id}`, {
        flightClass: { id: currentFlightClass.flightClass.id },
        airline: { id: currentAirline.id },
      });
      onUpdate();
    } catch (e) {
      console.log(e);
      toast(e?.response?.data?.message || e, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-warning text-white",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getFlightClass();
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={onClose} id="exampleModal">
      <ModalHeader>
        Add Flight Class
        <Button
          type="button"
          className="btn-close"
          onClick={onClose}
          aria-label="Close"
        ></Button>
      </ModalHeader>
      <ModalBody>
        {currentFlightClass && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              marginBlock: "1rem",
            }}
          >
            <h4>Flight Class:</h4>
            <ListGroup>
              <ListGroupItem>
                <i className="ri-file-copy-2-line align-middle me-2"></i>
                <b>Flight Class:</b>&nbsp; {currentFlightClass.name}
              </ListGroupItem>
            </ListGroup>
          </div>
        )}
        {currentAirline && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              marginBlock: "1rem",
            }}
          >
            <h4>Airline:</h4>
            <ListGroup>
              <ListGroupItem>
                <i className="ri-file-copy-2-line align-middle me-2"></i>
                <b>Airline:</b>&nbsp; {currentAirline.name}
              </ListGroupItem>
            </ListGroup>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="name" className="col-form-label">
              Choose Flight Class:
            </label>
            <select
              required
              className="form-control"
              onChange={(e) => {
                if (!e.target.value) return;
                setCurrentFlightClass(
                  flightClasses.find((x) => x.id === Number(e.target.value))
                );
              }}
            >
              <option value={""}>Choose</option>
              {flightClasses.map((flightClass, id) => {
                return (
                  <option key={id} value={flightClass.id}>
                    {flightClass.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="name" className="col-form-label">
              Choose Airline:
            </label>
            <select
              required
              className="form-control"
              onChange={(e) => {
                if (!e.target.value) return;
                setCurrentAirline(
                  airlines.find((x) => x.id === Number(e.target.value))
                );
              }}
            >
              <option value={""}>Choose</option>
              {airlines.map((airline, id) => {
                return (
                  <option key={id} value={airline.id}>
                    {airline.name}
                  </option>
                );
              })}
            </select>
          </div>
          <ModalFooter>
            <Button
              color="light"
              type="button"
              className="btn btn-light"
              onClick={onClose}
            >
              Back
            </Button>
            <Button
              disabled={!currentFlightClass || isLoading || !currentAirline}
              color="primary"
              type="submit"
            >
              Update
            </Button>
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default AddFlightModal;
