import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row, Spinner } from "reactstrap";
import CountUp from "react-countup";

//Import Icons
import FeatherIcon from "feather-icons-react";
import axios from "axios";

const CountWidget = () => {
  const [dashboard, setDashboard] = useState();

  const getCounts = async () => {
    try {
      const token = localStorage.getItem("authUser");

      const response = await axios.get("/dashboard", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDashboard(response);
      console.log("Dashboard: ", response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCounts();
  }, []);

  return (
    <Row>
      {!dashboard ? (
        <Spinner color="info" type="grow">
          Yükleniyor...
        </Spinner>
      ) : (
        <>
          <Col md={6}>
            <Card className="card-animate">
              <CardBody>
                <div className="d-flex justify-content-between">
                  <div>
                    <p className="fw-medium text-muted mb-0">Kullanıcılar</p>
                    <h2 className="mt-4 ff-secondary fw-semibold">
                      <span className="counter-value">
                        <CountUp
                          start={0}
                          end={dashboard.users}
                          decimals={0}
                          duration={4}
                        />
                      </span>
                    </h2>
                  </div>
                  <div>
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-info-subtle rounded-circle fs-2">
                        <FeatherIcon icon="users" className="text-info" />
                      </span>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="card-animate">
              <CardBody>
                <div className="d-flex justify-content-between">
                  <div>
                    <p className="fw-medium text-muted mb-0">Başvurular</p>
                    <h2 className="mt-4 ff-secondary fw-semibold">
                      <span className="counter-value" data-target="3">
                        <CountUp
                          start={0}
                          end={dashboard.offers}
                          duration={4}
                        />
                      </span>
                    </h2>
                    <p className="mb-0 text-muted">
                      Bu ay: <b>{dashboard.offersThisMonth}</b>
                    </p>
                  </div>
                  <div>
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-info-subtle rounded-circle fs-2">
                        <FeatherIcon icon="clock" className="text-info" />
                      </span>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="card-animate">
              <CardBody>
                <div className="d-flex justify-content-between">
                  <div>
                    <p className="fw-medium text-muted mb-0">Hekimler</p>
                    <h2 className="mt-4 ff-secondary fw-semibold">
                      <span className="counter-value" data-target="3">
                        <CountUp
                          start={0}
                          end={dashboard.practioners}
                          duration={4}
                        />
                      </span>
                    </h2>
                  </div>
                  <div>
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-info-subtle rounded-circle fs-2">
                        <FeatherIcon icon="clock" className="text-info" />
                      </span>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="card-animate">
              <CardBody>
                <div className="d-flex justify-content-between">
                  <div>
                    <p className="fw-medium text-muted mb-0">
                      Aktif Başvurular
                    </p>
                    <h2 className="mt-4 ff-secondary fw-semibold">
                      <span className="counter-value" data-target="3">
                        <CountUp
                          start={0}
                          end={dashboard.activeOffers}
                          duration={4}
                        />
                      </span>
                    </h2>
                  </div>
                  <div>
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-info-subtle rounded-circle fs-2">
                        <FeatherIcon icon="clock" className="text-info" />
                      </span>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </>
      )}
    </Row>
  );
};

export default CountWidget;
