import React from "react";
import { Container } from "reactstrap";
import CountWidget from "../../Components/Common/CountWidget";
import BreadCrumb from "../../Components/Common/BreadCrumb";

const Analytics = () => {
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Analytics" pageTitle="Dashboards" />
          <CountWidget />
        </Container>
      </div>
    </>
  );
};

export default Analytics;
