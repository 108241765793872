import React, { useState } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Col,
  Row,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { usePagination } from "../../Components/Hooks/UsePagination";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";

const AllApplications = () => {
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const navigate = useNavigate();

  // 1.Tab: Tüm başvurular (accept_offer olmayanlar)
  const {
    data: dataAll,
    emptyResult: emptyResultAll,
    renderFields: renderFieldsAll,
    renderData: renderDataAll,
    renderButtons: renderButtonsAll,
    renderSearch: renderSearchAll,
  } = usePagination("/offer", {
    fields: [
      { label: "ID", column: "id", id: "id" },
      { label: "Teklif Numarası", column: "offerNumber", id: "offerNumber" },
      { label: "Müşteri Adı", column: "user.firstName", id: "user.firstName" },
      { label: "Müşteri Soyadı", column: "user.lastName", id: "user.lastName" },
      {
        label: "Hizmet",
        id: "service.locales.name",
        column: "service.locales.name",
      },
      {
        label: "Alt Hizmet",
        id: "service.subServices.translations.name",
        column: "service.subServices.translations.name",
      },
      { label: "Durum", column: "status", id: "status" },
      { label: "Oluşturma Tarihi", column: "createdAt", id: "createdAt" },
      { label: "İşlem", id: "action" },
    ],
    // accept_offer olmayanları göstermek için örnek param:
    // Aşağıda örnek: status=accept_offer:not şeklinde de kullanılabilir.
    params: "status=accept_offer:not",
  });

  // 2.Tab: Onaylananlar (status=accept_offer olanlar)
  const {
    data: dataAccepted,
    emptyResult: emptyResultAccepted,
    renderFields: renderFieldsAccepted,
    renderData: renderDataAccepted,
    renderButtons: renderButtonsAccepted,
    renderSearch: renderSearchAccepted,
  } = usePagination("/offer", {
    fields: [
      { label: "ID", column: "id", id: "id" },
      { label: "Teklif Numarası", column: "offerNumber", id: "offerNumber" },
      { label: "Müşteri Adı", column: "user.firstName", id: "user.firstName" },
      { label: "Müşteri Soyadı", column: "user.lastName", id: "user.lastName" },
      {
        label: "Hizmet",
        id: "service.locales.name",
        column: "service.locales.name",
      },
      {
        label: "Alt Hizmet",
        id: "service.subServices.translations.name",
        column: "service.subServices.translations.name",
      },
      { label: "Durum", column: "status", id: "status" },
      { label: "Oluşturma Tarihi", column: "createdAt", id: "createdAt" },
      { label: "İşlem", id: "action" },
    ],
    params: "status=accept_offer",
  });

  const renderActionButtons = (d) => (
    <>
      {d.id === "action" && (
        <div className="d-flex" style={{ gap: "1rem" }}>
          <Button
            color="info"
            outline
            onClick={() => {
              navigate(`/offer-detail/${d.data["id"]}`);
            }}
          >
            Detayları Gör
          </Button>
        </div>
      )}
    </>
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <ToastContainer />
          <Row>
            <BreadCrumb title="Tüm Başvurular" pageTitle="Panel" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title mb-0">Tüm Başvurular</h4>
                  </CardHeader>
                  <CardBody>
                    {/* Tab Menüsü */}
                    <Nav pills className="mb-3">
                      <NavItem style={{ cursor: "pointer" }}>
                        <NavLink
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => {
                            toggleTab("1");
                          }}
                        >
                          Başvurular
                        </NavLink>
                      </NavItem>
                      <NavItem style={{ cursor: "pointer" }}>
                        <NavLink
                          className={classnames({ active: activeTab === "2" })}
                          onClick={() => {
                            toggleTab("2");
                          }}
                        >
                          Onaylananlar
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={activeTab}>
                      {/* 1.Tab İçeriği */}
                      <TabPane tabId="1">
                        <div className="listjs-table" id="customerList">
                          <Row className="g-4 mb-3">
                            <Col className="col-sm">
                              <div className="d-flex justify-content-sm-end">
                                {renderSearchAll()}
                              </div>
                            </Col>
                          </Row>
                          <div className="table-responsive table-card mt-3 mb-1">
                            <table
                              className="table align-middle table-nowrap"
                              id="organizationTable"
                            >
                              <thead className="table-light">
                                <tr>{renderFieldsAll()}</tr>
                              </thead>
                              <tbody className="list form-check-all">
                                {renderDataAll(renderActionButtons)}
                              </tbody>
                            </table>
                            {(!dataAll || !dataAll?.length) && emptyResultAll()}
                          </div>
                          {renderButtonsAll()}
                        </div>
                      </TabPane>

                      {/* 2.Tab İçeriği */}
                      <TabPane tabId="2">
                        <div className="listjs-table" id="customerList2">
                          <Row className="g-4 mb-3">
                            <Col className="col-sm">
                              <div className="d-flex justify-content-sm-end">
                                {renderSearchAccepted()}
                              </div>
                            </Col>
                          </Row>
                          <div className="table-responsive table-card mt-3 mb-1">
                            <table
                              className="table align-middle table-nowrap"
                              id="organizationTable2"
                            >
                              <thead className="table-light">
                                <tr>{renderFieldsAccepted()}</tr>
                              </thead>
                              <tbody className="list form-check-all">
                                {renderDataAccepted(renderActionButtons)}
                              </tbody>
                            </table>
                            {(!dataAccepted || !dataAccepted?.length) &&
                              emptyResultAccepted()}
                          </div>
                          {renderButtonsAccepted()}
                        </div>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AllApplications;
