import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Input, Label, Button } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const useSubserviceLanguageForm = (baseUri, initialLanguage) => {
  const [formData, setFormData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [language, setLanguage] = useState(initialLanguage || "tr");
  const [draftData, setDraftData] = useState({});
  const [drafts, setDrafts] = useState({});
  const formRef = useRef(null);
  const richTextWarn = useRef({});

  // Dil seçimini ve form verisini yönetmek için gerekli useEffect
  useEffect(() => {
    if (baseUri) {
      if (drafts[language]) {
        // Eğer bu dil için daha önce taslak varsa, onu yükle
        setDraftData(drafts[language]);
      } else {
        fetchData(baseUri);
      }
    }
  }, [baseUri, language]);

  // API'den veriyi almak için
  const fetchData = async (uri) => {
    try {
      setLoading(true);
      const response = await axios.get(uri);
      if (response) {
        setFormData(response);
        setDraftData(response);
        setDrafts((prevDrafts) => ({
          ...prevDrafts,
          [language]: response,
        }));
      }
    } catch (error) {
      console.error("Veri çekme hatası:", error);
      toast(
        error?.response?.data?.message || "Veri yüklenirken bir hata oluştu.",
        {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          className: "bg-warning text-white",
        }
      );
    } finally {
      setLoading(false);
    }
  };

  // Form alanlarındaki değişiklikleri yönetmek için
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDraftData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setDrafts((prevDrafts) => ({
      ...prevDrafts,
      [language]: {
        ...prevDrafts[language],
        [name]: value,
      },
    }));
  };

  // CKEditor alanlarındaki değişiklikleri yönetmek için
  const handleRichTextChange = (field, editorData) => {
    setDraftData((prevData) => ({
      ...prevData,
      [field]: editorData,
    }));
    setDrafts((prevDrafts) => ({
      ...prevDrafts,
      [language]: {
        ...prevDrafts[language],
        [field]: editorData,
      },
    }));

    // Rich text alanları için validasyon
    setTimeout(() => {
      if (richTextWarn.current[field] !== undefined) {
        richTextWarn.current[field] =
          editorData.length > 0 ? "" : `${field} alanı zorunludur.`;
      }
    }, 100);
  };

  // Form gönderimi
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationMessages = [];
    Object.values(richTextWarn.current).forEach((msg) => {
      if (msg.length) validationMessages.push(msg);
    });

    if (validationMessages.length) {
      return alert(validationMessages.join("\n"));
    }

    try {
      setLoading(true);
      await axios.patch(`${baseUri}`, draftData);
      toast("Kayıt başarıyla güncellendi.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
      setFormData(draftData);
      setDrafts((prevDrafts) => ({
        ...prevDrafts,
        [language]: draftData,
      }));
    } catch (error) {
      console.error("Form gönderme hatası:", error);
      toast(
        error?.response?.data?.message ||
          "Güncelleme sırasında bir hata oluştu.",
        {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          className: "bg-warning text-white",
        }
      );
    } finally {
      setLoading(false);
    }
  };

  // Tab yapısını yönetmek için dil değişimini set etme fonksiyonu
  const switchLanguageTab = (newLanguage) => {
    setLanguage(newLanguage);
  };

  // Formu render etmek için kullanılacak bileşen
  const renderForm = (fields) => {
    if (!fields || fields.length === 0) {
      return <p>Form yükleniyor...</p>;
    }
    return (
      <form onSubmit={handleSubmit} ref={formRef}>
        <ToastContainer />
        {fields.map((field, index) => {
          return (
            <div key={index} style={{ marginBlock: "1rem" }}>
              <Label
                htmlFor={`form-item-${field.field}`}
                className="form-label"
              >
                {field.label}
              </Label>

              {field.type !== "select" && !field.richText && (
                <Input
                  name={field.field}
                  value={draftData[field.field] || ""}
                  onChange={handleChange}
                  id={`form-item-${field.field}`}
                  className="form-control"
                  {...field}
                />
              )}

              {field.richText && (
                <>
                  <Input
                    name={field.field}
                    hidden
                    value={draftData[field.field] || ""}
                  />
                  <CKEditor
                    editor={ClassicEditor}
                    data={draftData[field.field] || ""}
                    onChange={(_, editor) => {
                      handleRichTextChange(field.field, editor.getData());
                    }}
                  />
                </>
              )}
            </div>
          );
        })}

        {fields.length > 0 && (
          <Button
            disabled={isLoading}
            type="submit"
            className="mt-4 btn btn-primary w-100 d-block"
          >
            {!isLoading ? "Kaydet" : "Lütfen Bekleyin"}
          </Button>
        )}
      </form>
    );
  };

  return {
    formData,
    setFormData,
    draftData,
    setDraftData,
    isLoading,
    renderForm,
    switchLanguageTab,
    language,
  };
};

export default useSubserviceLanguageForm;
