import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { Input, Label, Button } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useArticleLanguageForm = (baseUri, language = null, onLanguageChange) => {
  const [formData, setFormData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [draftData, setDraftData] = useState({});
  const [drafts, setDrafts] = useState({});
  const formRef = useRef(null);
  const richTextWarn = useRef({});

  useEffect(() => {
    if (baseUri) {
      if (language && drafts[language]) {
        setDraftData(drafts[language]);
      } else {
        fetchData(baseUri);
      }
    }
  }, [baseUri, language]);

  const fetchData = async (uri) => {
    try {
      setLoading(true);
      const response = await axios.get(uri);
      if (response) {
        setFormData(response);
        setDraftData(response);
        if (language) {
          setDrafts((prevDrafts) => ({
            ...prevDrafts,
            [language]: response,
          }));
        }
      }
    } catch (error) {
      toast(
        error?.response?.data?.message || "Veri yüklenirken bir hata oluştu.",
        {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          className: "bg-warning text-white",
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;
    setDraftData((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));
    if (language) {
      setDrafts((prevDrafts) => ({
        ...prevDrafts,
        [language]: {
          ...prevDrafts[language],
          [name]: fieldValue,
        },
      }));
    }
  };

  const handleRichTextChange = (field, editorData) => {
    setDraftData((prevData) => ({
      ...prevData,
      [field]: editorData,
    }));
    if (language) {
      setDrafts((prevDrafts) => ({
        ...prevDrafts,
        [language]: {
          ...prevDrafts[language],
          [field]: editorData,
        },
      }));
    }

    setTimeout(() => {
      if (richTextWarn.current[field] !== undefined) {
        richTextWarn.current[field] =
          editorData.length > 0 ? "" : `${field} alanı zorunludur.`;
      }
    }, 100);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationMessages = [];
    Object.values(richTextWarn.current).forEach((msg) => {
      if (msg.length) validationMessages.push(msg);
    });

    if (validationMessages.length) {
      return alert(validationMessages.join("\n"));
    }

    try {
      setLoading(true);
      const payload = {
        id: draftData.id,
        title: draftData.title,
        subtitle: draftData.subtitle,
        firstParagraph: draftData.firstParagraph,
        body: draftData.body,
        slug: draftData.slug,
        isStory: draftData.isStory,
        type: draftData.type,
      };
      await axios.patch(`${baseUri}`, payload);
      toast("Kayıt başarıyla güncellendi.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
      setFormData(draftData);
      if (language) {
        setDrafts((prevDrafts) => ({
          ...prevDrafts,
          [language]: draftData,
        }));
      }
    } catch (error) {
      toast(
        error?.response?.data?.message ||
          "Güncelleme sırasında bir hata oluştu.",
        {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          className: "bg-warning text-white",
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const switchLanguageTab = (newLanguage) => {
    if (onLanguageChange) {
      onLanguageChange(newLanguage);
    }
  };

  const renderForm = (fields) => {
    if (!fields || fields.length === 0) {
      return <p>Form yükleniyor...</p>;
    }
    return (
      <form onSubmit={handleSubmit} ref={formRef}>
        <ToastContainer />
        {fields.map((field, index) => {
          return (
            <div key={index} style={{ marginBlock: "1rem" }}>
              <Label
                htmlFor={`form-item-${field.field}`}
                className="form-label"
              >
                {field.label}
              </Label>

              {field.type !== "select" && !field.richText && (
                <Input
                  name={field.field}
                  type={field.type}
                  checked={
                    field.type === "checkbox"
                      ? draftData[field.field] || false
                      : undefined
                  }
                  value={
                    field.type !== "checkbox"
                      ? draftData[field.field] || ""
                      : undefined
                  }
                  onChange={handleChange}
                  id={`form-item-${field.field}`}
                  className="form-control"
                  {...field}
                />
              )}

              {field.richText && (
                <>
                  <Input
                    name={field.field}
                    hidden
                    value={draftData[field.field] || ""}
                  />
                  <CKEditor
                    editor={ClassicEditor}
                    data={draftData[field.field] || ""}
                    onChange={(_, editor) => {
                      handleRichTextChange(field.field, editor.getData());
                    }}
                  />
                </>
              )}

              {field.type === "select" && field.options && (
                <Input
                  type="select"
                  name={field.field}
                  value={draftData[field.field] || ""}
                  onChange={handleChange}
                  id={`form-item-${field.field}`}
                  className="form-control"
                >
                  {field.options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Input>
              )}
            </div>
          );
        })}

        {fields.length > 0 && (
          <Button
            disabled={isLoading}
            type="submit"
            className="mt-4 btn btn-primary w-100 d-block"
          >
            {!isLoading ? "Kaydet" : "Lütfen Bekleyin"}
          </Button>
        )}
      </form>
    );
  };

  return {
    formData,
    setFormData,
    draftData,
    setDraftData,
    isLoading,
    renderForm,
    switchLanguageTab,
    language,
  };
};

export default useArticleLanguageForm;
