import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import {
  Button,
  ModalFooter,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useParams, useNavigate } from "react-router-dom";

const AddNewFile = () => {
  document.title = "Add New File - Visit Turkey";

  const [isLoading, setLoading] = useState(false);
  const [columnType, setColumnType] = useState("");
  const { column, id } = useParams();
  const collectionId = useRef(null);

  const formRef = useRef();
  const files = useRef();

  const { navigate } = useNavigate();

  useEffect(() => {
    setColumnType(column);
  }, [column]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    formData.append("column", column);
    formData.append("type", column);
    formData.append("relationId", id);
    if (collectionId.current) {
      formData.append("collectionId", collectionId.current);
    }
    files.current.forEach((file) => {
      formData.append("files", file);
    });
    console.log(Object.fromEntries(formData));
    try {
      setLoading(true);

      await axios.post("/files/upload/multiple", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      window.history.back();
      toast("Added new images", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
      onClose();
      onCreate();
    } catch (e) {
      toast(e?.response?.data?.message || e, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-warning text-white",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    files.current = Array.from(e.target.files);
  };

  const getCollectionId = async () => {
    const response = await axios.get(`/files/collection-id/${column}/${id}`);
    collectionId.current = response?.id;
  };

  useEffect(() => {
    getCollectionId();
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Add New File" pageTitle="Add New File" />
          <Row>
            <Col>
              <Card>
                <CardBody className="pt-2">
                  <h5 className="card-title mb-3  mb-md-0 flex-grow-1">
                    Add File
                  </h5>
                  <form onSubmit={handleSubmit} ref={formRef}>
                    <div className="mb-3">
                      <label htmlFor="name" className="col-form-label">
                        File Type
                      </label>
                      <select
                        disabled={!!column}
                        value={columnType}
                        onChange={(e) => setColumnType(e.target.value)}
                        required
                        name="type"
                        className="form-control"
                      >
                        <option value={""}>Choose</option>
                        <option value={"user"}>user</option>
                        <option value={"subService"}>sub service</option>
                        <option value={"health-category"}>
                          health category
                        </option>
                        <option value={"service"}>service</option>
                        <option value={"blog"}>blog</option>
                        <option value={"accommodation"}>accommodation</option>
                        <option value={"clinic"}>clinic</option>
                        <option value={"practitioner"}>practitioner</option>
                        <option value={"category"}>category</option>
                        <option value={"section"}>section</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="name" className="col-form-label">
                        Files
                      </label>
                      <input
                        className="form-control"
                        onChange={handleChange}
                        multiple
                        type="file"
                        accept="image/*"
                        required
                      />
                    </div>

                    <ModalFooter className="gap-2">
                      <Button
                        color="light"
                        type="button"
                        className="btn btn-light"
                        onClick={() => navigate(-1)}
                      >
                        Back
                      </Button>
                      <Button
                        disabled={isLoading}
                        color="primary"
                        type="submit"
                      >
                        {isLoading ? "Please wait" : "Add"}
                      </Button>
                    </ModalFooter>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddNewFile;
