import React, { useState } from "react";
import { Alert, Col, Container, Row, Nav, NavItem, NavLink } from "reactstrap";
import { UseUpdateForm } from "../../Components/Hooks/UseUpdateForm";
import { useParams } from "react-router-dom";
import { UseUpdateSingleFile } from "../../Components/Hooks/UseUpdateSingleFile";
import useCategoryLanguageForm from "../../Components/Hooks/useCategoryLanguageForm";

const EditCategory = () => {
  document.title = "Kategori Düzenle - VisitTurkey";
  const { id } = useParams();

  const [language, setLanguage] = useState("tr");

  const { renderRedirectButton } = UseUpdateSingleFile(undefined, {
    pageColumn: "category",
    relationId: id,
  });

  const { renderForm: renderCommonForm } = UseUpdateForm(
    `/service/category/detail/${id}`,
    `/service/category/detail/${id}`,
    [],
    {
      fields: [
        {
          field: "isHomePageItem",
          type: "checkbox",
          label: "Ana Sayfa Ögesi mi?",
        },
        {
          field: "favorite",
          type: "checkbox",
          label: "Favori mi?",
        },
      ],
    }
  );

  const {
    renderForm: renderLanguageForm,
    switchLanguageTab,
    setFormData,
  } = useCategoryLanguageForm(
    `/service/category/detail/${id}/${language}`,
    language
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>{renderRedirectButton()}</Row>
          <Row>
            <Col>{renderCommonForm()}</Col>
          </Row>
          <Col style={{ marginBlock: "2rem" }}>
            <Alert color="info">Lütfen güncelleme için bir dil seçin</Alert>
            <Nav tabs>
              {["tr", "ar", "en"].map((lang) => (
                <NavItem key={lang}>
                  <NavLink
                    className={language === lang ? "active fw-bold" : ""}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setFormData((prevData) => ({ ...prevData }));
                      setLanguage(lang);
                      switchLanguageTab(lang);
                    }}
                  >
                    {lang === "tr"
                      ? "Türkçe"
                      : lang === "ar"
                      ? "Arapça"
                      : "İngilizce"}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
          <Col>
            {renderLanguageForm([
              {
                field: "id",
                readOnly: "readonly",
                type: "input",
                label: "Id",
                required: "required",
              },
              {
                field: "title",
                type: "text",
                label: "Başlık",
                required: "required",
              },
              {
                field: "slug",
                type: "text",
                label: "Slug",
                required: "required",
              },
            ])}
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditCategory;
