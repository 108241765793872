import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";

import { useInsertForm } from "../../Components/Hooks/UseInsertForm";

const AddHotelCategory = () => {
  document.title = "Add Hotel Category | Visit Turkey";

  const { renderForm } = useInsertForm(
    [
      {
        label: "image",
        name: "image",
        required: false,
        type: "file",
        propname: "iconSrc",
        accept: "image/*",
      },
    ],
    [
      {
        label: "Soru Adı",
        name: "name",
        placeholder: "enter soru adı",
        required: true,
        type: "text",
      },
    ],
    { postUrl: "/accommodation/categories" }
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Add Hotel Category"
            pageTitle="Add Hotel Category"
          />
          <Row>
            <Col>
              <Card>
                <CardBody className="pt-2">
                  <h5 className="card-title mb-3  mb-md-0 flex-grow-1">
                    Add Hotel
                  </h5>
                  {renderForm()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddHotelCategory;
