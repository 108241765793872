import React, { useState } from "react";
import { Alert, Button, Col, Container, Row } from "reactstrap";
import { UseUpdateForm } from "../../Components/Hooks/UseUpdateForm";
import { useParams } from "react-router-dom";
import { UseUpdateSingleFile } from "../../Components/Hooks/UseUpdateSingleFile";

const EditHotelCategory = () => {
  document.title = "Edit Category - VisitTurkey";
  const { id } = useParams();

  const [language, setLanguage] = useState("tr");

  const { renderFileInput } = UseUpdateSingleFile(
    `/accommodation/category/detail/${id}`,
    {
      label: "Icon File",
      column: "iconSrc",
    }
  );
  const { renderForm: renderCommonForm } = UseUpdateForm(
    `/accommodation/category/detail/${id}`,
    `/accommodation/category/detail/${id}`,
    [],
    {
      fields: [
        {
          field: "isToggleQuestion",
          type: "checkbox",
          label: "Is Toggle Question",
        },
        {
          field: "questionType",
          label: "Type",
          required: "required",
          type: "select",
          options: [
            { value: "health", label: "Health" },
            { value: "services", label: "Services" },
            { value: "accommodation", label: "Accommodation" },
            { value: "travel", label: "Travel" },
          ],
        },
      ],
    }
  );

  const { renderForm: renderLanguageForm } = UseUpdateForm(
    `/accommodation/category/detail/${id}/${language}`,
    `/accommodation/category/detail/${id}/${language}`,
    [language],
    {
      fields: [
        {
          field: "id",
          readOnly: "readonly",
          type: "input",
          label: "Id",
          required: "required",
        },
        {
          field: "name",
          type: "text",
          label: "Name",
          required: "required",
        },
      ],
    }
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>{renderCommonForm()}</Col>
          </Row>
          <Col style={{ marginBlock: "2rem" }}>
            <Alert color="info">Please choose a locale for update</Alert>
            <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
              <Button
                onClick={() => setLanguage("tr")}
                className={language === "tr" ? "fw-bold" : ""}
              >
                Turkish
              </Button>
              <Button
                onClick={() => setLanguage("ar")}
                dir="rtl"
                className={language === "ar" ? "fw-bold" : ""}
              >
                Arabic
              </Button>
              <Button
                onClick={() => setLanguage("en")}
                className={language === "en" ? "fw-bold" : ""}
              >
                English
              </Button>
            </div>
          </Col>
          <Col>{renderLanguageForm()}</Col>
          <hr />
          <Col>{renderFileInput()}</Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditHotelCategory;
