import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Nav,
  NavItem,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  Label,
  Button,
  NavLink,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import DeleteModal from "../../Components/Common/DeleteModal";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useSelector, useDispatch } from "react-redux";
import { getApplicationList } from "../../slices/thunks";
import TableContainer from "../../Components/Common/TableContainer";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  AppId,
  Designation,
  Contact,
  Status,
  Type,
} from "../../Components/Dashboard/ApplicationCol";
import MultiUser from "../../assets/images/users/multi-user.jpg";
import { createSelector } from "reselect";
// Import Images
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";

const tags = [
  { label: "Exiting", value: "Exiting" },
  { label: "Lead", value: "Lead" },
  { label: "Long-term", value: "Long-term" },
  { label: "Partner", value: "Partner" },
];

const AddUser = () => {
  document.title = "Add User | Visit Turkey";

  const dispatch = useDispatch();
  const [country, setCountry] = useState({ value: "", error: "" });
  const [city, setCity] = useState({ value: "", error: "" });
  const [note, setNote] = useState("");

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      kvkk: "",
      postalCode: "",
      neighborhood: "",
      street: "",
      apartment: "",
      no: "",
      password: "",
      language: "",
      note: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter First Name"),
      lastName: Yup.string().required("Please Enter Last Name"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      kvkk: Yup.string().required("Please Enter KVKK"),
      postalCode: Yup.string().required("Please Enter Postal Code"),
      neighborhood: Yup.string().required("Please Enter Neighborhood"),
      street: Yup.string().required("Please Enter Street"),
      apartment: Yup.string().required("Please Enter Apartment"),
      no: Yup.string().required("Please Enter No"),
      password: Yup.string().required("Please Enter Password"),
      language: Yup.string().required("Please Enter Language"),
    }),
    onSubmit: (values) => {
      const value = {
        country: country.value,
        city: city.value,
      };

      const error = {};
      const keys = Object.keys(value);

      for (let item of keys) {
        if (!value[item]) {
          const msg = `Please Enter ${item}`;
          error[item] = msg;
          if (item === "country") setCountry({ value: "", error: msg });
          if (item === "city") setCity({ value: "", error: msg });
        }
      }

      const hasError = Object.values(error).map((item) => item).length > 0;
      if (hasError) {
        return;
      }

      const newUser = {
        firstName: values["firstName"],
        lastName: values["lastName"],
        email: values["email"],
        phone: values["phone"],
        kvkk: values["kvkk"],
        postalCode: values["postalCode"],
        neighborhood: values["neighborhood"],
        street: values["street"],
        apartment: values["apartment"],
        no: values["no"],
        password: values["password"],
        language: values["language"],
        country: country.value,
        city: city.value,
        note,
      };
      console.log(newUser);
      validation.resetForm();
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <DeleteModal
          show={deleteModal}
          onCloseClick={() => setDeleteModal(false)}
        />
        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        /> */}
        <Container fluid>
          <BreadCrumb title="Users" pageTitle="All Users" />
          <Row>
            <Col>
              <Card>
                {/* <CardBody className="border border-dashed border-end-0 border-start-0">
                  <Form>
                    <Row className="g-3">
                      <Col xxl={5} sm={6}>
                        <div className="search-box">
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder="Search for application ID, company, designation status or something..."
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>
                      <Col xxl={2} sm={6}>
                        <div>
                          <Flatpickr
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select date"
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "multiple",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xxl={2} sm={4}>
                        <div>
                          <Select
                            options={option}
                            name="choices-single-default"
                            id="idStatus"
                          ></Select>
                        </div>
                      </Col>
                      <Col xxl={2} sm={4}>
                        <div>
                          <Select
                            options={option1}
                            name="choices-single-default"
                            id="idType"
                          ></Select>
                        </div>
                      </Col>
                      <Col xxl={1} sm={4}>
                        <div>
                          <Button
                            type="button"
                            color="primary"
                            className="btn w-100"
                            // onclick=""
                          >
                            {" "}
                            <i className="ri-equalizer-fill me-1 align-bottom"></i>
                            Filters
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody> */}
                <CardBody className="pt-2">
                  <h5 className="card-title mb-3  mb-md-0 flex-grow-1">
                    Add User
                  </h5>

                  <Form
                    className="tablelist-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Input type="hidden" id="id-field" />
                    <Row className="g-3">
                      <Col lg={12}>
                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute  bottom-0 end-0">
                              <Label
                                htmlFor="customer-image-input"
                                className="mb-0"
                              >
                                <div className="avatar-xs cursor-pointer">
                                  <div className="avatar-title bg-light border rounded-circle text-muted">
                                    <i className="ri-image-fill"></i>
                                  </div>
                                </div>
                              </Label>
                              <Input
                                className="form-control d-none"
                                id="customer-image-input"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.img || ""}
                                invalid={
                                  validation.touched.img &&
                                  validation.errors.img
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            <div className="avatar-lg p-1">
                              <div className="avatar-title bg-light rounded-circle">
                                <img
                                  src={dummyImg}
                                  alt="dummyImg"
                                  id="customer-img"
                                  className="avatar-md rounded-circle object-fit-cover"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          <Label
                            htmlFor="firstName-field"
                            className="form-label"
                          >
                            First Name
                          </Label>
                          <Input
                            name="firstName"
                            id="firstName-field"
                            className="form-control"
                            placeholder="Enter First Name"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstName || ""}
                            invalid={
                              validation.touched.firstName &&
                              validation.errors.firstName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.firstName &&
                          validation.errors.firstName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.firstName}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div>
                          <Label
                            htmlFor="lastName-field"
                            className="form-label"
                          >
                            Last Name
                          </Label>
                          <Input
                            name="lastName"
                            id="lastName-field"
                            className="form-control"
                            placeholder="Enter Last Name"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastName || ""}
                            invalid={
                              validation.touched.lastName &&
                              validation.errors.lastName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.lastName &&
                          validation.errors.lastName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.lastName}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div>
                          <Label htmlFor="email-field" className="form-label">
                            E-mail Address
                          </Label>
                          <Input
                            name="email"
                            id="email-field"
                            className="form-control"
                            placeholder="Enter Email"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div>
                          <Label htmlFor="phone-field" className="form-label">
                            Phone
                          </Label>
                          <Input
                            name="phone"
                            id="phone-field"
                            className="form-control"
                            placeholder="Enter Phone"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone || ""}
                            invalid={
                              validation.touched.phone &&
                              validation.errors.phone
                                ? true
                                : false
                            }
                          />
                          {validation.touched.phone &&
                          validation.errors.phone ? (
                            <FormFeedback type="invalid">
                              {validation.errors.phone}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div>
                          <Label htmlFor="kvkk-field" className="form-label">
                            KVKK
                          </Label>
                          <Input
                            name="kvkk"
                            id="kvkk-field"
                            className="form-control"
                            placeholder="Enter KVKK"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.kvkk || ""}
                            invalid={
                              validation.touched.kvkk && validation.errors.kvkk
                                ? true
                                : false
                            }
                          />
                          {validation.touched.kvkk && validation.errors.kvkk ? (
                            <FormFeedback type="invalid">
                              {validation.errors.kvkk}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div>
                          <Label
                            htmlFor="company_name-field"
                            className="form-label"
                          >
                            Address
                          </Label>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div>
                          <Label htmlFor="country-field" className="form-label">
                            Country
                          </Label>
                          <Select
                            onChange={(e) =>
                              setCountry({ value: e.value, error: "" })
                            }
                            className="mb-0"
                            options={tags}
                            id="country-field"
                          ></Select>

                          {country.error ? (
                            <p className="text-danger">{country.error}</p>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div>
                          <Label htmlFor="city-field" className="form-label">
                            City
                          </Label>
                          <Select
                            onChange={(e) =>
                              setCity({ value: e.value, error: "" })
                            }
                            className="mb-0"
                            options={tags}
                            id="city-field"
                          ></Select>

                          {city.error ? (
                            <p className="text-danger">{city.error}</p>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div>
                          <Label
                            htmlFor="postalCode-field"
                            className="form-label"
                          >
                            Postal Code
                          </Label>
                          <Input
                            name="postalCode"
                            id="postalCode-field"
                            className="form-control"
                            placeholder="Enter Postal Code"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.postalCode || ""}
                            invalid={
                              validation.touched.postalCode &&
                              validation.errors.postalCode
                                ? true
                                : false
                            }
                          />

                          {validation.touched.postalCode &&
                          validation.errors.postalCode ? (
                            <FormFeedback type="invalid">
                              {validation.errors.postalCode}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div>
                          <Label
                            htmlFor="neighborhood-field"
                            className="form-label"
                          >
                            Neighbourhood
                          </Label>
                          <Input
                            name="neighborhood"
                            id="neighborhood-field"
                            className="form-control"
                            placeholder="Enter Neighborhood"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.neighborhood || ""}
                            invalid={
                              validation.touched.neighborhood &&
                              validation.errors.neighborhood
                                ? true
                                : false
                            }
                          />

                          {validation.touched.neighborhood &&
                          validation.errors.neighborhood ? (
                            <FormFeedback type="invalid">
                              {validation.errors.neighborhood}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div>
                          <Label htmlFor="street-field" className="form-label">
                            Street
                          </Label>
                          <Input
                            name="street"
                            id="street-field"
                            className="form-control"
                            placeholder="Enter Street"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.street || ""}
                            invalid={
                              validation.touched.street &&
                              validation.errors.street
                                ? true
                                : false
                            }
                          />

                          {validation.touched.street &&
                          validation.errors.street ? (
                            <FormFeedback type="invalid">
                              {validation.errors.street}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div>
                          <Label
                            htmlFor="apartment-field"
                            className="form-label"
                          >
                            Apartment
                          </Label>
                          <Input
                            name="apartment"
                            id="apartment-field"
                            className="form-control"
                            placeholder="Enter Apartment"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.apartment || ""}
                            invalid={
                              validation.touched.apartment &&
                              validation.errors.apartment
                                ? true
                                : false
                            }
                          />

                          {validation.touched.apartment &&
                          validation.errors.apartment ? (
                            <FormFeedback type="invalid">
                              {validation.errors.apartment}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div>
                          <Label htmlFor="no-field" className="form-label">
                            No
                          </Label>
                          <Input
                            name="no"
                            id="no-field"
                            className="form-control"
                            placeholder="Enter No"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.no || ""}
                            invalid={
                              validation.touched.no && validation.errors.no
                                ? true
                                : false
                            }
                          />

                          {validation.touched.no && validation.errors.no ? (
                            <FormFeedback type="invalid">
                              {validation.errors.no}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div>
                          <Label
                            htmlFor="password-field"
                            className="form-label"
                          >
                            Password
                          </Label>
                          <Input
                            name="password"
                            id="password-field"
                            className="form-control"
                            placeholder="Enter Password"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div>
                          <Label
                            htmlFor="language-field"
                            className="form-label"
                          >
                            Language
                          </Label>
                          <Input
                            name="language"
                            id="language-field"
                            className="form-control"
                            placeholder="Enter Language"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.language || ""}
                            invalid={
                              validation.touched.language &&
                              validation.errors.language
                                ? true
                                : false
                            }
                          />
                          {validation.touched.language &&
                          validation.errors.language ? (
                            <FormFeedback type="invalid">
                              {validation.errors.language}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <Label htmlFor="note-field" className="form-label">
                            Note
                          </Label>
                          <textarea
                            onClick={(e) => setNote(e.target.value)}
                            className="form-control"
                            id="note-textarea"
                            placeholder="Enter Address"
                            rows="2"
                          ></textarea>
                        </div>
                      </Col>
                    </Row>

                    <div className="hstack gap-2 mt-4 justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-success"
                        id="add-btn"
                      >
                        Add User
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddUser;
