import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

const Team = () => {
  document.title = "Team | Visit Turkey Dashboard";

  const [currentUserId, setCurrentUserId] = useState();
  const [createModal, setCreateModal] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [team, setTeam] = useState([]);
  const [keyword, setKeyword] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [name, setName] = useState("");

  const getEmployees = async () => {
    const response = await axios.get(`/admin?keyword=${keyword}`);
    setTeam(response);
  };

  const createNewUser = async (e) => {
    e.preventDefault();

    try {
      await axios.post("/admin", {
        email,
        password,
        role,
        name,
      });
      getEmployees();
      setCreateModal(false);
      toast("This record added", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
    } catch (e) {
      toast(e?.response?.data?.message || e, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-warning text-white",
      });
    }
  };

  const removeEmployee = async () => {
    if (!currentUserId) {
      return;
    }
    try {
      await axios.delete(`/admin/${currentUserId}`);
      toast("This record deleted", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
      getEmployees();
    } catch (e) {
      toast(e?.response?.data?.message || e, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-warning text-white",
      });
    }
  };

  useEffect(() => {
    getEmployees();
  }, [keyword]);

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <DeleteModal
        show={currentUserId !== undefined}
        onDeleteClick={() => {
          removeEmployee();
          setCurrentUserId(undefined);
        }}
        onCloseClick={() => setCurrentUserId(undefined)}
      />

      <Modal
        isOpen={createModal}
        toggle={() => {
          setCreateModal(!createModal);
        }}
        id="exampleModal"
      >
        <ModalHeader>
          Create New Employee
          <Button
            type="button"
            className="btn-close"
            onClick={() => {
              setCreateModal(false);
            }}
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={createNewUser}>
            <div className="mb-3">
              <label htmlFor="name" className="col-form-label">
                Name:
              </label>
              <Input
                type="text"
                className="form-control"
                required
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="col-form-label">
                Email:
              </label>
              <Input
                type="email"
                className="form-control"
                required
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="col-form-label">
                Password:
              </label>
              <Input
                type="password"
                className="form-control"
                required
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="col-form-label">
                Role:
              </label>
              <select
                className="form-select"
                required
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="">Choose</option>
                <option value="superadmin">Super admin</option>
                <option value="customer support">Customer Support</option>
              </select>
            </div>
            <ModalFooter>
              <Button
                color="light"
                className="btn btn-light"
                onClick={() => {
                  setCreateModal(false);
                }}
              >
                Back
              </Button>
              <Button color="primary" type="submit">
                Create
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>

      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Team" pageTitle="Users" />
          <Card>
            <CardBody>
              <Row className="g-2">
                <Col sm={4}>
                  <div className="search-box">
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Search for email"
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </Col>
                <Col className="col-sm-auto ms-auto">
                  <div className="list-grid-nav hstack gap-1">
                    <Button
                      color="success"
                      onClick={() => {
                        setCreateModal(true);
                      }}
                    >
                      <i className="ri-add-fill me-1 align-bottom"></i> Add
                      Members
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Row>
            <Col lg={12}>
              <div id="teamlist">
                <Row className="team-list grid-view-filter">
                  {team.map((item, key) => (
                    <Col key={key}>
                      <Card className="team-box">
                        <div className="team-cover">
                          <img
                            src={item.backgroundImg}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <CardBody className="p-4">
                          <Row className="align-items-center team-row">
                            <Col className="team-settings">
                              <Row>
                                <UncontrolledDropdown
                                  style={{ marginLeft: "auto" }}
                                  direction="start"
                                  className="col text-end"
                                >
                                  <DropdownToggle
                                    tag="a"
                                    id="dropdownMenuLink2"
                                    role="button"
                                  >
                                    <i className="ri-more-fill fs-17"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      className="dropdown-item remove-list"
                                      href="#removeMemberModal"
                                      onClick={() => setCurrentUserId(item.id)}
                                    >
                                      <i className="ri-delete-bin-5-line me-2 align-bottom text-muted"></i>
                                      Remove
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </Row>
                            </Col>
                            <Col lg={4} className="col text-capitalize">
                              <div className="team-profile-img">
                                <div className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">
                                  <div className="avatar-title text-uppercase border rounded-circle bg-light text-primary">
                                    {item.avatartext}
                                  </div>
                                </div>
                                <div className="team-content">
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      setIsOpen(!isOpen);
                                      setSideBar(item);
                                    }}
                                  >
                                    <h5 className="fs-16 mb-1">{item.name}</h5>
                                  </Link>
                                  <p className="text-muted mb-0">{item.role}</p>
                                </div>
                              </div>
                            </Col>
                            <Col lg={2} className="col">
                              <div className="text-end">
                                <Link
                                  to="/pages-profile"
                                  className="btn btn-light view-btn"
                                >
                                  View Profile
                                </Link>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
              <div
                className="py-4 mt-4 text-center"
                id="noresult"
                style={{ display: team?.length ? "none" : "block" }}
              >
                <lord-icon
                  src="https://cdn.lordicon.com/msoeawqm.json"
                  trigger="loop"
                  colors="primary:#405189,secondary:#0ab39c"
                  style={{ width: "72px", height: "72px" }}
                ></lord-icon>
                <h5 className="mt-4">Sorry! No Result Found</h5>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Team;
