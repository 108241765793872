import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";

import { useInsertForm } from "../../Components/Hooks/UseInsertForm";

const AddCategory = () => {
  document.title = "Add Category | Visit Turkey";

  const { renderForm } = useInsertForm(
    [
      {
        name: "isHomePageItem",
        type: "checkbox",
        label: "Is Home Page Item",
      },
      {
        name: "favorite",
        type: "checkbox",
        label: "Is Favorite",
      },

      {
        label: "image",
        name: "image",
        placeholder: "enter date",
        required: false,
        type: "file",
        multiple: true,
        multiuploadcolumn: "category",
        uploadtype: "category",
      },
    ],
    [
      {
        name: "title",
        type: "text",
        label: "Title",
        required: "required",
      },
      {
        name: "slug",
        type: "text",
        label: "Slug",
        required: "required",
      },
    ],
    { postUrl: "/service/category" }
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Add Category" pageTitle="Add Category" />
          <Row>
            <Col>
              <Card>
                <CardBody className="pt-2">
                  <h5 className="card-title mb-3  mb-md-0 flex-grow-1">
                    Add Category
                  </h5>
                  {renderForm()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddCategory;
