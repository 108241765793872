import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import { ToastContainer, toast } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const FileManager = () => {
  document.title = "File Manager | Visit Turkey";
  const [types, setTypes] = useState([]);
  const [folders, setFolder] = useState();
  const [files, setFiles] = useState();
  const [relationId, setRelationId] = useState();
  const navigate = useNavigate();
  const { type, uid } = useParams();
  const column = type
    ?.replace("sub-service", "subService")
    ?.replace("practioner", "practitioner");

  const getTypes = async () => {
    toast("Types loading", {
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      autoClose: 1000,
      className: "bg-primary text-white",
    });
    setFiles(undefined);
    // window.location.href = "#files";
    const response = await axios.get("/files/types");
    setTypes(response);
  };

  const getFolders = async (type) => {
    toast("Folders loading", {
      autoClose: 1000,
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      className: "bg-primary text-white",
    });
    setFiles(undefined);
    const response = await axios.get(`/files/collections/${type}`);
    setFolder(response);
  };

  const getFiles = async (id) => {
    toast("Files loading", {
      autoClose: 1000,
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      className: "bg-primary text-white",
    });
    window.location.href = "#files";

    const response = await axios.get(`/files/folder/${id}`);
    if (response?.length) {
      console.log(response);
      const id = response[0]["file_collection"][column]?.id;
      if (!id) {
        // alert(
        //   "ilişkili id bulunamadı! - file-collection sayfasındaki columnla relationlarlardan birisi eşleşmeli"
        // );
        toast("There is no file with that id", {
          autoClose: 2000,
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          className: "bg-primary text-white",
        });
      }
      setRelationId(id);
    }
    setFiles(response);
  };

  useEffect(() => {
    getTypes(types);
  }, []);

  useEffect(() => {
    if (!type) {
      return;
    }
    getFolders(type);
  }, [type]);

  useEffect(() => {
    if (!type || !uid) {
      return;
    }
    getFiles(uid);
  }, [type, uid]);

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <DeleteModal
        show={false}
        onDeleteClick={() => {}}
        onCloseClick={() => {}}
      />

      <div className="page-content">
        <Container fluid>
          <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
            <div className="file-manager-sidebar">
              <div className="p-3 d-flex flex-column h-100">
                <div className="mb-3">
                  <h5 className="mb-0 fw-semibold">Drive</h5>
                </div>
                <SimpleBar className="mt-3 mx-n4 px-4 file-menu-sidebar-scroll">
                  <ul className="list-unstyled file-manager-menu">
                    <li>
                      <a
                        data-bs-toggle="collapse"
                        href="#collapseExample"
                        role="button"
                        aria-expanded="true"
                        aria-controls="collapseExample"
                      >
                        <i className="ri-folder-2-line align-bottom me-2"></i>{" "}
                        <span className="file-list-link">File Types</span>
                      </a>
                      <div className="collapse show" id="collapseExample">
                        <ul className=" ">
                          {types.map((type, index) => {
                            return (
                              <li key={index}>
                                <Link to={`/files/${type.type}`}>
                                  <span className="text-capitalize">
                                    {type.type}
                                  </span>
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </li>
                  </ul>
                </SimpleBar>
              </div>
            </div>
            <div className="file-manager-content w-100 p-3 py-0">
              <div className="mx-n3 pt-4 px-4 file-manager-content-scroll overflow-x-hidden overflow-y-auto">
                <div id="folder-list" className="mb-2">
                  <Row className="justify-content-beetwen g-2 mb-4">
                    <Col>
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-2 d-block d-lg-none">
                          <button
                            type="button"
                            className="btn btn-soft-success btn-icon btn-sm fs-16 file-menu-btn"
                          >
                            <i className="ri-menu-2-fill align-bottom"></i>
                          </button>
                        </div>
                        <div className="flex-grow-1" id="folders">
                          <h5 className="fs-16 mb-0">Folders</h5>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row id="folderlist-data">
                    {folders ? (
                      <>
                        {folders.length ? (
                          folders.map((item, key) => (
                            <Col
                              xxl={3}
                              className="col-6 folder-card"
                              key={key}
                            >
                              <Card
                                className="bg-light shadow-none"
                                id={"folder-" + item.id}
                              >
                                <CardBody>
                                  <div className="d-flex mb-1">
                                    <UncontrolledDropdown>
                                      <DropdownToggle
                                        tag="button"
                                        className="btn btn-ghost-primary btn-icon btn-sm dropdown"
                                      >
                                        <i className="ri-more-2-fill fs-16 align-bottom" />
                                      </DropdownToggle>
                                      <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem
                                          onClick={() => {
                                            navigate(
                                              `/files/${type}/${item.id}`
                                            );
                                          }}
                                          className="view-item-btn"
                                        >
                                          Open
                                        </DropdownItem>
                                        <DropdownItem onClick={() => {}}>
                                          Delete
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>

                                  <div
                                    className="text-center cursor-pointer"
                                    onClick={() => {
                                      navigate(`/files/${type}/${item.id}`);
                                    }}
                                  >
                                    <div className="mb-2">
                                      <i className="ri-folder-2-fill align-bottom text-warning display-5"></i>
                                    </div>
                                    <h6 className="fs-15 folder-name">
                                      {item.name}
                                    </h6>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          ))
                        ) : (
                          <h3>This folder is empty</h3>
                        )}
                      </>
                    ) : (
                      <h3>Please choose a folder</h3>
                    )}
                  </Row>
                </div>
                <div>
                  <div className="d-flex align-items-center mb-3" id="files">
                    <h5 className="flex-grow-1 fs-16 mb-0" id="filetype-title">
                      Files
                    </h5>
                    {relationId && (
                      <div className="flex-shrink-0">
                        <button
                          className="btn btn-success createFile-modal"
                          onClick={() => {
                            navigate(`/files/add/${column}/${relationId}`);
                          }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          file to folder
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="table-responsive">
                    <table className="table align-middle table-nowrap mb-0">
                      <thead className="table-active">
                        <tr>
                          <th scope="col">Id</th>
                          <th scope="col">Name</th>
                          <th scope="col">File Type</th>
                          <th scope="col">Path</th>
                          <th scope="col">Created Date</th>
                          <th scope="col" className="text-center">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody id="file-list">
                        {files ? (
                          <>
                            {files.length ? (
                              files.map((item, key) => (
                                <tr key={key}>
                                  <td>{item.id}</td>
                                  <td>{item.name}</td>
                                  <td className="filelist-size">
                                    {item.mimeType}
                                  </td>
                                  <td className="filelist-path">
                                    <a href={item.path} target="_blank">
                                      <b>View</b>
                                    </a>
                                  </td>
                                  <td className="filelist-create">
                                    {item.createdAt}
                                  </td>
                                  <td>
                                    <div className="d-flex gap-3 justify-content-center">
                                      <button
                                        type="button"
                                        className="btn btn-danger"
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <h3>This folder is empty</h3>
                            )}
                          </>
                        ) : (
                          <h3>Please choose a folder first</h3>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <ul id="pagination" className="pagination pagination-lg"></ul>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FileManager;
