import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import axios from "axios";
import { useInsertForm } from "../../Components/Hooks/UseInsertForm";

const AddService = () => {
  document.title = "Add Service - VisitTurkey";

  const [options, setOptions] = useState([]);

  const getCategories = async () => {
    const response = await axios.get(
      "/service/category?page=1&take=250&language=en&keyword="
    );
    setOptions(
      response.map((a) => {
        return {
          value: a.category.id.toString(),
          label: a.title,
        };
      })
    );
    console.log(response);
  };

  const { renderForm } = useInsertForm(
    [
      {
        label: "Category Id",
        name: "categoryId",
        required: true,
        type: "selectbox",
        options,
      },
      {
        label: "image",
        name: "image",
        accept: "image/*",
        required: true,
        type: "file",
        multiple: true,
        multiuploadcolumn: "service",
        uploadtype: "service",
      },
    ],
    [
      {
        label: "Name",
        name: "name",
        placeholder: "enter name",
        required: true,
        type: "text",
      },
      {
        label: "Slug",
        name: "slug",
        placeholder: "enter slug",
        required: true,
        type: "text",
      },
    ],
    { postUrl: "/service/create" }
  );

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>{renderForm()}</Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddService;
