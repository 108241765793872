import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import useDoctorLanguageForm from "../../Components/Hooks/useDoctorLanguageForm";
import { UseUpdateSingleFile } from "../../Components/Hooks/UseUpdateSingleFile";
import axios from "axios";

const EditDoctor = () => {
  document.title = "Doktor Düzenle - VisitTurkey";
  const { id } = useParams();
  const [options, setOptions] = useState([]);
  const [language, setLanguage] = useState("tr");

  const { renderRedirectButton } = UseUpdateSingleFile(undefined, {
    pageColumn: "practitioner",
    relationId: id,
  });

  const { renderForm: renderCommonForm, setFormData: setCommonFormData } =
    useDoctorLanguageForm(`/practioner/detail/${id}`);

  const {
    renderForm: renderLanguageForm,
    switchLanguageTab,
    setFormData,
  } = useDoctorLanguageForm(
    `/practioner/detail/${id}/${language}`,
    language,
    (lang) => setLanguage(lang)
  );

  const getOptions = async () => {
    const response = await axios.get("/organization/all/clinic");
    setOptions(
      response.map((x) => {
        return {
          value: x.id.toString(),
          label: x.fullName,
        };
      })
    );
  };

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>{renderRedirectButton()}</Col>
          </Row>
          <Row>
            <Col>
              {renderCommonForm([
                {
                  field: "clinicId",
                  label: "Klinik Id",
                  type: "select",
                  options,
                  required: "required",
                },
                {
                  field: "name",
                  label: "Doktor Adı",
                  type: "input",
                  required: "required",
                },
                {
                  field: "licenseNumber",
                  label: "Lisans Numarası",
                  type: "input",
                  required: "required",
                },
                {
                  field: "field",
                  label: "Uzmanlık Alanı",
                  type: "input",
                  required: "required",
                },
                {
                  field: "slug",
                  label: "Slug",
                  type: "input",
                  required: "required",
                },
              ])}
            </Col>
          </Row>
          <Col style={{ marginBlock: "2rem" }}>
            <Alert color="info">Lütfen güncelleme için bir dil seçin</Alert>
            <Nav tabs>
              {["tr", "ar", "en"].map((lang) => (
                <NavItem key={lang}>
                  <NavLink
                    className={language === lang ? "active fw-bold" : ""}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setLanguage(lang);
                      switchLanguageTab(lang);
                    }}
                  >
                    {lang === "tr"
                      ? "Türkçe"
                      : lang === "ar"
                      ? "Arapça"
                      : "İngilizce"}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
          <Col>
            {renderLanguageForm([
              {
                field: "about",
                label: "Hakkında",
                name: "about",
                placeholder: "Hakkında",
                required: true,
                type: "text",
                richText: true,
              },
            ])}
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditDoctor;
