import React, { useState } from "react";
import {
  Card,
  CardHeader,
  Col,
  Row,
  Container,
  CardBody,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { usePagination } from "../../Components/Hooks/UsePagination";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";

const RecentlyUpdatedApplications = () => {
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const navigate = useNavigate();

  // 1.Tab: accept_offer olmayan, güncellenmiş başvurular
  const {
    data: dataAll,
    emptyResult: emptyResultAll,
    renderFields: renderFieldsAllBase,
    renderData: renderDataAllBase,
    renderButtons: renderButtonsAll,
    renderSearch: renderSearchAll,
  } = usePagination("/offer", {
    fields: [
      { label: "ID", column: "id", id: "id" },
      { label: "Teklif Numarası", column: "offerNumber", id: "offerNumber" },
      { label: "Müşteri Adı", column: "user.firstName", id: "user.firstName" },
      {
        label: "Müşteri Soyadı",
        column: "user.lastName",
        id: "user.lastName",
      },
      {
        label: "Hizmet",
        column: "service.locales.name",
        id: "service.locales.name",
      },
      {
        label: "Alt Hizmet",
        column: "service.subServices.translations.name",
        id: "service.subServices.translations.name",
      },
      { label: "Durum", column: "status", id: "status" },
      { label: "Güncellenme Tarihi", column: "updatedAt", id: "updatedAt" },
      { label: "İşlem", id: "action" },
    ],
    params: "updatedOrder=DESC&status=accept_offer:not",
  });

  // 2.Tab: Sadece accept_offer olanlar
  const {
    data: dataAccepted,
    emptyResult: emptyResultAccepted,
    renderFields: renderFieldsAcceptedBase,
    renderData: renderDataAcceptedBase,
    renderButtons: renderButtonsAccepted,
    renderSearch: renderSearchAccepted,
  } = usePagination("/offer", {
    fields: [
      { label: "ID", column: "id", id: "id" },
      { label: "Teklif Numarası", column: "offerNumber", id: "offerNumber" },
      { label: "Müşteri Adı", column: "user.firstName", id: "user.firstName" },
      {
        label: "Müşteri Soyadı",
        column: "user.lastName",
        id: "user.lastName",
      },
      {
        label: "Hizmet",
        column: "service.locales.name",
        id: "service.locales.name",
      },
      {
        label: "Alt Hizmet",
        column: "service.subServices.translations.name",
        id: "service.subServices.translations.name",
      },
      { label: "Durum", column: "status", id: "status" },
      { label: "Güncellenme Tarihi", column: "updatedAt", id: "updatedAt" },
      { label: "İşlem", id: "action" },
    ],
    params: "updatedOrder=DESC&status=accept_offer",
  });

  // Tarih formatlama ve action button ekleme için renderData fonksiyonlarını wrap ediyoruz
  // Tarihi Türkçe formatlamak için yardımcı fonksiyon
  const formatTurkishDate = (value) => {
    if (!value) return "";
    const date = new Date(value);
    return date.toLocaleString("tr-TR", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const renderDataAll = (cb) => {
    return renderDataAllBase((d) => {
      // Eğer kolon updatedAt ise formatla
      if (d.field.column === "updatedAt" && d.data["updatedAt"]) {
        d.data["updatedAt"] = formatTurkishDate(d.data["updatedAt"]);
      }
      return cb(d);
    });
  };

  const renderDataAccepted = (cb) => {
    return renderDataAcceptedBase((d) => {
      if (d.field.column === "updatedAt" && d.data["updatedAt"]) {
        d.data["updatedAt"] = formatTurkishDate(d.data["updatedAt"]);
      }
      return cb(d);
    });
  };

  const renderActionButtons = (d) => (
    <>
      {d.id === "action" && (
        <div className="d-flex" style={{ gap: "1rem" }}>
          <Button
            color="info"
            outline
            onClick={() => {
              navigate(`/offer-detail/${d.data["id"]}`);
            }}
          >
            Detayları Gör
          </Button>
        </div>
      )}
    </>
  );

  // Tarih formatlaması dışında field render değişmiyorsa orijinal field render'ı kullanabiliriz.
  // Eğer herhangi bir değişiklik yapmıyorsanız direkt renderFieldsAllBase() kullanabilirsiniz.
  const renderFieldsAll = () => renderFieldsAllBase();
  const renderFieldsAccepted = () => renderFieldsAcceptedBase();

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          <ToastContainer />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">
                    Son Güncellenen Başvurular
                  </h4>
                </CardHeader>

                <CardBody>
                  <Nav pills className="mb-3">
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggleTab("1");
                        }}
                      >
                        Tüm Başvurular
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggleTab("2");
                        }}
                      >
                        Onaylananlar
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <div className="listjs-table" id="customerList">
                        <Row className="g-4 mb-3">
                          <Col className="col-sm">
                            <div className="d-flex justify-content-sm-end">
                              {renderSearchAll()}
                            </div>
                          </Col>
                        </Row>

                        <div className="table-responsive table-card mt-3 mb-1">
                          <table
                            className="table align-middle table-nowrap"
                            id="organizationTable"
                          >
                            <thead className="table-light">
                              <tr>{renderFieldsAll()}</tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {renderDataAll(renderActionButtons)}
                            </tbody>
                          </table>
                          {(!dataAll || !dataAll?.length) && emptyResultAll()}
                        </div>

                        {renderButtonsAll()}
                      </div>
                    </TabPane>

                    <TabPane tabId="2">
                      <div className="listjs-table" id="customerList2">
                        <Row className="g-4 mb-3">
                          <Col className="col-sm">
                            <div className="d-flex justify-content-sm-end">
                              {renderSearchAccepted()}
                            </div>
                          </Col>
                        </Row>

                        <div className="table-responsive table-card mt-3 mb-1">
                          <table
                            className="table align-middle table-nowrap"
                            id="organizationTable2"
                          >
                            <thead className="table-light">
                              <tr>{renderFieldsAccepted()}</tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {renderDataAccepted(renderActionButtons)}
                            </tbody>
                          </table>
                          {(!dataAccepted || !dataAccepted?.length) &&
                            emptyResultAccepted()}
                        </div>

                        {renderButtonsAccepted()}
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RecentlyUpdatedApplications;
