import React from "react";
import {
  Card,
  CardHeader,
  Col,
  Row,
  Container,
  CardBody,
  Button,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { usePagination } from "../../Components/Hooks/UsePagination";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSoftDelete } from "../../Components/Hooks/UseSoftDelete";
import { useNavigate } from "react-router-dom";

const AllOrganisations = () => {
  // const {
  //   data,
  //   emptyResult,
  //   renderFields,
  //   renderData,
  //   renderButtons,
  //   renderSearch,
  // } = usePagination("/organization", {
  //   fields: [
  //     { label: "id", column: "id", id: "id" },
  //     { label: "name", column: "name", id: "name" },
  //     { label: "created At", column: "createdAt", id: "createdAt" },
  //     { label: "updated At", column: "updatedAt", id: "updatedAt" },
  //     { label: "is Active", id: "isActive" },
  //     { label: "action", id: "action" },
  //   ],
  // });
  // const { renderSwitch } = useSoftDelete();
  // const navigate = useNavigate();
  const {
    data,
    emptyResult,
    renderFields,
    renderData,
    renderButtons,
    renderSearch,
  } = usePagination("/organization/clinics?type=hospital", {
    fields: [
      { label: "id", column: "id", id: "id" },
      { label: "full name", column: "fullName", id: "fullName" },
      { label: "tax number", column: "taxNumber", id: "taxNumber" },
      { label: "tax office", column: "taxOffice", id: "taxOffice" },
      { label: "license number", column: "licenseNumber", id: "licenseNumber" },
      // {
      //   label: "organization",
      //   column: "organization.name",
      //   id: "organization.name",
      // },
      { label: "created at", column: "createdAt", id: "createdAt" },
      { label: "is Active", id: "isActive" },
      { label: "action", id: "action" },
    ],
  });
  const { renderSwitch } = useSoftDelete();
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className="page-content">
        <ToastContainer />
        <Container fluid>
          <Row>
            <BreadCrumb title="All Hospitals" pageTitle="Tables" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title mb-0">View All Hospitals</h4>
                  </CardHeader>

                  <CardBody>
                    <div className="listjs-table" id="customerList">
                      <Row className="g-4 mb-3">
                        <Col className="col-sm-auto">
                          <div>
                            <Button
                              color="success"
                              className="add-btn me-1"
                              onClick={() => navigate("/add-hospital")}
                              id="create-btn"
                            >
                              <i className="ri-add-line align-bottom me-1"></i>{" "}
                              Add
                            </Button>
                          </div>
                        </Col>
                        <Col className="col-sm">
                          <div className="d-flex justify-content-sm-end">
                            {renderSearch()}
                          </div>
                        </Col>
                      </Row>

                      <div className="table-responsive table-card mt-3 mb-1">
                        <table
                          className="table align-middle table-nowrap"
                          id="organizationTable"
                        >
                          <thead className="table-light">
                            <tr>{renderFields()}</tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {renderData((d) => (
                              <>
                                {d.id === "isActive" &&
                                  renderSwitch(
                                    `/organization/clinic/${d.data["id"]}`,
                                    !d.data["deletedAt"] ? true : false
                                  )}
                                {d.id === "action" && (
                                  <div
                                    className="d-flex"
                                    style={{ gap: "1rem" }}
                                  >
                                    <Button
                                      color="info"
                                      outline
                                      onClick={() => {
                                        navigate(
                                          `/edit-hospital/${d.data["id"]}`
                                        );
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  </div>
                                )}
                              </>
                            ))}
                          </tbody>
                        </table>
                        {(!data || !data?.length) && emptyResult()}
                      </div>

                      {renderButtons()}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
  // return (
  //   <React.Fragment>
  //     <div className="page-content">
  //       <ToastContainer />
  //       <Container fluid>
  //         <Row>
  //           <BreadCrumb title="All Organizations" pageTitle="Tables" />
  //           <Row>
  //             <Col lg={12}>
  //               <Card>
  //                 <CardHeader>
  //                   <h4 className="card-title mb-0">View All Organizations</h4>
  //                 </CardHeader>

  //                 <CardBody>
  //                   <div className="listjs-table" id="customerList">
  //                     <Row className="g-4 mb-3">
  //                       <Col className="col-sm-auto">
  //                         <div>
  //                           <Button
  //                             color="success"
  //                             className="add-btn me-1"
  //                             onClick={() => navigate("/add-organization")}
  //                             id="create-btn"
  //                           >
  //                             <i className="ri-add-line align-bottom me-1"></i>{" "}
  //                             Add
  //                           </Button>
  //                         </div>
  //                       </Col>
  //                       <Col className="col-sm">
  //                         <div className="d-flex justify-content-sm-end">
  //                           {renderSearch()}
  //                         </div>
  //                       </Col>
  //                     </Row>

  //                     <div className="table-responsive table-card mt-3 mb-1">
  //                       <table
  //                         className="table align-middle table-nowrap"
  //                         id="organizationTable"
  //                       >
  //                         <thead className="table-light">
  //                           <tr>{renderFields()}</tr>
  //                         </thead>
  //                         <tbody className="list form-check-all">
  //                           {renderData((d) => (
  //                             <>
  //                               {d.id === "isActive" &&
  //                                 renderSwitch(
  //                                   `/organization/${d.data["id"]}`,
  //                                   !d.data["deletedAt"] ? true : false
  //                                 )}
  //                               {d.id === "action" && (
  //                                 <div
  //                                   className="d-flex"
  //                                   style={{ gap: "1rem" }}
  //                                 >
  //                                   <Button
  //                                     color="info"
  //                                     outline
  //                                     onClick={() => {
  //                                       navigate(
  //                                         `/edit-organization/${d.data["id"]}`
  //                                       );
  //                                     }}
  //                                   >
  //                                     Edit
  //                                   </Button>
  //                                 </div>
  //                               )}
  //                             </>
  //                           ))}
  //                         </tbody>
  //                       </table>
  //                       {(!data || !data?.length) && emptyResult()}
  //                     </div>

  //                     {renderButtons()}
  //                   </div>
  //                 </CardBody>
  //               </Card>
  //             </Col>
  //           </Row>
  //         </Row>
  //       </Container>
  //     </div>
  //   </React.Fragment>
  // );
};

export default AllOrganisations;
