import React from "react";
import { Col, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { useSelector } from "react-redux";

const Section = (props) => {
  const user = useSelector((state) => state.Login.user);
  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <div className="flex-grow-1">
              <h4 className="fs-16 mb-1 text-capitalize">
                Hoş geldiniz, {user.name}!
              </h4>
              <p className="text-muted mb-0">
                İşte özetiniz ve istatistikleriniz.
              </p>
            </div>
            <div className="mt-3 mt-lg-0">
              <form action="#">
                <Row className="g-3 mb-0 align-items-center">
                  {/* <div className="col-auto">
                    <button type="button" className="btn btn-soft-success">
                      <i className="ri-add-circle-line align-middle me-1"></i>{" "}
                      Add User
                    </button>
                  </div> */}
                </Row>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Section;
