import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const useUpdate = () => {
  const updateRequest = async (uri, body) => {
    try {
      await axios.put(uri, body);
      toast("This record updated", {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: true,
        className: "bg-success text-white",
      });
    } catch (e) {
      toast(e?.response?.data?.message || e, {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: true,
        className: "bg-success text-white",
      });
    }
  };

  return {
    updateRequest,
  };
};
