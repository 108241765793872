import React, { useEffect, useState } from "react";
import { Alert, Col, Container, Row, Nav, NavItem, NavLink } from "reactstrap";
import { useParams } from "react-router-dom";
import { UseUpdateSingleFile } from "../../Components/Hooks/UseUpdateSingleFile";
import useQuestionForm from "../../Components/Hooks/useQuestionForm";
import { UseUpdateForm } from "../../Components/Hooks/UseUpdateForm";
import axios from "axios";

const EditQuestion = () => {
  document.title = "Soru Düzenle - VisitTurkey";
  const { id } = useParams();

  const [language, setLanguage] = useState("tr");
  const [options, setOptions] = useState([]);

  const getServices = async () => {
    try {
      const response = await axios.get("/service/services/all");
      if (response.length > 0) {
        const options = response.map((option) => ({
          label: option.name,
          value: option.service?.id,
        }));
        setOptions(options);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { renderFileInput } = UseUpdateSingleFile(`/questions/detail/${id}`, {
    label: "Icon Dosyası",
    column: "iconSrc",
  });

  const { renderForm: renderCommonForm } = UseUpdateForm(
    `/questions/detail/${id}`,
    `/questions/detail/${id}`,
    [],
    {
      fields: [
        {
          field: "isToggleQuestion",
          type: "checkbox",
          label: "Anahtar Soru mu?",
        },
        {
          field: "questionType",
          label: "Tip",
          required: "required",
          type: "select",
          options: [
            { value: "health", label: "Sağlık" },
            { value: "services", label: "Hizmetler" },
            { value: "accommodation", label: "Konaklama" },
            { value: "travel", label: "Seyahat" },
            { value: "additional", label: "Additional" },
          ],
        },
        {
          field: "service",
          label: "Hizmet",
          required: "required",
          type: "select",
          multiple: true,
          options: options,
        },
      ],
    }
  );

  const {
    renderForm: renderLanguageForm,
    switchLanguageTab,
    formData,
    setFormData,
  } = useQuestionForm(`/questions/detail/${id}/${language}`, language);

  useEffect(() => {
    getServices();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>{renderCommonForm()}</Col>
          </Row>
          <Col style={{ marginBlock: "2rem" }}>
            <Alert color="info">Lütfen güncelleme için bir dil seçin</Alert>
            <Nav tabs>
              {["tr", "ar", "en"].map((lang) => (
                <NavItem key={lang}>
                  <NavLink
                    className={language === lang ? "active fw-bold" : ""}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      // Mevcut form verisini sakla ve sonra dil değiştir.
                      setFormData((prevData) => ({ ...prevData }));
                      setLanguage(lang);
                      switchLanguageTab(lang);
                    }}
                  >
                    {lang === "tr"
                      ? "Türkçe"
                      : lang === "ar"
                      ? "Arapça"
                      : "İngilizce"}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
          <Col>
            {renderLanguageForm([
              {
                field: "id",
                readOnly: "readonly",
                type: "input",
                label: "Id",
                required: "required",
              },
              {
                field: "name",
                type: "text",
                label: "İsim",
                required: "required",
              },
            ])}
          </Col>
          <hr />
          <Col>{renderFileInput()}</Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditQuestion;
