// import React, { useEffect, useState } from "react";
// import { Col, Container, Row } from "reactstrap";
// import { UseUpdateForm } from "../../Components/Hooks/UseUpdateForm";
// import { useParams } from "react-router-dom";
// import axios from "axios";

// const EditOrganization = () => {
//   document.title = "Edit Organization - VisitTurkey";
//   const { id } = useParams();

//   const { renderForm: renderCommonForm } = UseUpdateForm(
//     `/organization/detail/${id}`,
//     `/organization/detail/${id}`,
//     [],
//     {
//       fields: [
//         {
//           field: "id",
//           label: "Id",
//           type: "input",
//           readOnly: "readonly",
//           required: "required",
//         },
//         {
//           field: "name",
//           label: "name",
//           type: "input",
//           required: "required",
//         },
//       ],
//     }
//   );

//   return (
//     <React.Fragment>
//       <div className="page-content">
//         <Container fluid>
//           <Row>
//             <Col>{renderCommonForm()}</Col>
//           </Row>
//         </Container>
//       </div>
//     </React.Fragment>
//   );
// };

// export default EditOrganization;

import React from "react";
import { Col, Container, Row } from "reactstrap";
import { UseUpdateForm } from "../../Components/Hooks/UseUpdateForm";
import { useParams } from "react-router-dom";
import { UseUpdateSingleFile } from "../../Components/Hooks/UseUpdateSingleFile";

const EditOrganization = () => {
  document.title = "Edit Hospital - VisitTurkey";
  const { id } = useParams();

  const { renderRedirectButton } = UseUpdateSingleFile(undefined, {
    pageColumn: "clinic",
    relationId: id,
  });

  const { renderForm: renderCommonForm } = UseUpdateForm(
    `/organization/detail/clinic/${id}`,
    `/organization/detail/clinic/${id}`,
    [],
    {
      fields: [
        {
          label: "Type",
          field: "type",
          required: true,
          type: "select",
          required: "required",
          options: [
            { label: "Hospital", value: "hospital" },
            { label: "Clinic", value: "clinic" },
          ],
        },
        {
          field: "fullName",
          label: "Full Name",
          type: "input",
          required: "required",
        },
        {
          field: "taxNumber",
          label: "Tax Number",
          type: "input",
          required: "required",
        },
        {
          field: "taxOffice",
          label: "Tax Office",
          type: "input",
          required: "required",
        },
        {
          field: "address",
          label: "Address",
          type: "textarea",
          required: "required",
        },
      ],
    }
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>{renderCommonForm()}</Col>
            <Col>{renderRedirectButton()}</Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditOrganization;
