import React, { useImperativeHandle, useState, forwardRef } from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";

const UserModal = ({ user }, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen((prevIsOpen) => !prevIsOpen);
  console.log(user);

  useImperativeHandle(ref, () => ({
    toggle,
  }));

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      scrollable={true}
      id="exampleModalScrollable"
    >
      <ModalHeader
        className="modal-title"
        id="exampleModalScrollableTitle"
        toggle={toggle}
      >
        User Information
      </ModalHeader>
      <ModalBody>
        <h6 className="fs-15">Full Name</h6>
        <div className="d-flex">
          <div className="flex-grow-1 ms-2">
            <p className="text-muted mb-0">
              {user.lastName !== "Google"
                ? `${user.firstName} ${user.lastName}`
                : `${user.firstName}`}
            </p>
          </div>
        </div>
        <h6 className="fs-15 mt-4">Email Address</h6>
        <div className="d-flex">
          <div className="flex-grow-1 ms-2">
            <p className="text-muted mb-0">{user.email}</p>
          </div>
        </div>
        <h6 className="fs-15 mt-4">Phone Number</h6>
        <div className="d-flex">
          <div className="flex-grow-1 ms-2">
            <p className="text-muted mb-0">{user.phoneNumber}</p>
          </div>
        </div>
        <h6 className="fs-15 mt-4">Date Of Birth</h6>
        <div className="d-flex">
          <div className="flex-grow-1 ms-2">
            <p className="text-muted mb-0">{user.dateOfBirth}</p>
          </div>
        </div>
        {user?.city && (
          <>
            <h6 className="fs-15 mt-4">City</h6>
            <div className="d-flex">
              <div className="flex-grow-1 ms-2">
                <p className="text-muted mb-0">{user.city}</p>
              </div>
            </div>
          </>
        )}

        {user?.gender && (
          <>
            <h6 className="fs-15 mt-4">Gender</h6>
            <div className="d-flex">
              <div className="flex-grow-1 ms-2">
                <p className="text-muted mb-0">{user.gender}</p>
              </div>
            </div>
          </>
        )}
      </ModalBody>

      <div className="modal-footer">
        <Button color="light" onClick={() => setIsOpen(false)}>
          Close
        </Button>
        {/* <Button color="primary">Save changes</Button> */}
      </div>
    </Modal>
  );
};

export default forwardRef(UserModal);
