import React from "react";
import {
  Card,
  CardHeader,
  Col,
  Row,
  Container,
  CardBody,
  Button,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { usePagination } from "../../Components/Hooks/UsePagination";
import { useNavigate } from "react-router-dom";
import { useSoftDelete } from "../../Components/Hooks/UseSoftDelete";

const AllHotelCategories = () => {
  const {
    data,
    emptyResult,
    renderFields,
    renderData,
    renderButtons,
    renderSearch,
  } = usePagination("/accommodation/categories", {
    fields: [
      { label: "id", column: "id", id: "id" },
      { label: "Created at", column: "createdAt", id: "createdat" },
      { label: "Name", column: "name", id: "name" },
      { label: "is Active", id: "isActive" },
      { label: "action", id: "action" },
    ],
  });
  const { renderSwitch, renderToast } = useSoftDelete();
  const navigate = useNavigate();

  const deleteHotelCategory = async (id) => {
    try {
      await axios.delete(`/accommodation/categories/${id}`);
      toast("Hotel category has been deleted successfully!", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
    } catch (error) {
      toast("An error occurred while deleting hotel category", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
      if (process.env.NODE_ENV === "development") console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <ToastContainer />
          {renderToast()}
          <Row>
            <BreadCrumb title="All Categories" pageTitle="Categories" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title mb-0">View All Categories</h4>
                  </CardHeader>

                  <CardBody>
                    <div className="listjs-table" id="customerList">
                      <Row className="g-4 mb-3">
                        <Col className="col-sm-auto">
                          <div>
                            <Button
                              color="success"
                              className="add-btn me-1"
                              onClick={() => navigate("/hotels/categories/add")}
                              id="create-btn"
                            >
                              <i className="ri-add-line align-bottom me-1"></i>{" "}
                              Add
                            </Button>
                          </div>
                        </Col>
                        <Col className="col-sm">
                          <div className="d-flex justify-content-sm-end">
                            {renderSearch()}
                          </div>
                        </Col>
                      </Row>

                      <div className="table-responsive table-card mt-3 mb-1">
                        <table
                          className="table align-middle table-nowrap"
                          id="organizationTable"
                        >
                          <thead className="table-light">
                            <tr>{renderFields()}</tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {renderData((d) => (
                              <>
                                {d.id === "isActive" &&
                                  renderSwitch(
                                    `/accommodation/categories/${d.data["accommodationCategory.id"]}`,
                                    !d.data["accommodationCategory.deletedAt"]
                                      ? true
                                      : false
                                  )}
                                {d.id === "action" && (
                                  <div
                                    className="d-flex"
                                    style={{ gap: "1rem" }}
                                  >
                                    <Button
                                      color="info"
                                      onClick={() => {
                                        navigate(
                                          `/hotels/categories/edit/${d.data["accommodationCategory.id"]}`
                                        );
                                      }}
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      color="danger"
                                      onClick={() =>
                                        deleteHotelCategory(
                                          d.data["accommodationCategory.id"]
                                        )
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                )}
                              </>
                            ))}
                          </tbody>
                        </table>
                        {(!data || !data?.length) && emptyResult()}
                      </div>

                      {renderButtons()}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AllHotelCategories;
