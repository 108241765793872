import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { UseUpdateForm } from "../../Components/Hooks/UseUpdateForm";
import { useParams } from "react-router-dom";
import axios from "axios";
import { UseUpdateSingleFile } from "../../Components/Hooks/UseUpdateSingleFile";

const EditClinic = () => {
  document.title = "Edit Clinic - VisitTurkey";
  const { id } = useParams();
  const [options, setOptions] = useState([]);

  const { renderRedirectButton } = UseUpdateSingleFile(undefined, {
    pageColumn: "clinic",
    relationId: id,
  });

  // const getOptions = async () => {
  //   const response = await axios.get("/organization?page=1&take=250&keyword=");
  //   setOptions(
  //     response.map((x) => {
  //       return {
  //         value: x.id.toString(),
  //         label: x.name,
  //       };
  //     })
  //   );
  // };

  const { renderForm: renderCommonForm } = UseUpdateForm(
    `/organization/detail/clinic/${id}`,
    `/organization/detail/clinic/${id}`,
    [],
    {
      fields: [
        // {
        //   field: "organizationId",
        //   label: "Organization",
        //   type: "select",
        //   options,
        //   required: "required",
        // },
        {
          label: "Type",
          field: "type",
          required: true,
          type: "select",
          required: "required",
          options: [
            { label: "Hospital", value: "hospital" },
            { label: "Clinic", value: "clinic" },
          ],
        },
        {
          field: "fullName",
          label: "Full Name",
          type: "input",
          required: "required",
        },
        {
          field: "taxNumber",
          label: "Tax Number",
          type: "input",
          required: "required",
        },
        {
          field: "taxOffice",
          label: "Tax Office",
          type: "input",
          required: "required",
        },
        {
          field: "address",
          label: "Address",
          type: "textarea",
          required: "required",
        },
      ],
    }
  );

  // useEffect(() => {
  //   getOptions();
  // }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>{renderCommonForm()}</Col>
            <Col>{renderRedirectButton()}</Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditClinic;
