import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { loginUser, lockUser } from "../../slices/thunks";

const useProfile = () => {
  const user = useSelector((state) => state.Login.user);
  const locked = useSelector((state) => state.Login.locked);

  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const lock = async () => {
    await axios.patch("/auth/lock");
    dispatch(lockUser(true));
  };

  const unlock = () => dispatch(lockUser(false));

  const logout = () => {
    localStorage.removeItem("authUser");
    dispatch(loginUser(undefined));
  };

  const loginWithToken = async () => {
    const token = localStorage.getItem("authUser");
    if (!token) {
      setLoading(false);
    }
    try {
      const response = await axios.get("/auth/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.admin.loginStatus === "lock") {
        dispatch(lockUser(true));
      }
      dispatch(loginUser(response.admin));
    } catch (e) {
      alert(e.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loginWithToken();
  }, []);

  return {
    isLoading,
    user,
    locked,
    lock,
    unlock,
    isLoggedin: Object.keys(user).length > 0 ? true : false,
    logout,
  };
};

export { useProfile };
