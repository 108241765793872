import React, { useState } from "react";
import {
  Alert,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button,
} from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import useArticleLanguageForm from "../../Components/Hooks/useArticleLanguageForm";
import { UseUpdateSingleFile } from "../../Components/Hooks/UseUpdateSingleFile";
import axios from "axios";
import { toast } from "react-toastify";

const EditArticle = () => {
  document.title = "Makaleyi Düzenle - VisitTurkey";
  const { id } = useParams();
  const navigate = useNavigate();

  const [language, setLanguage] = useState("tr");
  const { renderRedirectButton } = UseUpdateSingleFile(undefined, {
    pageColumn: "blog",
    relationId: id,
  });

  const { renderForm: renderCommonForm, setFormData: setCommonFormData } =
    useArticleLanguageForm(`/blog/detail/${id}`);

  const {
    renderForm: renderLanguageForm,
    switchLanguageTab,
    setFormData,
  } = useArticleLanguageForm(
    `/blog/detail/locale/${id}/${language}`,
    language,
    (lang) => setLanguage(lang)
  );

  const deleteBlog = async () => {
    try {
      if (confirm("Bu makaleyi silmek üzeresiniz")) {
        await axios.delete(`/blog/${id}`);
        toast(`Blog başarıyla silindi`, {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          className: "bg-success text-white",
        });
        navigate("/discover/blog");
      }
    } catch (error) {
      toast(`Bir hata ile karşılaştık`, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-warning text-white",
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Col>{renderRedirectButton()}</Col>
          <Col>
            <Button
              onClick={deleteBlog}
              type="submit"
              color="danger"
              className="mt-4 btn w-100 d-block"
            >
              Blogu Sil
            </Button>
          </Col>
          <Row>
            <Col>
              {renderCommonForm([
                {
                  field: "isStory",
                  label: "Bu bir hikaye mi?",
                  type: "checkbox",
                },
                {
                  field: "type",
                  label: "Tür",
                  required: "required",
                  type: "select",
                  options: [
                    { value: "historical", label: "Tarihi" },
                    { value: "touristical", label: "Turistik" },
                    { value: "city", label: "Şehir" },
                    { value: "blog", label: "Blog" },
                  ],
                },
              ])}
            </Col>
          </Row>
          <Col style={{ marginBlock: "2rem" }}>
            <Alert color="info">Güncelleme için bir dil seçiniz</Alert>
            <Nav tabs>
              {["tr", "ar", "en"].map((lang) => (
                <NavItem key={lang}>
                  <NavLink
                    className={language === lang ? "active fw-bold" : ""}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setLanguage(lang);
                      switchLanguageTab(lang);
                    }}
                  >
                    {lang === "tr"
                      ? "Türkçe"
                      : lang === "ar"
                      ? "Arapça"
                      : "İngilizce"}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
          <Col>
            {renderLanguageForm([
              {
                field: "id",
                readOnly: "readonly",
                type: "input",
                label: "Id",
                required: "required",
              },
              {
                field: "title",
                type: "input",
                label: "Başlık",
                required: "required",
              },
              {
                field: "subtitle",
                type: "input",
                label: "Alt Başlık",
                required: "required",
              },
              {
                field: "firstParagraph",
                type: "input",
                label: "İlk Paragraf",
                required: "required",
              },
              {
                field: "body",
                richText: true,
                label: "İçerik",
                required: "required",
              },
              {
                field: "slug",
                type: "input",
                label: "Bağlantı",
                required: "required",
              },
            ])}
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditArticle;
