import React, { useState } from "react";
import { Alert, Col, Container, Nav, NavItem, NavLink } from "reactstrap";
import { useParams } from "react-router-dom";
import usePolicyLanguageForm from "../../Components/Hooks/usePolicyLanguageForm";

const EditPolicy = () => {
  document.title = "Politika Düzenle - VisitTurkey";
  const { id } = useParams();

  const [language, setLanguage] = useState("tr");

  const {
    renderForm: renderLanguageForm,
    switchLanguageTab,
    setFormData,
  } = usePolicyLanguageForm(`/policy/${id}/${language}`, language);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Col style={{ marginBlock: "2rem" }}>
            <Alert color="info">Lütfen güncelleme için bir dil seçin</Alert>
            <Nav tabs>
              {["tr", "ar", "en"].map((lang) => (
                <NavItem key={lang}>
                  <NavLink
                    className={language === lang ? "active fw-bold" : ""}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setFormData((prevData) => ({ ...prevData }));
                      setLanguage(lang);
                      switchLanguageTab(lang);
                    }}
                  >
                    {lang === "tr"
                      ? "Türkçe"
                      : lang === "ar"
                      ? "Arapça"
                      : "İngilizce"}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
          <Col>
            {renderLanguageForm([
              {
                field: "id",
                readOnly: "readonly",
                type: "input",
                label: "Id",
                required: "required",
              },
              {
                field: "title",
                type: "text",
                label: "Başlık",
                required: "required",
              },
              {
                field: "slug",
                type: "text",
                label: "Slug",
                required: "required",
              },
              {
                field: "content",
                type: "text",
                label: "İçerik",
                required: "required",
                richText: true,
              },
            ])}
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditPolicy;
