import React from "react";
import {
  Card,
  CardHeader,
  Col,
  Row,
  Container,
  CardBody,
  Button,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { usePagination } from "../../Components/Hooks/UsePagination";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const RecentlyUpdatedApplications = () => {
  const {
    data,
    emptyResult,
    renderFields,
    renderData,
    renderButtons,
    renderSearch,
  } = usePagination("/offer", {
    fields: [
      { label: "id", column: "id", id: "id" },
      { label: "offerNumber", column: "offerNumber", id: "offerNumber" },
      {
        label: "Customer Name",
        column: "user.firstName",
        id: "user.firstName",
      },
      {
        label: "Customer Lastname ",
        column: "user.lastName",
        id: "user.lastName",
      },
      {
        label: "Service",
        id: "service.locales.name",
        column: "service.locales.name",
      },
      {
        label: "Sub Service",
        id: "service.subServices.translations.name",
        column: "service.subServices.translations.name",
      },
      { label: "updated at", column: "updatedAt", id: "updatedAt" },
      // { label: "is Active", id: "isActive" },
      { label: "action", id: "action" },
    ],
    params: "updatedOrder=DESC",
  });
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          <ToastContainer />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">
                    Recently Updated Applications
                  </h4>
                </CardHeader>

                <CardBody>
                  <div className="listjs-table" id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          {renderSearch()}
                        </div>
                      </Col>
                    </Row>

                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="organizationTable"
                      >
                        <thead className="table-light">
                          <tr>{renderFields()}</tr>
                        </thead>
                        <tbody className="list form-check-all">
                          {renderData((d) => (
                            <>
                              {d.id === "action" && (
                                <div className="d-flex" style={{ gap: "1rem" }}>
                                  <Button
                                    color="info"
                                    outline
                                    onClick={() => {
                                      navigate(`/offer-detail/${d.data["id"]}`);
                                    }}
                                  >
                                    View Details
                                  </Button>
                                </div>
                              )}
                            </>
                          ))}
                        </tbody>
                      </table>
                      {(!data || !data?.length) && emptyResult()}
                    </div>

                    {renderButtons()}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RecentlyUpdatedApplications;
