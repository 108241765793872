import React, { useState, useEffect } from "react";
import { Alert, Button, Col, Container, Row } from "reactstrap";
import { UseUpdateForm } from "../../Components/Hooks/UseUpdateForm";
import { useParams } from "react-router-dom";
import axios from "axios";
import { UseUpdateSingleFile } from "../../Components/Hooks/UseUpdateSingleFile";

const EditService = () => {
  document.title = "Edit Service - VisitTurkey";
  const { id } = useParams();

  const [language, setLanguage] = useState("tr");
  const [options, setOptions] = useState([]);

  const { renderRedirectButton } = UseUpdateSingleFile(undefined, {
    pageColumn: "service",
    relationId: id,
  });

  const { renderForm: renderCommonForm } = UseUpdateForm(
    `/service/service/detail/${id}`,
    `/service/service/detail/${id}`,
    [options],
    {
      fields: [
        {
          field: "categoryId",
          label: "Category Id",
          type: "select",
          options,
        },
      ],
    }
  );

  const { renderForm: renderLanguageForm } = UseUpdateForm(
    `/service/service/detail/${id}/${language}`,
    `/service/service/detail/${id}/${language}`,
    [language],
    {
      fields: [
        {
          field: "id",
          readOnly: "readonly",
          type: "input",
          label: "Id",
          required: "required",
        },
        {
          field: "name",
          type: "text",
          label: "Name",
          required: "required",
        },
        {
          field: "slug",
          type: "text",
          label: "Slug",
          required: "required",
        },
      ],
    }
  );

  const getCategories = async () => {
    const response = await axios.get(
      "/service/category?page=1&take=250&language=en&keyword="
    );
    setOptions(
      response.map((a) => {
        return {
          value: a.category.id.toString(),
          label: a.title,
        };
      })
    );
    console.log(response);
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>{renderCommonForm()}</Col>
          </Row>
          <Col style={{ marginBlock: "2rem" }}>
            <Alert color="info">Please choose a locale for update</Alert>
            <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
              <Button
                onClick={() => setLanguage("tr")}
                className={language === "tr" ? "fw-bold" : ""}
              >
                Turkish
              </Button>
              <Button
                onClick={() => setLanguage("ar")}
                dir="rtl"
                className={language === "ar" ? "fw-bold" : ""}
              >
                Arabic
              </Button>
              <Button
                onClick={() => setLanguage("en")}
                className={language === "en" ? "fw-bold" : ""}
              >
                English
              </Button>
            </div>
          </Col>
          <Col>{renderLanguageForm()}</Col>
          <Col>{renderRedirectButton()}</Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditService;
