import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isApps, setIsApps] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isPages, setIsPages] = useState(false);
  const [isBaseUi, setIsBaseUi] = useState(false);
  const [isAdvanceUi, setIsAdvanceUi] = useState(false);
  const [isForms, setIsForms] = useState(false);
  const [isTables, setIsTables] = useState(false);
  const [isFiles, setIsFiles] = useState(false);
  const [isCharts, setIsCharts] = useState(false);
  const [isIcons, setIsIcons] = useState(false);
  const [isMaps, setIsMaps] = useState(false);
  const [isMultiLevel, setIsMultiLevel] = useState(false);
  const [isUsers, setIsUsers] = useState(false);
  const [isTourism, setIsTourism] = useState(false);
  const [isDiscover, setIsDiscover] = useState(false);
  const [isPage, setIsPage] = useState(false);
  const [isBlog, setIsBlog] = useState(false);
  const [isHelp, setIsHelp] = useState(false);
  const [isApplication, setIsApplication] = useState(false);
  const [isMail, setIsMail] = useState(false);
  const [isMobileApp, setIsMobileApp] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [isDoctor, setIsDoctor] = useState(false);
  const [isAccounting, setIsAccounting] = useState(false);
  const [isStatistics, setIsStatistics] = useState(false);
  const [isSetting, setIsSetting] = useState(false);
  const [isOrganisation, setIsOrganisation] = useState(false);
  const [isClinic, setIsClinic] = useState(false);
  const [isSubDoctor, setIsSubDoctor] = useState(false);
  const [isSubDiscover, setIsSubDiscover] = useState(false);
  const [isSubQuestion, setIsSubQuestion] = useState(false);
  const [isAccommodation, setIsAccommodation] = useState(false);
  const [isPolicy, setIsPolicy] = useState(false);

  // Apps
  const [isEmail, setEmail] = useState(false);
  const [isSubEmail, setSubEmail] = useState(false);
  const [isEcommerce, setIsEcommerce] = useState(false);
  const [isProjects, setIsProjects] = useState(false);
  const [isTasks, setIsTasks] = useState(false);
  const [isCRM, setIsCRM] = useState(false);
  const [isCrypto, setIsCrypto] = useState(false);
  const [isInvoices, setIsInvoices] = useState(false);
  const [isSupportTickets, setIsSupportTickets] = useState(false);
  const [isNFTMarketplace, setIsNFTMarketplace] = useState(false);
  const [isJobs, setIsJobs] = useState(false);
  const [isJobList, setIsJobList] = useState(false);
  const [isCandidateList, setIsCandidateList] = useState(false);

  // Authentication
  const [isSignIn, setIsSignIn] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isPasswordCreate, setIsPasswordCreate] = useState(false);
  const [isLockScreen, setIsLockScreen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isVerification, setIsVerification] = useState(false);
  const [isError, setIsError] = useState(false);

  // Pages
  const [isProfile, setIsProfile] = useState(false);
  const [isLanding, setIsLanding] = useState(false);

  // Charts
  const [isApex, setIsApex] = useState(false);

  // Multi Level
  const [isLevel1, setIsLevel1] = useState(false);
  const [isLevel2, setIsLevel2] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Apps") {
      setIsApps(false);
    }
    if (iscurrentState !== "Auth") {
      setIsAuth(false);
    }
    if (iscurrentState !== "Pages") {
      setIsPages(false);
    }
    if (iscurrentState !== "BaseUi") {
      setIsBaseUi(false);
    }
    if (iscurrentState !== "AdvanceUi") {
      setIsAdvanceUi(false);
    }
    if (iscurrentState !== "Forms") {
      setIsForms(false);
    }
    if (iscurrentState !== "Tables") {
      setIsTables(false);
    }
    if (iscurrentState !== "Charts") {
      setIsCharts(false);
    }
    if (iscurrentState !== "Icons") {
      setIsIcons(false);
    }
    if (iscurrentState !== "Maps") {
      setIsMaps(false);
    }
    if (iscurrentState !== "MuliLevel") {
      setIsMultiLevel(false);
    }
    if (iscurrentState === "Widgets") {
      history("/widgets");
      document.body.classList.add("twocolumn-panel");
    }
    if (iscurrentState !== "Landing") {
      setIsLanding(false);
    }
    if (iscurrentState !== "Users") {
      setIsUsers(false);
    }
    if (iscurrentState !== "Health Tourism") {
      setIsTourism(false);
    }
    if (iscurrentState !== "Discover Turkey") {
      setIsDiscover(false);
    }
    if (iscurrentState !== "Pages") {
      setIsPage(false);
    }
    if (iscurrentState !== "Blog") {
      setIsBlog(false);
    }
    if (iscurrentState !== "Support") {
      setIsHelp(false);
    }
    if (iscurrentState !== "Applications") {
      setIsApplication(false);
    }
    if (iscurrentState !== "Mail") {
      setIsMail(false);
    }
    if (iscurrentState !== "Mobile App") {
      setIsMobileApp(false);
    }
    if (iscurrentState !== "Staff") {
      setIsStaff(false);
    }
    if (iscurrentState !== "Hospital/Clinic/Doctor") {
      setIsDoctor(false);
    }
    if (iscurrentState !== "Accounting") {
      setIsAccounting(false);
    }
    if (iscurrentState !== "Statistics") {
      setIsStatistics(false);
    }
    if (iscurrentState !== "Settings") {
      setIsSetting(false);
    }
    if (iscurrentState !== "Policies") {
      setIsSetting(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isApps,
    isAuth,
    isPages,
    isBaseUi,
    isAdvanceUi,
    isForms,
    isTables,
    isCharts,
    isIcons,
    isMaps,
    isMultiLevel,
    isUsers,
    isTourism,
    isPage,
    isBlog,
    isHelp,
    isApplication,
    isMail,
    isMobileApp,
    isStaff,
    isDoctor,
    isAccounting,
    isStatistics,
    isSetting,
  ]);

  const menuItems = [
    {
      label: "Menü",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Panel",
      icon: "ri-dashboard-2-line",
      link: "/#",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "applications",
          label: "Son Başvurular",
          link: "/latest-applications",
          parentId: "dashboard",
        },
        {
          id: "supports",
          label: "Son Destek Talepleri",
          link: "/latest-support-requests",
          parentId: "dashboard",
        },
        {
          id: "treatments",
          label: "Aktif Tedaviler",
          link: "/active-treatments",
          parentId: "dashboard",
        },
      ],
    },
    {
      id: "user",
      label: "Kullanıcılar",
      icon: "ri-account-circle-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsUsers(!isUsers);
        setIscurrentState("Users");
        updateIconSidebar(e);
      },
      stateVariables: isUsers,
      subItems: [
        {
          id: "all-users",
          label: "Tüm Kullanıcılar",
          link: "/all-users",
          parentId: "user",
        },
        {
          id: "all-users",
          label: "Ekip",
          link: "/team",
          parentId: "user",
        },
      ],
    },
    {
      id: "tourism",
      label: "Sağlık Turizmi",
      icon: "ri-account-circle-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsTourism(!isTourism);
        setIscurrentState("Health Tourism");
        updateIconSidebar(e);
      },
      stateVariables: isTourism,
      subItems: [
        {
          id: "sections",
          label: "Bölümler",
          link: "/sections",
          parentId: "tourism",
        },
        {
          id: "categories",
          label: "Kategoriler",
          link: "/categories",
          parentId: "tourism",
        },
        {
          id: "questions",
          label: "Sorular",
          link: "/#",
          parentId: "tourism",
          stateVariables: isSubQuestion,
          isChildItem: true,
          click: function (e) {
            e.preventDefault();
            setIsSubQuestion(!isSubQuestion);
          },
          childItems: [
            {
              id: 1,
              label: "Sağlık",
              link: "/questions/health",
              parentId: "questions",
            },
            {
              id: 2,
              label: "Seyahat",
              link: "/questions/travel",
              parentId: "questions",
            },
            {
              id: 3,
              label: "Konaklama",
              link: "/questions/accommodation",
              parentId: "questions",
            },
            {
              id: 3,
              label: "Ek",
              link: "/questions/additional",
              parentId: "questions",
            },
            {
              id: 4,
              label: "Soru Ekle",
              link: "/questions/create",
              parentId: "questions",
            },
          ],
        },
        {
          id: "add-category",
          label: "Kategori Ekle",
          link: "/add-category",
          parentId: "tourism",
        },
        {
          id: "add-section",
          label: "Bölüm Ekle",
          link: "/add-section",
          parentId: "tourism",
        },
      ],
    },
    {
      id: "discover",
      label: "Türkiye'yi Keşfet",
      icon: "ri-account-circle-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsDiscover(!isDiscover);
        setIscurrentState("Discover Turkey");
        updateIconSidebar(e);
      },
      stateVariables: isDiscover,
      subItems: [
        {
          id: "places",
          label: "Mekanlar",
          link: "/#",
          parentId: "discover",
          stateVariables: isSubDiscover,
          isChildItem: true,
          click: function (e) {
            e.preventDefault();
            setIsSubDiscover(!isSubDiscover);
          },
          childItems: [
            {
              id: 1,
              label: "Tarihi Mekanlar",
              link: "/discover/historical",
              parentId: "places",
            },
            {
              id: 2,
              label: "Şehirler",
              link: "/discover/city",
              parentId: "places",
            },
            {
              id: 3,
              label: "Turistik Mekanlar",
              link: "/discover/touristical",
              parentId: "places",
            },
          ],
        },
      ],
    },
    {
      id: "file",
      label: "Dosya Yöneticisi",
      icon: "ri-account-circle-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsFiles(!isFiles);
        setIscurrentState("File");
        updateIconSidebar(e);
      },
      stateVariables: isFiles,
      subItems: [
        {
          id: "files",
          label: "Dosyaları Görüntüle",
          link: "/files",
          parentId: "file",
        },
      ],
    },
    {
      id: "blog",
      label: "Blog",
      icon: "ri-account-circle-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsBlog(!isBlog);
        setIscurrentState("Blog");
        updateIconSidebar(e);
      },
      stateVariables: isBlog,
      subItems: [
        {
          id: "all-articles",
          label: "Tüm Makaleler",
          link: "/discover/blog",
          parentId: "blog",
        },
        {
          id: "add-article",
          label: "Makale Ekle",
          link: "/add-article",
          parentId: "blog",
        },
      ],
    },
    {
      id: "application",
      label: "Başvurular",
      icon: "ri-account-circle-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsApplication(!isApplication);
        setIscurrentState("Applications");
        updateIconSidebar(e);
      },
      stateVariables: isApplication,
      subItems: [
        {
          id: "all-applications",
          label: "Tüm Başvurular",
          link: "/all-applications",
          parentId: "application",
        },
        {
          id: "all-applications",
          label: "Teklif Oluştur",
          link: "/create-offer",
          parentId: "application",
        },
        {
          id: "contact-requests",
          label: "İletişim Talepleri",
          link: "/contact-requests",
          parentId: "application",
        },
      ],
    },
    {
      id: "doctors",
      label: "Hastane/Klinik/Doktor",
      icon: "ri-account-circle-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsDoctor(!isDoctor);
        setIscurrentState("Hospital/Clinic/Doctor");
        updateIconSidebar(e);
      },
      stateVariables: isDoctor,
      subItems: [
        {
          id: "hospitals",
          label: "Hastaneler",
          link: "/#",
          isChildItem: true,
          click: function (e) {
            e.preventDefault();
            setIsOrganisation(!isOrganisation);
          },
          stateVariables: isOrganisation,
          parentId: "doctors",
          childItems: [
            {
              id: 1,
              label: "Tüm Hastaneler",
              link: "/all-hospitals",
              parentId: "doctors",
            },
            {
              id: 2,
              label: "Hastane Ekle",
              link: "/add-hospital",
              parentId: "doctors",
            },
          ],
        },
        {
          id: "clinics",
          label: "Klinikler",
          link: "/#",
          isChildItem: true,
          parentId: "doctors",
          stateVariables: isClinic,
          click: function (e) {
            e.preventDefault();
            setIsClinic(!isClinic);
          },
          childItems: [
            {
              id: 1,
              label: "Tüm Klinikler",
              link: "/all-clinics",
              parentId: "doctors",
            },
            {
              id: 2,
              label: "Klinik Ekle",
              link: "/add-clinic",
              parentId: "doctors",
            },
          ],
        },
        {
          id: "doctors",
          label: "Doktorlar",
          link: "/#",
          parentId: "doctors",
          isChildItem: true,
          stateVariables: isSubDoctor,
          click: function (e) {
            e.preventDefault();
            setIsSubDoctor(!isSubDoctor);
          },
          childItems: [
            {
              id: 1,
              label: "Tüm Doktorlar",
              link: "/all-doctors",
              parentId: "doctors",
            },
            {
              id: 2,
              label: "Doktor Ekle",
              link: "/add-doctor",
              parentId: "doctors",
            },
          ],
        },
      ],
    },
    {
      id: "accommodations",
      label: "Konaklamalar",
      icon: "ri-account-circle-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsAccommodation(!isAccommodation);
        setIscurrentState("Accommodations");
        updateIconSidebar(e);
      },
      stateVariables: isAccommodation,
      subItems: [
        {
          id: "hotels",
          label: "Oteller",
          link: "/hotels",
          parentId: "accommodations",
        },
        {
          id: "categories",
          label: "Kategoriler",
          link: "/hotels/categories",
          parentId: "accommodations",
        },
        {
          id: "add-hotel",
          label: "Otel Ekle",
          link: "/hotels/add",
          parentId: "accommodations",
        },
        {
          id: "add-category",
          label: "Kategori Ekle",
          link: "/hotels/categories/add",
          parentId: "accommodations",
        },
      ],
    },
    {
      id: "policies",
      label: "Poliçeler",
      icon: "ri-account-circle-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsPolicy(!isPolicy);
        setIscurrentState("Policies");
        updateIconSidebar(e);
      },
      stateVariables: isPolicy,
      subItems: [
        {
          id: "policy",
          label: "Poliçeler",
          link: "/policies",
          parentId: "policies",
        },
        {
          id: "add-policy",
          label: "Poliçe Ekle",
          link: "/policy/add",
          parentId: "policies",
        },
      ],
    },
  ];

  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
