import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row, Nav, NavItem, NavLink } from "reactstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import { UseUpdateSingleFile } from "../../Components/Hooks/UseUpdateSingleFile";
import useServiceLanguageForm from "../../Components/Hooks/useServiceLanguageForm";
import { UseUpdateForm } from "../../Components/Hooks/UseUpdateForm";

const EditService = () => {
  document.title = "Hizmet Düzenle - VisitTurkey";
  const { id } = useParams();

  const [language, setLanguage] = useState("tr");
  const [options, setOptions] = useState([]);

  const { renderRedirectButton } = UseUpdateSingleFile(undefined, {
    pageColumn: "service",
    relationId: id,
  });

  const { renderForm: renderCommonForm } = UseUpdateForm(
    `/service/service/detail/${id}`,
    `/service/service/detail/${id}`,
    [options],
    {
      fields: [
        {
          field: "categoryId",
          label: "Kategori Id",
          type: "select",
          options,
        },
      ],
    }
  );

  const {
    renderForm: renderLanguageForm,
    switchLanguageTab,
    setFormData,
  } = useServiceLanguageForm(
    `/service/service/detail/${id}/${language}`,
    language
  );

  const getCategories = async () => {
    try {
      const response = await axios.get(
        "/service/category?page=1&take=250&language=en&keyword="
      );
      if (response) {
        setOptions(
          response.map((a) => {
            return {
              value: a.category.id.toString(),
              label: a.title,
            };
          })
        );
      }
    } catch (error) {
      console.error("Kategori verisi çekilemedi:", error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>{renderRedirectButton()}</Row>
          <Row>
            <Col>{renderCommonForm()}</Col>
          </Row>
          <Col style={{ marginBlock: "2rem" }}>
            <Alert color="info">Lütfen güncelleme için bir dil seçin</Alert>
            <Nav tabs>
              {["tr", "ar", "en"].map((lang) => (
                <NavItem key={lang}>
                  <NavLink
                    className={language === lang ? "active fw-bold" : ""}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setFormData((prevData) => ({ ...prevData }));
                      setLanguage(lang);
                      switchLanguageTab(lang);
                    }}
                  >
                    {lang === "tr"
                      ? "Türkçe"
                      : lang === "ar"
                      ? "Arapça"
                      : "İngilizce"}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
          <Col>
            {renderLanguageForm([
              {
                field: "id",
                readOnly: "readonly",
                type: "input",
                label: "Id",
                required: "required",
              },
              {
                field: "name",
                type: "text",
                label: "Adı",
                required: "required",
              },
              {
                field: "slug",
                type: "text",
                label: "Slug",
                required: "required",
              },
            ])}
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditService;
