import React from "react";
import {
  Card,
  CardHeader,
  Col,
  Row,
  Container,
  CardBody,
  Button,
  FormGroup,
  Input,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import "react-toastify/dist/ReactToastify.css";
import { usePagination } from "../../Components/Hooks/UsePagination";
import { useParams, useNavigate } from "react-router-dom";
import { useSoftDelete } from "../../Components/Hooks/UseSoftDelete";

const Subservice = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data,
    emptyResult,
    renderFields,
    renderData,
    renderButtons,
    renderSearch,
  } = usePagination(`/service/services/sub/${id}`, {
    fields: [
      { label: "id", column: "id", id: "id" },
      { label: "Created at", column: "createdAt", id: "createdat" },
      { label: "Name", column: "name", id: "name" },
      { label: "Slug", column: "slug", id: "slug" },
      { label: "is Active", id: "isActive" },
      { label: "action", id: "action" },
    ],
  });

  const { renderSwitch, renderToast } = useSoftDelete();

  return (
    <React.Fragment>
      <div className="page-content">
        {renderToast()}
        <Container fluid>
          <Row>
            <BreadCrumb title="All Sub Services" pageTitle="Tables" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title mb-0">View All Sub Services</h4>
                  </CardHeader>

                  <CardBody>
                    <div className="listjs-table" id="customerList">
                      <Row className="g-4 mb-3">
                        <Col className="col-sm-auto">
                          <div>
                            <Button
                              color="success"
                              className="add-btn me-1"
                              onClick={() => navigate(`/add-subservice/${id}`)}
                              id="create-btn"
                            >
                              <i className="ri-add-line align-bottom me-1"></i>{" "}
                              Add
                            </Button>
                          </div>
                        </Col>
                        <Col className="col-sm">
                          <div className="d-flex justify-content-sm-end">
                            {renderSearch()}
                          </div>
                        </Col>
                      </Row>

                      <div className="table-responsive table-card mt-3 mb-1">
                        <table
                          className="table align-middle table-nowrap"
                          id="organizationTable"
                        >
                          <thead className="table-light">
                            <tr>{renderFields()}</tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {renderData((d) => (
                              <>
                                {d.id === "isActive" &&
                                  renderSwitch(
                                    `/service/service/${d.data["subService.id"]}/sub`,
                                    !d.data["subService.deletedAt"]
                                      ? true
                                      : false
                                  )}

                                {d.id === "action" && (
                                  <div
                                    className="d-flex"
                                    style={{ gap: "1rem" }}
                                  >
                                    <Button
                                      color="info"
                                      onClick={() => {
                                        navigate(
                                          `/categories/services/${d.data["subService.id"]}/${d.data["subService.service.id"]}/sub/edit`
                                        );
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  </div>
                                )}
                              </>
                            ))}
                          </tbody>
                        </table>
                        {(!data || !data?.length) && emptyResult()}
                      </div>

                      {renderButtons()}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Subservice;
