import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { Input, Label, Button } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const UseUpdateForm = (uri, updateUri, watch, options) => {
  const [data, setData] = useState();
  const form = useRef();
  const [attachData, setAttachData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const option = {};
  const editorRef = useRef(null);

  const { fields } = options;
  const richTextWarn = useRef({});

  const setRichTextValidation = () => {
    if (data?.body?.length > 0) {
      richTextWarn.current["body"] = "";
      return;
    }
    fields.forEach((field) => {
      if (field.richText && field.required) {
        richTextWarn.current = {
          ...richTextWarn.current,
          [field.field]: `${field.label} alanı zorunludur.`,
        };
      }
    });
  };

  const getData = async () => {
    setData(undefined);
    try {
      const response = await axios.get(uri);
      setData(response);

      if (response?.fileCollection) {
        const files = response.fileCollection.files?.map((item) => ({
          path: item.path,
          id: item.id,
        }));
        setFiles(files);
      }

      // Checkbox ve multi-select alanları işleme
      const checkboxes = fields.filter((x) => x.type === "checkbox");
      checkboxes.forEach((field) => {
        setAttachData((prev) => ({
          ...prev,
          [field.field]: response[field.field] ?? false, // Varsayılan olarak false ayarlandı
        }));
      });

      // Çoklu seçim (multi-select) alanlarını işleme
      const multiSelectFields = fields.filter(
        (field) => field.type === "select" && field.multiple
      );
      multiSelectFields.forEach((field) => {
        setAttachData((prev) => ({
          ...prev,
          [field.field]:
            response[field.field]?.map((item) =>
              typeof item === "object" ? item.id : item
            ) ?? [],
        }));
      });
    } catch (e) {
      toast(e?.response?.data?.message || "Veri yüklenirken bir hata oluştu.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-warning text-white",
      });
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const validationMessages = [];
      Object.values(richTextWarn.current).forEach((x) => {
        if (x.length) {
          validationMessages.push(x);
        }
      });

      if (validationMessages.length) {
        return alert(validationMessages.join("\n"));
      }

      const formData = new FormData(form.current);
      const body = Object.fromEntries(formData);

      // Correcting payload format for multi-select fields
      Object.keys(body).forEach((key) => {
        if (Array.isArray(body[key])) {
          body[key] = body[key].map((value) => {
            if (typeof value === "object" && value !== null && value.id) {
              return value.id;
            }
            return Number(value);
          });
        }
      });

      setLoading(true);
      await axios.patch(updateUri, { ...body, ...attachData, ...option });
      toast("Kayıt başarıyla güncellendi.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-success text-white",
      });
      setRichTextValidation();
    } catch (e) {
      toast(
        e?.response?.data?.message || "Güncelleme sırasında bir hata oluştu.",
        {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          className: "bg-warning text-white",
        }
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data?.service && Array.isArray(data.service)) {
      setAttachData((prev) => ({
        ...prev,
        service: data.service.map((item) => (item.id ? item.id : item)),
      }));
    }
  }, [data]);

  useEffect(() => {
    getData();
    setRichTextValidation();
  }, [...watch]);

  useEffect(() => {
    setRichTextValidation();
    if (editorRef.current && data?.body) {
      editorRef.current.setData(data.body);
    }
  }, [data, ...watch]);

  const deleteFile = async (id) => {
    try {
      const response = await axios.delete(`/files/${id}`);
      if (response?.data?.affected === 1 || response.affected === 1) {
        setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
        toast("Dosya başarıyla silindi.", {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          className: "bg-success text-white",
        });
      }
    } catch (error) {
      toast(
        error?.response?.data?.message || "Dosya silinirken bir hata oluştu.",
        {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          className: "bg-warning text-white",
        }
      );
    }
  };

  const renderForm = () => {
    return data ? (
      <form onSubmit={handleSubmit} ref={form}>
        <ToastContainer />
        {files?.length > 0 && (
          <div className="d-flex gap-2 flex-wrap mt-3">
            {files.map((file, i) => (
              <div key={file.id}>
                <div style={{ width: 100, height: 100, position: "relative" }}>
                  <img
                    src={file.path}
                    key={file.path}
                    style={{ width: 100, height: 100, borderRadius: 6 }}
                  />
                  <Button
                    onClick={() => deleteFile(file.id)}
                    style={{
                      position: "absolute",
                      top: -10,
                      right: -10,
                      zIndex: 10,
                      borderRadius: 100,
                      backgroundColor: "gray",
                      border: "1px solid gray",
                      width: 45,
                      height: 45,
                    }}
                  >
                    <i className="bx bx-trash"></i>
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}

        {fields.map((field, index) => {
          return (
            <div key={index}>
              <div style={{ marginBlock: "1rem" }}>
                <Label
                  htmlFor={`form-item-${field.field}`}
                  className="form-label text-capitalize"
                >
                  {field.label}
                </Label>

                {/* Checkbox ve diğer input alanlarının render edilmesi */}
                {field?.type === "checkbox" && (
                  <Input
                    type="checkbox"
                    onChange={(e) => {
                      setAttachData((prev) => ({
                        ...prev,
                        [field.field]: e.target.checked,
                      }));
                    }}
                    name={field.field}
                    checked={
                      attachData[field.field] ?? data[field.field] ?? false
                    }
                    id={`form-item-${field.field}`}
                    className="form-check-input"
                    {...field}
                  />
                )}

                {/* Tekli seçim ve metin alanları */}
                {field?.type !== "select" &&
                  !field.richText &&
                  field.type !== "checkbox" &&
                  field.type !== "checkbox-group" && (
                    <Input
                      onChange={(e) => {
                        setAttachData((prev) => ({
                          ...prev,
                          [field.field]: e.target.value,
                        }));
                      }}
                      name={field.field}
                      defaultValue={data[field.field]}
                      id={`form-item-${field.field}`}
                      className="form-control"
                      {...field}
                    />
                  )}

                {/* `service` alanı için checkbox listesi */}
                {field.type === "select" && field.multiple ? (
                  <div
                    className="checkbox-group"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center", // Checkboxları yatayda ortalar
                      alignItems: "center", // Checkboxları dikeyde ortalar
                      gap: "1rem",
                      width: "100%", // Tam ekran genişliği
                      textAlign: "center", // Metinleri ortalar
                      maxHeight: "200px",
                      overflowY: "auto",
                      padding: "0.5rem",
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                    }}
                  >
                    {field.options.map((option) => (
                      <label
                        key={option.value}
                        htmlFor={`${field.field}-${option.value}`}
                        className="custom-checkbox"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center", // Her checkboxın etrafını ortalar
                          width: "180px", // Sabit genişlik
                          height: "50px", // Sabit yükseklik
                          padding: "0.5rem",
                          cursor: "pointer",
                          borderRadius: "4px",
                          transition: "background-color 0.3s",
                        }}
                      >
                        <input
                          type="checkbox"
                          id={`${field.field}-${option.value}`}
                          name={field.field}
                          value={option.value}
                          checked={
                            attachData[field.field]?.includes(option.value) ||
                            data[field.field]?.some(
                              (item) =>
                                (typeof item === "object" ? item.id : item) ===
                                option.value
                            ) ||
                            false
                          }
                          onChange={(e) => {
                            const selectedValues = e.target.checked
                              ? [
                                  ...(attachData[field.field] || []),
                                  option.value,
                                ]
                              : attachData[field.field].filter(
                                  (v) => v !== option.value
                                );
                            setAttachData((prev) => ({
                              ...prev,
                              [field.field]: selectedValues,
                            }));
                          }}
                          style={{ display: "none" }}
                        />
                        <span
                          className="checkbox-box"
                          style={{
                            display: "inline-block",
                            width: "16px",
                            height: "16px",
                            border: "2px solid #007bff",
                            borderRadius: "4px",
                            marginRight: "0.5rem",
                            transition: "all 0.3s",
                            backgroundColor: attachData[field.field]?.includes(
                              option.value
                            )
                              ? "#007bff"
                              : "transparent",
                          }}
                        />
                        <span>{option.label}</span>
                      </label>
                    ))}
                  </div>
                ) : (
                  // Tekli seçim için eski select yapısını kullanırız
                  field.type === "select" && (
                    <select
                      className="form-select"
                      name={field.field}
                      defaultValue={
                        attachData[field.field] ??
                        (typeof data[field.field] === "object"
                          ? data[field.field]?.id
                          : data[field.field])
                      }
                      onChange={(e) => {
                        setAttachData((prev) => ({
                          ...prev,
                          [field.field]: e.target.value,
                        }));
                      }}
                    >
                      {field.options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  )
                )}

                {/* CKEditor rich text alanı */}
                {field?.richText && (
                  <>
                    <input
                      name={field.field}
                      hidden
                      defaultValue={data[field.field]}
                    />
                    <CKEditor
                      editor={ClassicEditor}
                      data={data[field.field]}
                      onChange={(_, editor) => {
                        const editorData = editor.getData();
                        setData((prevData) => ({
                          ...prevData,
                          [field.field]: editorData,
                        }));
                        document.querySelector(
                          `input[name='${field.field}']`
                        ).value = editorData;

                        setTimeout(() => {
                          if (richTextWarn.current[field.field] !== undefined) {
                            richTextWarn.current[field.field] =
                              editorData.length > 0
                                ? ""
                                : `${field.label} alanı zorunludur.`;
                          }
                        }, 100);
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          );
        })}
        {fields.length > 0 && (
          <Button
            disabled={isLoading}
            type="submit"
            className="mt-4 btn btn-primary w-100 d-block"
          >
            {!isLoading ? "Kaydet" : "Lütfen Bekleyin"}
          </Button>
        )}
      </form>
    ) : (
      <p>Lütfen Bekleyin...</p>
    );
  };

  return {
    renderForm,
    data,
  };
};
