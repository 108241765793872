import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";

import { useInsertForm } from "../../Components/Hooks/UseInsertForm";
import axios from "axios";

const AddHotel = () => {
  document.title = "Add Hotel | Visit Turkey";

  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);

  const getCities = async () => {
    const response = await axios.get("/accommodation/cities");
    setCities(
      response.map((x) => {
        return {
          value: x.id,
          label: x.name,
        };
      })
    );
  };

  const { renderForm } = useInsertForm(
    [
      {
        label: "Choose city",
        name: "cityId",
        required: true,
        options: cities,
        type: "selectbox",
      },
      {
        label: "Categories",
        name: "categories",
        required: true,
        options: categories,
        type: "selectbox",
        multiple: true,
      },
      {
        label: "image",
        name: "image",
        placeholder: "enter date",
        required: false,
        type: "file",
        multiple: true,
        multiuploadcolumn: "accommodation",
        uploadtype: "accommodation",
      },
    ],
    [
      {
        label: "Name",
        name: "name",
        placeholder: "enter name",
        required: true,
        type: "text",
      },
      {
        label: "Description",
        name: "description",
        placeholder: "enter description",
        required: true,
        type: "text",
        richText: true,
      },
    ],
    { postUrl: "/accommodation" }
  );

  const getCategories = async () => {
    const response = await axios.get(
      "/accommodation/categories?page=1&take=100&language=tr&keyword="
    );
    setCategories(
      response.map((x) => {
        return {
          value: x.accommodationCategory.id,
          label: x.name,
        };
      })
    );
  };

  useEffect(() => {
    Promise.all([getCities(), getCategories()]);
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Add Article" pageTitle="Add Article" />
          <Row>
            <Col>
              <Card>
                <CardBody className="pt-2">
                  <h5 className="card-title mb-3  mb-md-0 flex-grow-1">
                    Add Hotel
                  </h5>
                  {renderForm()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddHotel;
