import React, { useState, useEffect } from "react";
import { Alert, Button, Col, Container, Row } from "reactstrap";
import { UseUpdateForm } from "../../Components/Hooks/UseUpdateForm";
import { useParams } from "react-router-dom";
import axios from "axios";
import { UseUpdateSingleFile } from "../../Components/Hooks/UseUpdateSingleFile";

const EditDoctor = () => {
  document.title = "Edit Doctor - VisitTurkey";
  const { id } = useParams();
  const [options, setOptions] = useState([]);

  const [language, setLanguage] = useState("tr");

  const { renderRedirectButton } = UseUpdateSingleFile(undefined, {
    pageColumn: "practitioner",
    relationId: id,
  });

  const { renderForm: renderCommonForm } = UseUpdateForm(
    `/practioner/detail/${id}`,
    `/practioner/detail/${id}`,
    [],
    {
      fields: [
        {
          field: "clinicId",
          label: "Clinic Id",
          type: "select",
          options,
          required: "required",
        },
        {
          field: "name",
          label: "Doctor name",
          type: "input",
          required: "required",
        },
        {
          field: "licenseNumber",
          label: "License Number",
          type: "input",
          required: "required",
        },
        {
          field: "field",
          label: "Field",
          type: "input",
          required: "required",
        },
        {
          field: "slug",
          label: "slug",
          type: "input",
          required: "required",
        },
      ],
    }
  );

  const { renderForm: renderLanguageForm } = UseUpdateForm(
    `/practioner/detail/${id}/${language}`,
    `/practioner/detail/${id}/${language}`,
    [language],
    {
      fields: [
        {
          field: "id",
          readOnly: "readonly",
          type: "input",
          label: "Id",
          required: "required",
        },
        {
          label: "About",
          name: "about",
          field: "about",
          placeholder: "about",
          required: true,
          type: "text",
          richText: true,
        },
      ],
    }
  );

  const getOptions = async () => {
    const response = await axios.get("/organization/all/clinic");
    setOptions(
      response.map((x) => {
        return {
          value: x.id.toString(),
          label: x.fullName,
        };
      })
    );
  };

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>{renderCommonForm()}</Col>
          </Row>
          <Col style={{ marginBlock: "2rem" }}>
            <Alert color="info">Please choose a locale for update</Alert>
            <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
              <Button
                onClick={() => setLanguage("tr")}
                className={language === "tr" ? "fw-bold" : ""}
              >
                Turkish
              </Button>
              <Button
                onClick={() => setLanguage("ar")}
                dir="rtl"
                className={language === "ar" ? "fw-bold" : ""}
              >
                Arabic
              </Button>
              <Button
                onClick={() => setLanguage("en")}
                className={language === "en" ? "fw-bold" : ""}
              >
                English
              </Button>
            </div>
          </Col>
          <Col>{renderLanguageForm()}</Col>
          <Col>{renderRedirectButton()}</Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditDoctor;
