import { useState, useEffect, useRef } from "react";
import axios from "axios";
import SearchIcon from "./icons/search";
import { Button } from "reactstrap";

export const usePagination = (uri, opt) => {
  const { fields, preventInitialRequest } = opt;
  const LIMIT = 10;

  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState();
  const [keyword, setKeyword] = useState("");
  const timeoutRef = useRef();
  const isInitilized = useRef(false);

  const flatObj = (obj) => {
    let flatten = {};

    function flat(obj, val = "") {
      Object.keys(obj).forEach((key) => {
        if (
          !obj[key] ||
          typeof obj[key] !== "object" ||
          Array.isArray(obj[key])
        ) {
          flatten[val + "" + key] = obj[key];
        } else {
          flat(obj[key], val + key + ".");
        }
      });
    }

    flat(obj);

    return flatten;
  };

  const next = () => {
    setPage((cur) => (cur += 1));
  };

  const prev = () => {
    setPage((cur) => (cur -= 1));
  };

  const sendRequest = async () => {
    try {
      setLoading(true);
      let request = `${
        uri.includes("?")
          ? `${uri}&page=${page}&take=${LIMIT}&keyword=${keyword}&language=en`
          : `${uri}?page=${page}&take=${LIMIT}&keyword=${keyword}&language=en`
      }`;
      if (opt.params) {
        request += "&" + opt.params;
      }
      const response = await axios.get(request);
      setData(response);
    } catch (e) {
      alert(e.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const emptyResult = () => {
    if (!data || !data?.length) {
      return (
        <div className="noresult">
          <div className="text-center">
            <SearchIcon />
            <h5 className="mt-2">Sorry! No Result Found</h5>
            <p className="text-muted mb-0">We've couldn't find your query.</p>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderFields = () => {
    return fields.map((field, index) => {
      return (
        <th key={index} data-sort={field} className="text-capitalize">
          {field.label}
        </th>
      );
    });
  };

  const renderData = (cb) => {
    if (!data) {
      return null;
    }
    return data
      .map((x) => flatObj(x))
      .map((item, index) => {
        return (
          <tr key={item.id}>
            {fields.map((field, fieldIndex) => {
              return (
                <td key={fieldIndex}>
                  {field.column
                    ? item[field?.column]
                    : cb({ id: field.id, field, data: item })}
                </td>
              );
            })}
          </tr>
        );
      });
  };

  const renderButtons = () => {
    return (
      <div className="d-flex justify-content-end">
        <div className="pagination-wrap hstack gap-2">
          <Button
            outline
            onClick={prev}
            disabled={page === 1}
            className={`page-item pagination-prev ${
              page === 0 ? "disabled" : ""
            }`}
          >
            Previous
          </Button>
          {data?.length === 5 && (
            <Button
              outline
              onClick={next}
              disabled={!data || data?.length <= 0 ? true : false}
              className={`page-item pagination-prev ${
                data?.length <= 0 ? "disabled" : ""
              }`}
            >
              Next
            </Button>
          )}
        </div>
      </div>
    );
  };

  const renderSearch = () => {
    return (
      <div className="search-box ms-2" style={{ width: "100%" }}>
        <input
          type="text"
          onChange={(e) => {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
              setPage(1);
              setKeyword(e.target.value);
            }, 500);
          }}
          className="form-control search"
          placeholder="Search..."
        />
        <i className="ri-search-line search-icon"></i>
      </div>
    );
  };

  useEffect(() => {
    if (!isInitilized.current && preventInitialRequest) {
      isInitilized.current = true;
      return;
    }
    sendRequest();
  }, [page, keyword]);

  return {
    data,
    page,
    isLoading,
    sendRequest,
    renderSearch,
    renderButtons,
    renderFields,
    renderData,
    next,
    prev,
    sendRequest,
    emptyResult,
  };
};
