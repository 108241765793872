import React, { useState } from "react";
import {
  Alert,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { UseUpdateForm } from "../../Components/Hooks/UseUpdateForm";
import { useParams } from "react-router-dom";
import { UseUpdateSingleFile } from "../../Components/Hooks/UseUpdateSingleFile";

const EditHotelCategory = () => {
  document.title = "Otel Kategorisi Düzenle - VisitTurkey";
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("tr");

  const { renderFileInput } = UseUpdateSingleFile(
    `/accommodation/category/detail/${id}`,
    {
      label: "Icon File",
      column: "iconSrc",
    }
  );
  const { renderForm: renderCommonForm } = UseUpdateForm(
    `/accommodation/category/detail/${id}`,
    `/accommodation/category/detail/${id}`,
    [],
    {
      fields: [
        {
          field: "isToggleQuestion",
          type: "checkbox",
          label: "Is Toggle Question",
        },
        {
          field: "questionType",
          label: "Type",
          required: "required",
          type: "select",
          options: [
            { value: "health", label: "Health" },
            { value: "services", label: "Services" },
            { value: "accommodation", label: "Accommodation" },
            { value: "travel", label: "Travel" },
          ],
        },
      ],
    }
  );

  const renderLanguageFormForTab = (language) => {
    const { renderForm } = UseUpdateForm(
      `/accommodation/category/detail/${id}/${language}`,
      `/accommodation/category/detail/${id}/${language}`,
      [language],
      {
        fields: [
          {
            field: "id",
            readOnly: "readonly",
            type: "input",
            label: "Id",
            required: "required",
          },
          {
            field: "name",
            type: "text",
            label: "Name",
            required: "required",
          },
        ],
      }
    );
    return renderForm();
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>{renderCommonForm()}</Col>
          </Row>
          <Col style={{ marginBlock: "2rem" }}>
            <Alert color="info">Güncelleme için bir dil seçiniz</Alert>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "tr" })}
                  onClick={() => toggleTab("tr")}
                  style={{
                    cursor: "pointer",
                    fontWeight: activeTab === "tr" ? "bold" : "normal",
                  }}
                >
                  Türkçe
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "ar" })}
                  onClick={() => toggleTab("ar")}
                  style={{
                    cursor: "pointer",
                    fontWeight: activeTab === "ar" ? "bold" : "normal",
                  }}
                >
                  Arapça
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "en" })}
                  onClick={() => toggleTab("en")}
                  style={{
                    cursor: "pointer",
                    fontWeight: activeTab === "en" ? "bold" : "normal",
                  }}
                >
                  İngilizce
                </NavLink>
              </NavItem>
            </Nav>
          </Col>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="tr">{renderLanguageFormForTab("tr")}</TabPane>
            <TabPane tabId="ar">{renderLanguageFormForTab("ar")}</TabPane>
            <TabPane tabId="en">{renderLanguageFormForTab("en")}</TabPane>
          </TabContent>

          <hr />
          <Col>{renderFileInput()}</Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditHotelCategory;
